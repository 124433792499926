import { useParams, useNavigate } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import "./BlogDetails.css";
import { Link } from "react-router-dom";
import { TabTitle, Capitalize } from "../../utils/GeneralFunctions";
import ReactPlayer from "react-player";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  EmailShareButton,
  EmailIcon,
} from "react-share";

const findObjectById = (idToFind, arrayOfObjects) => {
  return arrayOfObjects.find((obj) => obj.id === idToFind);
};

const BlogDetails = () => {
  const { id } = useParams();
  const { data, error, isPending } = useFetch(
    'https://pick-705a9-default-rtdb.firebaseio.com/blogs/.json/'
  );

  const blog = data ? findObjectById(id, data) : null;

  TabTitle(id.charAt(0).toUpperCase() + id.slice(1).split("-").join(" "));
  Capitalize();

  //canal navigation
  const navigate = useNavigate();
  const goToCanalStory = () => {
    navigate("/featured/how-do-i-visit-the-panama-canal");
  };
  const goToTransportationStory = () => {
    navigate("/blogs/transportation-in-panama");
  };

  //transportation page forward back
  const navigateA = useNavigate();
  const goToSafetyStory = () => {
    navigateA("/blogs/is-panama-safe-to-travel-alone");
  };
  const goToElCangrejoStory = () => {
    navigateA("/blogs/things-to-do-in-el-cangrejo");
  };

  //spanish page navigation
  const navigateSpanish = useNavigate();
  const goToSpanishStory = () => {
    navigateSpanish("/blogs/what-languages-are-spoken-in-panama");
  };

  //cultural norms navigation
  const navigateCultural = useNavigate();
  const goToCulturalNorms = () => {
    navigateCultural("/blogs/the-culture-of-panama");
  };

  //food navigation
  const navigateFood = useNavigate();
  const goToFood = () => {
    navigateFood("/blogs/traditional-food-in-panama");
  };

  //whatsapp navigation
  const navigateWhatsApp = useNavigate();
  const goToWhatsAppStory = () => {
    navigateWhatsApp(
      "/blogs/using-whatsapp-instead-of-your-cell-phone-service-in-panama"
    );
  };

  //flights navigation
  const navigateFlights = useNavigate();
  const goToFlights = () => {
    navigateFlights("/blogs/how-to-fly-cheap-to-panama");
  };

  //english navigation
  const navigateEnglish = useNavigate();
  const goToEnglish = () => {
    navigateEnglish("/blogs/how-many-panamanians-speak-english");
  };

  //treaty navigation
  const navigateTreaty = useNavigate();
  const goToTreatyStory = () => {
    navigateTreaty("/blogs/why-did-jimmy-carter-sign-the-panama-canal-treaty");
  };

  //packing navigation
  const navigatePacking = useNavigate();
  const goToPacking = () => {
    navigatePacking("/blogs/packing-for-panama");
  };

  //must know navigation
  const navigateMustKnow = useNavigate();
  const goToMustKnow = () => {
    navigateMustKnow("/blogs/daily-life-in-panama");
  };

  const currentPageUrl = window.location.href;

  if (document.title === "Is Panama safe to travel alone?") {
    return (
      <main id="main-element" className={`blog-details margin `}>
        {isPending && <div>Loading...</div>}
        {error && <div className="load-error">{error}</div>}
        {blog && (
          <section className={blog.className}>
            <div className="line-divider centered wide">
              <p className="diamond-text-box date">
                <span className="left"></span>
                <span className="content caps centered">{blog.date}</span>
                <span className="right"></span>
              </p>
            </div>
            <h1 className="centered post-title">{blog.title}</h1>
            <p className="body intro">{blog.intro}</p>
            <div className="share-parent">
              <FacebookShareButton
                url={currentPageUrl}
                hashtag="#panama"
                aria-label="facebook"
              >
                <FacebookIcon size={35} />
              </FacebookShareButton>
              <TwitterShareButton
                url={currentPageUrl}
                hashtags={["pickuppanama", "safety", "panama"]}
                title={"Is Panama safe to travel alone?"}
                aria-label="twitter"
              >
                <TwitterIcon size={35} />
              </TwitterShareButton>
              <LinkedinShareButton
                url={currentPageUrl}
                title={"Is Panama safe to travel alone?"}
                summary={
                  "Follow these tips to stay safe in Panama. Learn how to prevent muggings, avoid bad areas, and more."
                }
                aria-label="linkedin"
              >
                <LinkedinIcon size={35} />
              </LinkedinShareButton>
              <EmailShareButton
                url={currentPageUrl}
                subject={"Is Panama safe to travel alone?"}
                body={
                  "Follow these tips to stay safe in Panama. Learn how to prevent muggings, avoid bad areas, and more."
                }
                aria-label="email"
                separator={" "}
              >
                <EmailIcon size={35} />
              </EmailShareButton>
            </div>
            <h2>{blog.howSafeH2}</h2>
            <p className="moderate-crime">{blog.moderateCrime}</p>
            <p className="despite-this">{blog.despiteThis}</p>
            <p className="this-article">{blog.thisArticle}</p>
            <figure className="cops-at-fruitstand">
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/safety/panamanian-police-at-fruitstand-320.webp 320w, /images/safety/panamanian-police-at-fruitstand-480.webp 480w, /images/safety/panamanian-police-at-fruitstand-640.webp 640w, /images/safety/panamanian-police-at-fruitstand-768.webp 768w, /images/safety/panamanian-police-at-fruitstand.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/safety/panamanian-police-at-fruitstand-320.jpg 320w, /images/safety/panamanian-police-at-fruitstand-480.jpg 480w, /images/safety/panamanian-police-at-fruitstand-640.jpg 640w, /images/safety/panamanian-police-at-fruitstand-768.jpg 768w, /images/safety/panamanian-police-at-fruitstand.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  fetchpriority="high"
                  className="no-right-click"
                  src={blog.copsAtFruitStand}
                  alt={blog.fruitStandCopAltText}
                />
              </picture>
              <figcaption>{blog.fruitStandCopCaption}</figcaption>
            </figure>
            <figure className="bank-police">
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/safety/panamanian-policeman-outside-of-bank-320.webp 320w, /images/safety/panamanian-policeman-outside-of-bank-480.webp 480w, /images/safety/panamanian-policeman-outside-of-bank-640.webp 640w, /images/safety/panamanian-policeman-outside-of-bank-768.webp 768w, /images/safety/panamanian-policeman-outside-of-bank.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/safety/panamanian-policeman-outside-of-bank-320.jpg 320w, /images/safety/panamanian-policeman-outside-of-bank-480.jpg 480w, /images/safety/panamanian-policeman-outside-of-bank-640.jpg 640w, /images/safety/panamanian-policeman-outside-of-bank-768.jpg 768w, /images/safety/panamanian-policeman-outside-of-bank.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.panamaCopBank}
                  alt={blog.panamaCopBankAltText}
                />
              </picture>
              <figcaption>{blog.panamaCopBankCaption}</figcaption>
            </figure>
            <figure className="market-police">
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/safety/panamanian-police-el-cangrejo-320.webp 320w, /images/safety/panamanian-police-el-cangrejo-480.webp 480w, /images/safety/panamanian-police-el-cangrejo-640.webp 640w, /images/safety/panamanian-police-el-cangrejo-768.webp 768w, /images/safety/panamanian-police-el-cangrejo.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/safety/panamanian-police-el-cangrejo-320.jpg 320w, /images/safety/panamanian-police-el-cangrejo-480.jpg 480w, /images/safety/panamanian-police-el-cangrejo-640.jpg 640w, /images/safety/panamanian-police-el-cangrejo-768.jpg 768w, /images/safety/panamanian-police-el-cangrejo.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.panamaCopElCan}
                  alt={blog.panamaCopElCanAltText}
                />
              </picture>
              <figcaption>{blog.panamaCopElCanCaption}</figcaption>
            </figure>
            <section>
              <h2>{blog.h2targets}</h2>
              <p>{blog.criteria}</p>
              <h3>{blog.h3alone}</h3>
              <p>{blog.commonTarget}</p>
              <p>{blog.Oftentimes}</p>
              <h3>{blog.unsurePeople}</h3>
              <p>{blog.bodyLanguage}</p>
              <p>{blog.ifAble}</p>
              <p>{blog.muggersMoveIn}</p>
              <p>{blog.goingAroundCorners}</p>
              <p>{blog.takeNote}</p>
              <p>{blog.whenYouEnter}</p>
              <h3>{blog.h3wealth}</h3>
              <p>{blog.cuffLinks}</p>
              <p>{blog.makeEffort}</p>
              <p>{blog.ensureThat}</p>
              <h2>{blog.h2whatToDo}</h2>
              <p>{blog.ifMugged}</p>
              <p>{blog.manyMuggers}</p>
              <p>{blog.dummyWallet}</p>
              <h2>{blog.h2HighCrime}</h2>
              <p>{blog.accordingTo}</p>
              <ul>
                <li>{blog.sanMiguelito}</li>
                <li>{blog.rioAbajo}</li>
                <li>{blog.elChorrillo}</li>
                <li>{blog.santaAna}</li>
                <li>{blog.panamaViejo}</li>
              </ul>
              <p>{blog.mostCrimeReported}</p>
              <p>{blog.duringDay}</p>
              <figure className="casco-police">
                <picture>
                  <source
                    type="image/webp"
                    srcSet={
                      process.env.PUBLIC_URL +
                      "/images/safety/casco-viejo-police-320.webp 320w, /images/safety/casco-viejo-police-480.webp 480w, /images/safety/casco-viejo-police-640.webp 640w, /images/safety/casco-viejo-police-768.webp 768w, /images/safety/casco-viejo-police.webp 1170w"
                    }
                    sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                  />
                  <source
                    type="image/jpg"
                    srcSet={
                      process.env.PUBLIC_URL +
                      "/images/safety/casco-viejo-police-320.jpg 320w, /images/safety/casco-viejo-police-480.jpg 480w, /images/safety/casco-viejo-police-640.jpg 640w, /images/safety/casco-viejo-police-768.jpg 768w, /images/safety/casco-viejo-police.jpg 1170w"
                    }
                    sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                  />
                  <img
                    className="no-right-click"
                    src={blog.cascoViejoPolice}
                    alt={blog.cascoViejoPolceAltText}
                  />
                </picture>
                <figcaption>{blog.cascoViejoPoliceCaption}</figcaption>
              </figure>
              <figure className="casco-tourist">
                <picture>
                  <source
                    type="image/webp"
                    srcSet={
                      process.env.PUBLIC_URL +
                      "/images/safety/autoridad-de-turismo-320.webp 320w, /images/safety/autoridad-de-turismo-480.webp 480w, /images/safety/autoridad-de-turismo-640.webp 640w, /images/safety/autoridad-de-turismo-768.webp 768w, /images/safety/autoridad-de-turismo.webp 1170w"
                    }
                    sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                  />
                  <source
                    type="image/jpg"
                    srcSet={
                      process.env.PUBLIC_URL +
                      "/images/safety/autoridad-de-turismo-320.jpg 320w, /images/safety/autoridad-de-turismo-480.jpg 480w, /images/safety/autoridad-de-turismo-640.jpg 640w, /images/safety/autoridad-de-turismo-768.jpg 768w, /images/safety/autoridad-de-turismo.jpg 1170w"
                    }
                    sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                  />
                  <img
                    className="no-right-click"
                    src={blog.cascoTouristAuth}
                    alt={blog.cascoTouristAuthAltText}
                  />
                </picture>
                <figcaption>{blog.cascoTouristAuthCaption}</figcaption>
              </figure>
              <p>{blog.cascoViejoBorders}</p>
              <figure className="near-casco">
                <picture>
                  <source
                    type="image/webp"
                    srcSet={
                      process.env.PUBLIC_URL +
                      "/images/safety/cinco-de-mayo-panama-320.webp 320w, /images/safety/cinco-de-mayo-panama-480.webp 480w, /images/safety/cinco-de-mayo-panama-640.webp 640w, /images/safety/cinco-de-mayo-panama-768.webp 768w, /images/safety/cinco-de-mayo-panama.webp 1170w"
                    }
                    sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                  />
                  <source
                    type="image/jpg"
                    srcSet={
                      process.env.PUBLIC_URL +
                      "/images/safety/cinco-de-mayo-panama-320.jpg 320w, /images/safety/cinco-de-mayo-panama-480.jpg 480w, /images/safety/cinco-de-mayo-panama-640.jpg 640w, /images/safety/cinco-de-mayo-panama-768.jpg 768w, /images/safety/cinco-de-mayo-panama.jpg 1170w"
                    }
                    sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                  />
                  <img
                    className="no-right-click"
                    src={blog.nearCascoViejo}
                    alt={blog.nearCascoViejoAltText}
                  />
                </picture>
                <figcaption>{blog.nearCascoViejoCaption}</figcaption>
              </figure>
              <p>{blog.iAdviseAgainst}</p>
              <h3>{blog.h3Colon}</h3>
              <p>{blog.colonIsOn}</p>
              <figure className="colon-plate">
                <picture>
                  <source
                    type="image/webp"
                    srcSet={
                      process.env.PUBLIC_URL +
                      "/images/safety/colon-panama-license-plate-320.webp 320w, /images/safety/colon-panama-license-plate-480.webp 480w, /images/safety/colon-panama-license-plate-640.webp 640w, /images/safety/colon-panama-license-plate-768.webp 768w, /images/safety/colon-panama-license-plate.webp 1170w"
                    }
                    sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                  />
                  <source
                    type="image/jpg"
                    srcSet={
                      process.env.PUBLIC_URL +
                      "/images/safety/colon-panama-license-plate-320.jpg 320w, /images/safety/colon-panama-license-plate-480.jpg 480w, /images/safety/colon-panama-license-plate-640.jpg 640w, /images/safety/colon-panama-license-plate-768.jpg 768w, /images/safety/colon-panama-license-plate.jpg 1170w"
                    }
                    sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                  />
                  <img
                    className="no-right-click"
                    src={blog.colonLicense}
                    alt={blog.colonLicenseAltText}
                  />
                </picture>
                <figcaption>{blog.colonLicenseCaption}</figcaption>
              </figure>
              <h3>{blog.darianGap}</h3>
              <p>{blog.nearColumbianBorder}</p>
              <p>{blog.difficultToNavigate}</p>
              <p>{blog.trafficking}</p>
              <p>{blog.adventureTourist}</p>
              <h2>{blog.h2SafeWater}</h2>
              <p>{blog.hygieneStandards}</p>
              <p>{blog.hepatitisA}</p>
              <p>{blog.playItSafe}</p>
              <h2>{blog.h2Insects}</h2>
              <p>{blog.panamaDoesHave}</p>
              <h2>{blog.h2Tips}</h2>
              <ul>
                <li>{blog.shareItinerary}</li>
                <li>{blog.beforeLeavingHotel}</li>
                <li>{blog.smartTraveler}</li>
                <li>{blog.moneyBelt}</li>
                <li>{blog.differentPockets}</li>
                <li>{blog.rubberBand}</li>
                <li>{blog.mentalChecklist}</li>
                <li>{blog.beGuarded}</li>
                <li>{blog.neverLeave}</li>
                <li>{blog.beMindful}</li>
                <li>{blog.commotion}</li>
                <li>{blog.takeLookBack}</li>
                <li>{blog.checkIn}</li>
                <li>{blog.emergencyNum}</li>
                <li>{blog.insurance}</li>
                <li>{blog.phrases}</li>
                <li>{blog.copyPassport}</li>
                <li>{blog.avoidWalking}</li>
                <li>{blog.clubbing}</li>
                <li>{blog.parkWellLit}</li>
                <li>{blog.takeUber}</li>
              </ul>
              <p>{blog.overallPanamaIs}</p>
              <p>{blog.mostTips}</p>
              <figure className="casco-petty-crime last-figure">
                <picture>
                  <source
                    type="image/webp"
                    srcSet={
                      process.env.PUBLIC_URL +
                      "/images/safety/casco-viejo-petty-crime-320.webp 320w, /images/safety/casco-viejo-petty-crime-480.webp 480w, /images/safety/casco-viejo-petty-crime-640.webp 640w, /images/safety/casco-viejo-petty-crime-768.webp 768w, /images/safety/casco-viejo-petty-crime.webp 1170w"
                    }
                    sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                  />
                  <source
                    type="image/jpg"
                    srcSet={
                      process.env.PUBLIC_URL +
                      "/images/safety/casco-viejo-petty-crime-320.jpg 320w, /images/safety/casco-viejo-petty-crime-480.jpg 480w, /images/safety/casco-viejo-petty-crime-640.jpg 640w, /images/safety/casco-viejo-petty-crime-768.jpg 768w, /images/safety/casco-viejo-petty-crime.jpg 1170w"
                    }
                    sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                  />
                  <img
                    className="no-right-click"
                    src={blog.cascoPettyCrime}
                    alt={blog.cascoPettyCrimeAltText}
                  />
                </picture>
                <figcaption>{blog.cascoPettyCrimeCaption}</figcaption>
              </figure>
            </section>
            <div className="button-parent">
              <button
                title="How do I visit the Panama Canal?"
                onClick={goToCanalStory}
              >
                &#60;
              </button>
              <button
                title="Transportation in Panama"
                onClick={goToTransportationStory}
              >
                &#62;
              </button>
            </div>
          </section>
        )}
      </main>
    );
  }
  if (document.title === "Transportation in Panama") {
    return (
      <main id="main-element" className={`blog-details margin `}>
        {isPending && <div>Loading...</div>}
        {error && <div className="load-error">{error}</div>}
        {blog && (
          <section className={blog.className}>
            <div className="line-divider centered wide">
              <p className="diamond-text-box date">
                <span className="left"></span>
                <span className="content caps centered">{blog.date}</span>
                <span className="right"></span>
              </p>
            </div>
            <h1 className="centered post-title">{blog.title}</h1>
            <p className="body intro">{blog.intro}</p>
            <div className="share-parent">
              <FacebookShareButton
                url={currentPageUrl}
                hashtag="#panama"
                aria-label="facebook"
              >
                <FacebookIcon size={35} />
              </FacebookShareButton>
              <TwitterShareButton
                url={currentPageUrl}
                hashtags={["pickuppanama", "transportation", "panama"]}
                title={"Transportation in Panama"}
                aria-label="twitter"
              >
                <TwitterIcon size={35} />
              </TwitterShareButton>
              <LinkedinShareButton
                url={currentPageUrl}
                title={"Transportation in Panama"}
                summary={
                  "Learn about the Panama metro train, buses, and taxi system to efficiently get around without overpaying for cabs."
                }
                aria-label="linkedin"
              >
                <LinkedinIcon size={35} />
              </LinkedinShareButton>
              <EmailShareButton
                url={currentPageUrl}
                subject={"Transportation in Panama"}
                body={
                  "Learn about the Panama metro train, buses, and taxi system to efficiently get around without overpaying for cabs."
                }
                aria-label="email"
                separator={" "}
              >
                <EmailIcon size={35} />
              </EmailShareButton>
            </div>
            <figure className="hwy">
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/transportation/panama-panamerican-highway-320.webp 320w, /images/transportation/panama-panamerican-highway-480.webp 480w, /images/transportation/panama-panamerican-highway-640.webp 640w, /images/transportation/panama-panamerican-highway-768.webp 768w, /images/transportation/panama-panamerican-highway.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/transportation/panama-panamerican-highway-320.jpg 320w, /images/transportation/panama-panamerican-highway-480.jpg 480w, /images/transportation/panama-panamerican-highway-640.jpg 640w, /images/transportation/panama-panamerican-highway-768.jpg 768w, /images/transportation/panama-panamerican-highway.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  fetchpriority="high"
                  className="no-right-click"
                  src={blog.panAm}
                  alt={blog.panAmAltText}
                />
              </picture>
              <figcaption>{blog.panAmCaption}</figcaption>
            </figure>
            <p>{blog.guide}</p>
            <h2>{blog.h2Tocumen}</h2>
            <p>{blog.iveUsed}</p>
            <h3>{blog.h3Taxi}</h3>
            <p>{blog.asSoon}</p>
            <p>{blog.theyCharge}</p>
            <h3>{blog.h3Uber}</h3>
            <p>{blog.uberWorks}</p>
            <p>{blog.cheapetPaid}</p>
            <p>{blog.buyingData}</p>
            <h3>{blog.h3Wifi}</h3>
            <p>{blog.wifiAccess}</p>
            <h3>{blog.h3TakeMetro}</h3>
            <p>{blog.metroLeast}</p>
            <p>{blog.hasOwn}</p>
            <p>{blog.canTake}</p>
            <h4>{blog.h4RapiCard}</h4>
            <p>{blog.cashCredit}</p>
            <figure className="rapi-card">
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/transportation/rapi-pass-320.webp 320w, /images/transportation/rapi-pass-480.webp 480w, /images/transportation/rapi-pass-640.webp 640w, /images/transportation/rapi-pass-768.webp 768w, /images/transportation/rapi-pass.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/transportation/rapi-pass-320.jpg 320w, /images/transportation/rapi-pass-480.jpg 480w, /images/transportation/rapi-pass-640.jpg 640w, /images/transportation/rapi-pass-768.jpg 768w, /images/transportation/rapi-pass.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.rapiCard}
                  alt={blog.rapiCardAltText}
                />
              </picture>
              <figcaption>{blog.rapiCardCaption}</figcaption>
            </figure>
            <p>{blog.cardIsFor}</p>
            <ul>
              <li>{blog.liMetro}</li>
              <li>{blog.liTrains}</li>
              <li>{blog.liTerminal}</li>
            </ul>
            <p>{blog.itCosts}</p>
            <p>{blog.youCan}</p>
            <p>{blog.ifTraveling}</p>
            <h4>{blog.h4Shuttle}</h4>
            <figure className="free-shuttle">
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/transportation/tocumen-airport-free-shuttle-320.webp 320w, /images/transportation/tocumen-airport-free-shuttle-480.webp 480w, /images/transportation/tocumen-airport-free-shuttle-640.webp 640w, /images/transportation/tocumen-airport-free-shuttle-768.webp 768w, /images/transportation/tocumen-airport-free-shuttle.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/transportation/tocumen-airport-free-shuttle-320.jpg 320w, /images/transportation/tocumen-airport-free-shuttle-480.jpg 480w, /images/transportation/tocumen-airport-free-shuttle-640.jpg 640w, /images/transportation/tocumen-airport-free-shuttle-768.jpg 768w, /images/transportation/tocumen-airport-free-shuttle.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.freeShuttle}
                  alt={blog.freeShuttleAltText}
                />
              </picture>
              <figcaption>{blog.freeShuttleCaption}</figcaption>
            </figure>
            <p>{blog.trainDoesNot}</p>
            <p>{blog.exitAirport}</p>
            <p>{blog.theseBuses}</p>
            <p>{blog.theFree}</p>
            <p>{blog.elevated}</p>
            <p>{blog.itsPart}</p>
            <h4>{blog.h4Aeropuerto}</h4>
            <ul>
              <li>{blog.line2Li}</li>
              <li>{blog.fiveMinLi}</li>
            </ul>
            <figure className="metro-map">
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/transportation/metro-map-aeropuerto-station-320.webp 320w, /images/transportation/metro-map-aeropuerto-station-480.webp 480w, /images/transportation/metro-map-aeropuerto-station-640.webp 640w, /images/transportation/metro-map-aeropuerto-station-768.webp 768w, /images/transportation/metro-map-aeropuerto-station.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/transportation/metro-map-aeropuerto-station-320.jpg 320w, /images/transportation/metro-map-aeropuerto-station-480.jpg 480w, /images/transportation/metro-map-aeropuerto-station-640.jpg 640w, /images/transportation/metro-map-aeropuerto-station-768.jpg 768w, /images/transportation/metro-map-aeropuerto-station.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.metroMap}
                  alt={blog.metroMapAltText}
                />
              </picture>
              <figcaption>
                {blog.metroMapCaption}
                <br />
                <span>{blog.courtesyLobueno}</span>
              </figcaption>
            </figure>
            <h4>{blog.h4CorridorSur}</h4>
            <ul>
              <li>{blog.sanMigLi}</li>
              <li>{blog.samePlatform}</li>
              <li>{blog.line2Costs}</li>
              <li>{blog.shouldTake}</li>
            </ul>
            <h4>{blog.h4SanMig}</h4>
            <ul>
              <li>{blog.whenYouGetOff}</li>
              <li>{blog.theSigns}</li>
              <li>{blog.takeLine1}</li>
              <li>{blog.line1Li}</li>
              <li>{blog.thisShouldLi}</li>
            </ul>
            <p>{blog.trainsModern}</p>
            <p>{blog.thePlatforms}</p>
            <p>{blog.carryingLuggage}</p>
            <div className="video-container">
              <ReactPlayer
                className="youtube-video"
                url="<https://youtube.com/shorts/buKyVdS-8cE?si=cmwDySeycx-e_lLr?rel=0>"
                controls
                width={null}
                height={null}
              />
            </div>
            <p className="metro-caption">{blog.metroVideoCaption}</p>
            <h4>{blog.metroHoursh4}</h4>
            <p className="bold-text">
              {blog.metroStrong} <span>{blog.fiveAm}</span>
            </p>
            <p className="bold-text">
              {blog.saturdayStrong} <span>{blog.fivetoTen}</span>
            </p>
            <p className="bold-text">
              {blog.sundayStrong} <span>{blog.sevenAm}</span>
            </p>
            <h2>{blog.h2Addresses}</h2>
            <p>{blog.noProperAddresses}</p>
            <p>{blog.goByLandmarks}</p>
            <p>{blog.whenAsking}</p>
            <p>{blog.itsCommon}</p>
            <p>{blog.alwaysGreet}</p>
            <h2>{blog.h2TransportationAround}</h2>
            <p>{blog.mainNeighborhoods}</p>
            <p>
              <strong>{blog.thisIsKnown}</strong>
            </p>
            <figure className="city-center">
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/transportation/panama-city-center-320.webp 320w, /images/transportation/panama-city-center-480.webp 480w, /images/transportation/panama-city-center-640.webp 640w, /images/transportation/panama-city-center-768.webp 768w, /images/transportation/panama-city-center.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/transportation/panama-city-center-320.jpg 320w, /images/transportation/panama-city-center-480.jpg 480w, /images/transportation/panama-city-center-640.jpg 640w, /images/transportation/panama-city-center-768.jpg 768w, /images/transportation/panama-city-center.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.cityCenter}
                  alt={blog.cityCenterAltText}
                />
              </picture>
              <figcaption>
                {blog.cityCenterCaption}
                <br />
                <span>{blog.courtesyGoogle}</span>
              </figcaption>
            </figure>
            <h3>{blog.h3YellowCab}</h3>
            <figure className="yellow-cabs">
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/transportation/cabs-panama-city-320.webp 320w, /images/transportation/cabs-panama-city-480.webp 480w, /images/transportation/cabs-panama-city-640.webp 640w, /images/transportation/cabs-panama-city-768.webp 768w, /images/transportation/cabs-panama-city.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/transportation/cabs-panama-city-320.jpg 320w, /images/transportation/cabs-panama-city-480.jpg 480w, /images/transportation/cabs-panama-city-640.jpg 640w, /images/transportation/cabs-panama-city-768.jpg 768w, /images/transportation/cabs-panama-city.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.panamaCabs}
                  alt={blog.panamaCabsAltText}
                />
              </picture>
              <figcaption>{blog.panamaCabsCaption}</figcaption>
            </figure>
            <p>{blog.licensedYellow}</p>
            <p>{blog.sinceThey}</p>
            <p>{blog.itsBest}</p>
            <p>{blog.beforeBoarding}</p>
            <p>{blog.aCabRide}</p>
            <p>{blog.wheneveverITake}</p>
            <p>{blog.iFind}</p>
            <p>{blog.goingOut}</p>
            <p>{blog.cascoWillRun}</p>
            <p>{blog.makeSureto}</p>
            <p>{blog.tipping}</p>
            <p>{blog.avoidTheCabs}</p>
            <p>{blog.iTookAlot}</p>
            <p>{blog.onlyOnce}</p>
            <h3>{blog.uberH3}</h3>
            <p>{blog.uberHas}</p>
            <p>{blog.youDontHave}</p>
            <p>{blog.safer}</p>
            <p>{blog.uberCanBe}</p>
            <p>{blog.surgePrice}</p>
            <p>{blog.someDrivers}</p>
            <p>{blog.itsAGoodIdea}</p>
            <p>{blog.documentEverything}</p>
            <p>{blog.ifAny}</p>
            <h3>{blog.h3PanamaBuses}</h3>
            <h4>{blog.h4RidingMetro}</h4>
            <figure className="metro-bus">
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/transportation/panama-metro-bus-and-cab-320.webp 320w, /images/transportation/panama-metro-bus-and-cab-480.webp 480w, /images/transportation/panama-metro-bus-and-cab-640.webp 640w, /images/transportation/panama-metro-bus-and-cab-768.webp 768w, /images/transportation/panama-metro-bus-and-cab.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/transportation/panama-metro-bus-and-cab-320.jpg 320w, /images/transportation/panama-metro-bus-and-cab-480.jpg 480w, /images/transportation/panama-metro-bus-and-cab-640.jpg 640w, /images/transportation/panama-metro-bus-and-cab-768.jpg 768w, /images/transportation/panama-metro-bus-and-cab.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.metroCab}
                  alt={blog.metroCabAltText}
                />
              </picture>
              <figcaption>{blog.metroCabCaption}</figcaption>
            </figure>
            <p>{blog.theMetroIs}</p>
            <p>{blog.theCostIs}</p>
            <p>{blog.thereAre}</p>
            <p>{blog.whenYouGetOn}</p>
            <p>{blog.whenReady}</p>
            <h4>{blog.h4busRidesOut}</h4>
            <figure className="albrook-terminal">
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/transportation/albrook-terminal-320.webp 320w, /images/transportation/albrook-terminal-480.webp 480w, /images/transportation/albrook-terminal-640.webp 640w, /images/transportation/albrook-terminal-768.webp 768w, /images/transportation/albrook-terminal.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/transportation/albrook-terminal-320.jpg 320w, /images/transportation/albrook-terminal-480.jpg 480w, /images/transportation/albrook-terminal-640.jpg 640w, /images/transportation/albrook-terminal-768.jpg 768w, /images/transportation/albrook-terminal.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.albrookEntr}
                  alt={blog.albrookEntrAltText}
                />
              </picture>
              <figcaption>{blog.albrookEntrBusCaption}</figcaption>
            </figure>
            <p>{blog.granTerminal}</p>
            <p>{blog.youCanTake}</p>
            <h2>{blog.h2CoronadoBus}</h2>
            <p>{blog.coronadoIs}</p>
            <p>{blog.thereAreBusesTo}</p>
            <p>{blog.youHaveTo}</p>
            <p>{blog.ticketWindows}</p>
            <p>{blog.theTicketPrice}</p>
            <p>{blog.afterPurchasing}</p>
            <p>{blog.youWillSee}</p>
            <p>{blog.twoBusesChoose}</p>
            <figure className="comfy-bus">
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/transportation/panama-comfy-bus-320.webp 320w, /images/transportation/panama-comfy-bus-480.webp 480w, /images/transportation/panama-comfy-bus-640.webp 640w, /images/transportation/panama-comfy-bus-768.webp 768w, /images/transportation/panama-comfy-bus.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/transportation/panama-comfy-bus-320.jpg 320w, /images/transportation/panama-comfy-bus-480.jpg 480w, /images/transportation/panama-comfy-bus-640.jpg 640w, /images/transportation/panama-comfy-bus-768.jpg 768w, /images/transportation/panama-comfy-bus.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.comfyBus}
                  alt={blog.comfyBusAltText}
                />
              </picture>
              <figcaption>{blog.comfyBusCaption}</figcaption>
            </figure>
            <figure className="coaster-bus">
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/transportation/panama-coaster-bus-320.webp 320w, /images/transportation/panama-coaster-bus-480.webp 480w, /images/transportation/panama-coaster-bus-640.webp 640w, /images/transportation/panama-coaster-bus-768.webp 768w, /images/transportation/panama-coaster-bus.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/transportation/panama-coaster-bus-320.jpg 320w, /images/transportation/panama-coaster-bus-480.jpg 480w, /images/transportation/panama-coaster-bus-640.jpg 640w, /images/transportation/panama-coaster-bus-768.jpg 768w, /images/transportation/panama-coaster-bus.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.coasterBus}
                  alt={blog.coasterBusAltText}
                />
              </picture>
              <figcaption>{blog.coasterBusCaption}</figcaption>
            </figure>
            <p>{blog.takeLarger}</p>
            <p>{blog.whenYouGetOnThe}</p>
            <p>{blog.coronadoIsAbout}</p>
            <figure className="coronado-stop">
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/transportation/coronado-panama-entrance-320.webp 320w, /images/transportation/coronado-panama-entrance-480.webp 480w, /images/transportation/coronado-panama-entrance-640.webp 640w, /images/transportation/coronado-panama-entrance-768.webp 768w, /images/transportation/coronado-panama-entrance.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/transportation/coronado-panama-entrance-320.jpg 320w, /images/transportation/coronado-panama-entrance-480.jpg 480w, /images/transportation/coronado-panama-entrance-640.jpg 640w, /images/transportation/coronado-panama-entrance-768.jpg 768w, /images/transportation/coronado-panama-entrance.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.coronadoEntrance}
                  alt={blog.coronadoEntranceAltText}
                />
              </picture>
              <figcaption>{blog.coronadoEntranceCaption}</figcaption>
            </figure>
            <p>{blog.pedestrianBridge}</p>
            <figure className="coronado-map">
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/transportation/map-of-coronado-320.webp 320w, /images/transportation/map-of-coronado-480.webp 480w, /images/transportation/map-of-coronado-640.webp 640w, /images/transportation/map-of-coronado-768.webp 768w, /images/transportation/map-of-coronado.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/transportation/map-of-coronado-320.jpg 320w, /images/transportation/map-of-coronado-480.jpg 480w, /images/transportation/map-of-coronado-640.jpg 640w, /images/transportation/map-of-coronado-768.jpg 768w, /images/transportation/map-of-coronado.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.coronadoMap}
                  alt={blog.coronadoMapAltText}
                />
              </picture>
              <figcaption>
                {blog.coronadoMapCaption}
                <br />
                <span>{blog.courtesyGoogle}</span>
              </figcaption>
            </figure>
            <p>{blog.youCanTakeCab}</p>
            <figure className="taxi-truck">
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/transportation/panama-taxi-pickup-truck-320.webp 320w, /images/transportation/panama-taxi-pickup-truck-480.webp 480w, /images/transportation/panama-taxi-pickup-truck-640.webp 640w, /images/transportation/panama-taxi-pickup-truck-768.webp 768w, /images/transportation/panama-taxi-pickup-truck.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/transportation/panama-taxi-pickup-truck-320.jpg 320w, /images/transportation/panama-taxi-pickup-truck-480.jpg 480w, /images/transportation/panama-taxi-pickup-truck-640.jpg 640w, /images/transportation/panama-taxi-pickup-truck-768.jpg 768w, /images/transportation/panama-taxi-pickup-truck.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.taxiPickUp}
                  alt={blog.taxiPickUpAltText}
                />
              </picture>
              <figcaption>{blog.taxiPickUpCaption}</figcaption>
            </figure>
            <p>{blog.smallWhite}</p>
            <figure className="mini-van">
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/transportation/coronado-shuttle-bus-320.webp 320w, /images/transportation/coronado-shuttle-bus-480.webp 480w, /images/transportation/coronado-shuttle-bus-640.webp 640w, /images/transportation/coronado-shuttle-bus-768.webp 768w, /images/transportation/coronado-shuttle-bus.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/transportation/coronado-shuttle-bus-320.jpg 320w, /images/transportation/coronado-shuttle-bus-480.jpg 480w, /images/transportation/coronado-shuttle-bus-640.jpg 640w, /images/transportation/coronado-shuttle-bus-768.jpg 768w, /images/transportation/coronado-shuttle-bus.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.coronadoShuttle}
                  alt={blog.coronadoShuttleAltText}
                />
              </picture>
              <figcaption>{blog.coronadoShuttleCaption}</figcaption>
            </figure>
            <p>{blog.packIn}</p>
            <figure className="inside-van">
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/transportation/coronado-shuttle-bus-interior-320.webp 320w, /images/transportation/coronado-shuttle-bus-interior-480.webp 480w, /images/transportation/coronado-shuttle-bus-interior-640.webp 640w, /images/transportation/coronado-shuttle-bus-interior-768.webp 768w, /images/transportation/coronado-shuttle-bus-interior.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/transportation/coronado-shuttle-bus-interior-320.jpg 320w, /images/transportation/coronado-shuttle-bus-interior-480.jpg 480w, /images/transportation/coronado-shuttle-bus-interior-640.jpg 640w, /images/transportation/coronado-shuttle-bus-interior-768.jpg 768w, /images/transportation/coronado-shuttle-bus-interior.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.shuttleInterior}
                  alt={blog.shuttleInteriorAltText}
                />
              </picture>
              <figcaption>{blog.shuttleInteriorCaption}</figcaption>
            </figure>
            <p>{blog.gates}</p>
            <figure className="gates">
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/transportation/coronado-panama-gates-320.webp 320w, /images/transportation/coronado-panama-gates-480.webp 480w, /images/transportation/coronado-panama-gates-640.webp 640w, /images/transportation/coronado-panama-gates-768.webp 768w, /images/transportation/coronado-panama-gates.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/transportation/coronado-panama-gates-320.jpg 320w, /images/transportation/coronado-panama-gates-480.jpg 480w, /images/transportation/coronado-panama-gates-640.jpg 640w, /images/transportation/coronado-panama-gates-768.jpg 768w, /images/transportation/coronado-panama-gates.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.coronadoGates}
                  alt={blog.coronadoGatesAltText}
                />
              </picture>
              <figcaption>{blog.coronadoGatesCaption}</figcaption>
            </figure>
            <h2>{blog.h2DiablosRojos}</h2>
            <p>{blog.theDiablosRojos}</p>
            <figure className="diablos">
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/transportation/panamanian-diablo-bus-320.webp 320w, /images/transportation/panamanian-diablo-bus-480.webp 480w, /images/transportation/panamanian-diablo-bus-640.webp 640w, /images/transportation/panamanian-diablo-bus-768.webp 768w, /images/transportation/panamanian-diablo-bus.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/transportation/panamanian-diablo-bus-320.jpg 320w, /images/transportation/panamanian-diablo-bus-480.jpg 480w, /images/transportation/panamanian-diablo-bus-640.jpg 640w, /images/transportation/panamanian-diablo-bus-768.jpg 768w, /images/transportation/panamanian-diablo-bus.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.diablo}
                  alt={blog.diabloAltText}
                />
              </picture>
              <figcaption>{blog.diabloCaption}</figcaption>
            </figure>
            <p>{blog.theyAreIndepen}</p>
            <figure className="chicken-bus">
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/transportation/panama-chicken-bus-320.webp 320w, /images/transportation/panama-chicken-bus-480.webp 480w, /images/transportation/panama-chicken-bus-640.webp 640w, /images/transportation/panama-chicken-bus-768.webp 768w, /images/transportation/panama-chicken-bus.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/transportation/panama-chicken-bus-320.jpg 320w, /images/transportation/panama-chicken-bus-480.jpg 480w, /images/transportation/panama-chicken-bus-640.jpg 640w, /images/transportation/panama-chicken-bus-768.jpg 768w, /images/transportation/panama-chicken-bus.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.panamaChickenBus}
                  alt={blog.panamaChickenBusAltText}
                />
              </picture>
              <figcaption>{blog.panamaChickenBusCaption}</figcaption>
            </figure>
            <p>{blog.panamaPhased}</p>
            <p>{blog.becauseTheyWere}</p>
            <p>{blog.youMayStill}</p>
            <h2>{blog.h2Chiva}</h2>
            <p>{blog.chivasAre}</p>
            <p>{blog.nowadays}</p>
            <figure className="chiva">
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/transportation/panama-chiva-320.webp 320w, /images/transportation/panama-chiva-480.webp 480w, /images/transportation/panama-chiva-640.webp 640w, /images/transportation/panama-chiva-768.webp 768w, /images/transportation/panama-chiva.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/transportation/panama-chiva-320.jpg 320w, /images/transportation/panama-chiva-480.jpg 480w, /images/transportation/panama-chiva-640.jpg 640w, /images/transportation/panama-chiva-768.jpg 768w, /images/transportation/panama-chiva.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.chiva}
                  alt={blog.chivaAltText}
                />
              </picture>
              <figcaption>{blog.chivaCaption}</figcaption>
            </figure>
            <p>{blog.summary}</p>
            <figure className="alex-bus last-figure">
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/transportation/alex-deleon-panama-bus-320.webp 320w, /images/transportation/alex-deleon-panama-bus-480.webp 480w, /images/transportation/alex-deleon-panama-bus-640.webp 640w, /images/transportation/alex-deleon-panama-bus-768.webp 768w, /images/transportation/alex-deleon-panama-bus.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/transportation/alex-deleon-panama-bus-320.jpg 320w, /images/transportation/alex-deleon-panama-bus-480.jpg 480w, /images/transportation/alex-deleon-panama-bus-640.jpg 640w, /images/transportation/alex-deleon-panama-bus-768.jpg 768w, /images/transportation/alex-deleon-panama-bus.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.alexBus}
                  alt={blog.alexBusAltText}
                />
              </picture>
              <figcaption>{blog.alexBusCaption}</figcaption>
            </figure>
            <div className="button-parent">
              <button
                title="Is Panama safe to travel alone?"
                onClick={goToSafetyStory}
              >
                &#60;
              </button>
              <button
                title="Things to do in El Cangrejo"
                onClick={goToElCangrejoStory}
              >
                &#62;
              </button>
            </div>
          </section>
        )}
      </main>
    );
  }
  if (document.title === "Things to do in El Cangrejo") {
    return (
      <main id="main-element" className={`blog-details margin `}>
        {isPending && <div>Loading...</div>}
        {error && <div className="load-error">{error}</div>}
        {blog && (
          <section className={blog.className}>
            <div className="line-divider centered wide">
              <p className="diamond-text-box date">
                <span className="left"></span>
                <span className="content caps centered">{blog.date}</span>
                <span className="right"></span>
              </p>
            </div>
            <h1 className="centered post-title">{blog.title}</h1>
            <p className="body intro">{blog.intro}</p>
            <div className="share-parent">
              <FacebookShareButton
                url={currentPageUrl}
                hashtag="#panama"
                aria-label="facebook"
              >
                <FacebookIcon size={35} />
              </FacebookShareButton>
              <TwitterShareButton
                url={currentPageUrl}
                hashtags={["pickuppanama", "el cangrejo", "panama"]}
                title={"Things to do in El Cangrejo"}
                aria-label="twitter"
              >
                <TwitterIcon size={35} />
              </TwitterShareButton>
              <LinkedinShareButton
                url={currentPageUrl}
                title={"Things to do in El Cangrejo"}
                summary={
                  "Discover the best restaurants and activities in El Cangrejo and learn why it is worth vistiting."
                }
                aria-label="linkedin"
              >
                <LinkedinIcon size={35} />
              </LinkedinShareButton>
              <EmailShareButton
                url={currentPageUrl}
                subject={"Things to do in El Cangrejo"}
                body={
                  "Discover the best restaurants and activities in El Cangrejo and learn why it is worth vistiting."
                }
                aria-label="email"
                separator={" "}
              >
                <EmailIcon size={35} />
              </EmailShareButton>
            </div>
            <figure>
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/elCangrejo/el-cangrejo-320.webp 320w, /images/elCangrejo/el-cangrejo-480.webp 480w, /images/elCangrejo/el-cangrejo-640.webp 640w, /images/elCangrejo/el-cangrejo-768.webp 768w, /images/elCangrejo/el-cangrejo.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/elCangrejo/el-cangrejo-320.jpg 320w, /images/elCangrejo/el-cangrejo-480.jpg 480w, /images/elCangrejo/el-cangrejo-640.jpg 640w, /images/elCangrejo/el-cangrejo-768.jpg 768w, /images/elCangrejo/el-cangrejo.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  fetchpriority="high"
                  className="no-right-click"
                  src={blog.elCran}
                  alt={blog.elCranAltText}
                />
              </picture>
              <figcaption>{blog.elCranCaption}</figcaption>
            </figure>
            <p>{blog.itIsOne}</p>
            <p>{blog.elCangrejoHas}</p>
            <p>
              <strong>{blog.itRep}</strong>
            </p>
            <h2>{blog.howItGotH2}</h2>
            <p>{blog.theNameOf}</p>
            <p>{blog.overHead}</p>
            <p>{blog.claws}</p>
            <figure className="crab">
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/elCangrejo/map-of-el-cangrejo-320.webp 320w, /images/elCangrejo/map-of-el-cangrejo-480.webp 480w, /images/elCangrejo/map-of-el-cangrejo-640.webp 640w, /images/elCangrejo/map-of-el-cangrejo.webp 768w, /images/elCangrejo/map-of-el-cangrejo.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/elCangrejo/map-of-el-cangrejo-320.jpg 320w, /images/elCangrejo/map-of-el-cangrejo-480.jpg 480w, /images/elCangrejo/map-of-el-cangrejo-640.jpg 640w, /images/elCangrejo/map-of-el-cangrejo.jpg 768w, /images/elCangrejo/map-of-el-cangrejo.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.crab}
                  alt={blog.crabAltText}
                />
              </picture>
              <figcaption>{blog.crabCaption}</figcaption>
            </figure>
            <h2>{blog.atmosphereH2}</h2>
            <p>{blog.atmoshphereIs}</p>
            <p>{blog.natureAndUnique}</p>
            <figure>
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/elCangrejo/bird-in-park-320.webp 320w, /images/elCangrejo/bird-in-park-480.webp 480w, /images/elCangrejo/bird-in-park-640.webp 640w, /images/elCangrejo/bird-in-park-768.webp 768w, /images/elCangrejo/bird-in-park.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/elCangrejo/bird-in-park-320.jpg 320w, /images/elCangrejo/bird-in-park-480.jpg 480w, /images/elCangrejo/bird-in-park-640.jpg 640w, /images/elCangrejo/bird-in-park-768.jpg 768w, /images/elCangrejo/bird-in-park.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.bird}
                  alt={blog.birdAltText}
                />
              </picture>
              <figcaption>{blog.birdCaption}</figcaption>
            </figure>
            <p>{blog.viaArgIs}</p>
            <p>{blog.theStreetIs}</p>
            <p>{blog.restaurantOptions}</p>
            <h2>{blog.restaurantsInH2}</h2>
            <h3>{blog.elTrapH3}</h3>
            <p>{blog.offersAuth}</p>
            <figure>
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/elCangrejo/el-trapiche-panama-320.webp 320w, /images/elCangrejo/el-trapiche-panama-480.webp 480w, /images/elCangrejo/el-trapiche-panama-640.webp 640w, /images/elCangrejo/el-trapiche-panama-768.webp 768w, /images/elCangrejo/el-trapiche-panama.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/elCangrejo/el-trapiche-panama-320.jpg 320w, /images/elCangrejo/el-trapiche-panama-480.jpg 480w, /images/elCangrejo/el-trapiche-panama-640.jpg 640w, /images/elCangrejo/el-trapiche-panama-768.jpg 768w, /images/elCangrejo/el-trapiche-panama.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.elTrap}
                  alt={blog.elTrapAltText}
                />
              </picture>
              <figcaption>{blog.elTrapCaption}</figcaption>
            </figure>
            <figure>
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/elCangrejo/panamanian-meal-el-trapiche-320.webp 320w, /images/elCangrejo/panamanian-meal-el-trapiche-480.webp 480w, /images/elCangrejo/panamanian-meal-el-trapiche-640.webp 640w, /images/elCangrejo/panamanian-meal-el-trapiche-768.webp 768w, /images/elCangrejo/panamanian-meal-el-trapiche.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/elCangrejo/panamanian-meal-el-trapiche-320.jpg 320w, /images/elCangrejo/panamanian-meal-el-trapiche-480.jpg 480w, /images/elCangrejo/panamanian-meal-el-trapiche-640.jpg 640w, /images/elCangrejo/panamanian-meal-el-trapiche-768.jpg 768w, /images/elCangrejo/panamanian-meal-el-trapiche.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.elTrapMeal}
                  alt={blog.elTrapMealAltText}
                />
              </picture>
              <figcaption>{blog.elTrapMealCaption}</figcaption>
            </figure>
            <figure className="el-trap-food">
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/elCangrejo/meal-at-el-trapiche-320.webp 320w, /images/elCangrejo/meal-at-el-trapiche-480.webp 480w, /images/elCangrejo/meal-at-el-trapiche-640.webp 640w, /images/elCangrejo/meal-at-el-trapiche-768.webp 768w, /images/elCangrejo/meal-at-el-trapiche.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/elCangrejo/meal-at-el-trapiche-320.jpg 320w, /images/elCangrejo/meal-at-el-trapiche-480.jpg 480w, /images/elCangrejo/meal-at-el-trapiche-640.jpg 640w, /images/elCangrejo/meal-at-el-trapiche-768.jpg 768w, /images/elCangrejo/meal-at-el-trapiche.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.elTrapMeal2}
                  alt={blog.elTrapMeal2AltText}
                />
              </picture>
              <figcaption>{blog.elTrapMeal2Caption}</figcaption>
            </figure>
            <figure className="el-trap-food">
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/elCangrejo/el-trapiche-meal-320.webp 320w, /images/elCangrejo/el-trapiche-meal-480.webp 480w, /images/elCangrejo/el-trapiche-meal-640.webp 640w, /images/elCangrejo/el-trapiche-meal-768.webp 768w, /images/elCangrejo/el-trapiche-meal.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/elCangrejo/el-trapiche-meal-320.jpg 320w, /images/elCangrejo/el-trapiche-meal-480.jpg 480w, /images/elCangrejo/el-trapiche-meal-640.jpg 640w, /images/elCangrejo/el-trapiche-meal-768.jpg 768w, /images/elCangrejo/el-trapiche-meal.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.elTrapMealA}
                  alt={blog.elTrapMealAAltText}
                />
              </picture>
              <figcaption>{blog.elTrapMealACaption}</figcaption>
            </figure>
            <h3>{blog.sieteMaresH3}</h3>
            <p>{blog.hasAnElegant}</p>
            <figure>
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/elCangrejo/siete-mares-panama-city-320.webp 320w, /images/elCangrejo/siete-mares-panama-city-480.webp 480w, /images/elCangrejo/siete-mares-panama-city-640.webp 640w, /images/elCangrejo/siete-mares-panama-city-768.webp 768w, /images/elCangrejo/siete-mares-panama-city.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/elCangrejo/siete-mares-panama-city-320.jpg 320w, /images/elCangrejo/siete-mares-panama-city-480.jpg 480w, /images/elCangrejo/siete-mares-panama-city-640.jpg 640w, /images/elCangrejo/siete-mares-panama-city-768.jpg 768w, /images/elCangrejo/siete-mares-panama-city.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.sieteMares}
                  alt={blog.sieteMaresAltText}
                />
              </picture>
              <figcaption>{blog.sieteMaresCaption}</figcaption>
            </figure>
            <figure>
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/elCangrejo/siete-mares-interior-320.webp 320w, /images/elCangrejo/siete-mares-interior-480.webp 480w, /images/elCangrejo/siete-mares-interior-640.webp 640w, /images/elCangrejo/siete-mares-interior-768.webp 768w, /images/elCangrejo/siete-mares-interior.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/elCangrejo/siete-mares-interior-320.jpg 320w, /images/elCangrejo/siete-mares-interior-480.jpg 480w, /images/elCangrejo/siete-mares-interior-640.jpg 640w, /images/elCangrejo/siete-mares-interior-768.jpg 768w, /images/elCangrejo/siete-mares-interior.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.sieteMaresInt}
                  alt={blog.sieteMaresIntAltText}
                />
              </picture>
              <figcaption>{blog.sieteMaresIntCaption}</figcaption>
            </figure>
            <figure className="siete">
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/elCangrejo/lobster-meal-siete-mares-320.webp 320w, /images/elCangrejo/lobster-meal-siete-mares-480.webp 480w, /images/elCangrejo/lobster-meal-siete-mares-640.webp 640w, /images/elCangrejo/lobster-meal-siete-mares-768.webp 768w, /images/elCangrejo/lobster-meal-siete-mares.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/elCangrejo/lobster-meal-siete-mares-320.jpg 320w, /images/elCangrejo/lobster-meal-siete-mares-480.jpg 480w, /images/elCangrejo/lobster-meal-siete-mares-640.jpg 640w, /images/elCangrejo/lobster-meal-siete-mares-768.jpg 768w, /images/elCangrejo/lobster-meal-siete-mares.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />

                <img
                  className="no-right-click"
                  src={blog.sieteMaresFood}
                  alt={blog.sieteMaresFoodAltText}
                />
              </picture>
              <figcaption>{blog.sieteMaresFoodCaption}</figcaption>
            </figure>
            <h3>{blog.chrisRestH3}</h3>
            <p>{blog.isAGood}</p>
            <figure>
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/elCangrejo/chris-restaurante-grill-320.webp 320w, /images/elCangrejo/chris-restaurante-grill-480.webp 480w, /images/elCangrejo/chris-restaurante-grill-640.webp 640w, /images/elCangrejo/chris-restaurante-grill-768.webp 768w, /images/elCangrejo/chris-restaurante-grill.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/elCangrejo/chris-restaurante-grill-320.jpg 320w, /images/elCangrejo/chris-restaurante-grill-480.jpg 480w, /images/elCangrejo/chris-restaurante-grill-640.jpg 640w, /images/elCangrejo/chris-restaurante-grill-768.jpg 768w, /images/elCangrejo/chris-restaurante-grill.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />

                <img
                  className="no-right-click"
                  src={blog.chrisR}
                  alt={blog.chrisRAltText}
                />
              </picture>
              <figcaption>{blog.chrisRCaption}</figcaption>
            </figure>
            <figure>
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/elCangrejo/chris-restaurante-interior-320.webp 320w, /images/elCangrejo/chris-restaurante-interior-480.webp 480w, /images/elCangrejo/chris-restaurante-interior-640.webp 640w, /images/elCangrejo/chris-restaurante-interior-768.webp 768w, /images/elCangrejo/chris-restaurante-interior.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/elCangrejo/chris-restaurante-interior-320.jpg 320w, /images/elCangrejo/chris-restaurante-interior-480.jpg 480w, /images/elCangrejo/chris-restaurante-interior-640.jpg 640w, /images/elCangrejo/chris-restaurante-interior-768.jpg 768w, /images/elCangrejo/chris-restaurante-interior.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.chrisR2}
                  alt={blog.chrisR2AltText}
                />
              </picture>
              <figcaption>{blog.chrisR2Caption}</figcaption>
            </figure>
            <h3>{blog.additionalH3}</h3>
            <ul>
              <li>
                {blog.liOne} <span>{blog.spanOne}</span>
              </li>
              <li>
                {blog.liTwo} <span>{blog.spanTwo}</span>
              </li>
              <li>
                {blog.liThree} <span>{blog.spanThree}</span>
              </li>
              <li>
                {blog.liFour} <span>{blog.spanFour}</span>
              </li>
              <li>
                {blog.liFive} <span>{blog.spanFive}</span>
              </li>
              <li>
                {blog.liSix} <span>{blog.spanSix}</span>
              </li>
              <li>
                {blog.liSeven} <span>{blog.spanSeven}</span>
              </li>
              <li>
                {blog.liEight} <span>{blog.spanEight}</span>
              </li>
              <li>
                {blog.liEightA} <span>{blog.spanEightA}</span>
              </li>
              <li>
                <figure className="lee">
                  <picture>
                    <source
                      type="image/webp"
                      srcSet={
                        process.env.PUBLIC_URL +
                        "/images/elCangrejo/don-lee-el-cangrejo-320.webp 320w, /images/elCangrejo/don-lee-el-cangrejo-480.webp 480w, /images/elCangrejo/don-lee-el-cangrejo-640.webp 640w, /images/elCangrejo/don-lee-el-cangrejo-768.webp 768w, /images/elCangrejo/don-lee-el-cangrejo.webp 1124w"
                      }
                      sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1124px"
                    />
                    <source
                      type="image/jpg"
                      srcSet={
                        process.env.PUBLIC_URL +
                        "/images/elCangrejo/don-lee-el-cangrejo-320.jpg 320w, /images/elCangrejo/don-lee-el-cangrejo-480.jpg 480w, /images/elCangrejo/don-lee-el-cangrejo-640.jpg 640w, /images/elCangrejo/don-lee-el-cangrejo-768.jpg 768w, /images/elCangrejo/don-lee-el-cangrejo.jpg 1124w"
                      }
                      sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1124px"
                    />
                    <img
                      className="no-right-click"
                      src={blog.donLee}
                      alt={blog.donLeeAltText}
                    />
                  </picture>
                  <figcaption>{blog.donLeeCaption}</figcaption>
                </figure>
              </li>
              <li>
                {blog.liNine} <span>{blog.spanNine}</span>
              </li>
              <li>
                {blog.liTen} <span>{blog.spanTen}</span>
              </li>
              <li>
                {blog.liEleven} <span>{blog.spanEleven}</span>
              </li>
              <li>
                {blog.liTwelve} <span>{blog.spanTwelve}</span>
              </li>
              <li>
                {blog.liThirteen} <span>{blog.spanThirteen}</span>
              </li>
              <li>
                {blog.liFourteen} <span>{blog.spanFourteen}</span>
              </li>
              <li>
                {blog.liFifteen} <span>{blog.spanFifteen}</span>
              </li>
              <li>
                {blog.liThirtySix} <span>{blog.spanThirtySix}</span>
              </li>
              <li>
                {blog.liSixteen} <span>{blog.spanSixteen}</span>
              </li>
              <li>
                {blog.liSeventeen} <span>{blog.spanSeventeen}</span>
              </li>
              <li>
                {blog.liEighteen} <span>{blog.spanEighteen}</span>
              </li>
              <li>
                {blog.liNineteen} <span>{blog.spanNineteen}</span>
              </li>
              <li>
                {blog.liTwenty} <span>{blog.spanTwenty}</span>
              </li>
              <li>
                {blog.liTwentyOne} <span>{blog.spanTwentyOne}</span>
              </li>
              <li>
                {blog.liTwentyTwo} <span>{blog.spanTwentyTwo}</span>
              </li>
              <li>
                {blog.liTwentyThree} <span>{blog.spanTwentyThree}</span>
              </li>
              <li>
                {blog.liTwentyThreeA} <span>{blog.spanTwentyThreeA}</span>
              </li>
              <li>
                <figure className="manlos">
                  <picture>
                    <source
                      type="image/webp"
                      srcSet={
                        process.env.PUBLIC_URL +
                        "/images/elCangrejo/manolos-320.webp 320w, /images/elCangrejo/manolos-480.webp 480w, /images/elCangrejo/manolos-640.webp 640w, /images/elCangrejo/manolos-768.webp 768w, /images/elCangrejo/manolos.webp 1124w"
                      }
                      sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1124px"
                    />
                    <source
                      type="image/jpg"
                      srcSet={
                        process.env.PUBLIC_URL +
                        "/images/elCangrejo/manolos-320.jpg 320w, /images/elCangrejo/manolos-480.jpg 480w, /images/elCangrejo/manolos-640.jpg 640w, /images/elCangrejo/manolos-768.jpg 768w, /images/elCangrejo/manolos.jpg 1124w"
                      }
                      sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1124px"
                    />
                    <img
                      className="no-right-click"
                      src={blog.manolos}
                      alt={blog.manolosAltText}
                    />
                  </picture>
                  <figcaption>{blog.manolosCaption}</figcaption>
                </figure>
              </li>
              <li>
                {blog.liTwentyFour} <span>{blog.spanTwentyFour}</span>
              </li>
              <li>
                {blog.liTwentyFourA} <span>{blog.spanTwentyFourA}</span>
              </li>
              <li>
                {blog.liTwentyFourB} <span>{blog.spanTwentyFourB}</span>
              </li>
              <li>
                {blog.liTwentyFourC} <span>{blog.spanTwentyFourC}</span>
              </li>
              <li>
                {blog.liTwentyFive} <span>{blog.spanTwentyFive}</span>
              </li>
              <li>
                {blog.liTwentySix} <span>{blog.spanTwentySix}</span>
              </li>
              <li>
                {blog.liTwentySeven} <span>{blog.spanTwentySeven}</span>
              </li>
              <li>
                {blog.liTwentyEight} <span>{blog.spanTwentyEight}</span>
              </li>
              <li>
                {blog.liTwentyNine} <span>{blog.spanTwentyNine}</span>
              </li>
              <li>
                {blog.liThirty} <span>{blog.spanThirty}</span>
              </li>
              <li>
                {blog.liThirtyOne} <span>{blog.spanThirtyOne}</span>
              </li>
              <li>
                {blog.liThirtyTwo} <span>{blog.spanThirtyTwo}</span>
              </li>
              <li>
                {blog.liThirtyThree} <span>{blog.spanThirtyThree}</span>
              </li>
              <li>
                {blog.liThirtyFour} <span>{blog.spanThirtyFour}</span>
              </li>
              <li>
                {blog.liThirtyFive} <span>{blog.spanThirtyFive}</span>
              </li>
              <li>
                {blog.liThirtySeven} <span>{blog.spanThirtySeven}</span>
              </li>
              <li>
                {blog.liThirtyEight} <span>{blog.spanThirtyEight}</span>
              </li>
              <li>
                <figure className="sen">
                  <picture>
                    <source
                      type="image/webp"
                      srcSet={
                        process.env.PUBLIC_URL +
                        "/images/elCangrejo/sen-vietnam-320.webp 320w, /images/elCangrejo/sen-vietnam-480.webp 480w, /images/elCangrejo/sen-vietnam-640.webp 640w, /images/elCangrejo/sen-vietnam-768.webp 768w, /images/elCangrejo/sen-vietnam.webp 1124w"
                      }
                      sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1124px"
                    />
                    <source
                      type="image/jpg"
                      srcSet={
                        process.env.PUBLIC_URL +
                        "/images/elCangrejo/sen-vietnam-320.jpg 320w, /images/elCangrejo/sen-vietnam-480.jpg 480w, /images/elCangrejo/sen-vietnam-640.jpg 640w, /images/elCangrejo/sen-vietnam-768.jpg 768w, /images/elCangrejo/sen-vietnam.jpg 1124w"
                      }
                      sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1124px"
                    />
                    <img
                      className="no-right-click"
                      src={blog.sen}
                      alt={blog.senAltText}
                    />
                  </picture>
                  <figcaption>{blog.senCaption}</figcaption>
                </figure>
              </li>
              <li>
                {blog.liThirtyNine} <span>{blog.spanThirtyNine}</span>
              </li>
              <li>
                {blog.liForty} <span>{blog.spanForty}</span>
              </li>
              <li>
                {blog.liFortyOne} <span>{blog.spanFortyOne}</span>
              </li>
              <li>
                {blog.liFortyTwo} <span>{blog.spanFortyTwo}</span>
              </li>
              <li>
                {blog.liFortyThree} <span>{blog.spanFortyThree}</span>
              </li>
            </ul>
            <h3>{blog.barsPubsH3}</h3>
            <figure>
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/elCangrejo/el-cangrejo-night-320.webp 320w, /images/elCangrejo/el-cangrejo-night-480.webp 480w, /images/elCangrejo/el-cangrejo-night-640.webp 640w, /images/elCangrejo/el-cangrejo-night-768.webp 768w, /images/elCangrejo/el-cangrejo-night.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/elCangrejo/el-cangrejo-night-320.jpg 320w, /images/elCangrejo/el-cangrejo-night-480.jpg 480w, /images/elCangrejo/el-cangrejo-night-640.jpg 640w, /images/elCangrejo/el-cangrejo-night-768.jpg 768w, /images/elCangrejo/el-cangrejo-night.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.night}
                  alt={blog.nightAltText}
                />
              </picture>
              <figcaption>{blog.nightCaption}</figcaption>
            </figure>
            <ul>
              <li>{blog.bpOne}</li>
              <li>{blog.bpOneA}</li>
              <li>{blog.bpTwo}</li>
              <li>{blog.bpThree}</li>
              <li>{blog.bpFour}</li>
              <li>{blog.bpFive}</li>
              <li>{blog.bpSix}</li>
              <li>{blog.bpSeven}</li>
              <li>{blog.bpEight}</li>
              <li>{blog.bpNine}</li>
              <li>{blog.bpTen}</li>
              <li>{blog.bpEleven}</li>
              <li>{blog.bpTwelve}</li>
              <li>{blog.bpThirteen}</li>
              <li>{blog.bpFourteen}</li>
              <li>{blog.bpFifteen}</li>
              <li>{blog.bpSixteen}</li>
            </ul>
            <h2>{blog.parksH2}</h2>
            <h3>{blog.andresBelH3}</h3>
            <p>{blog.isLocated}</p>
            <p>{blog.thereAre}</p>
            <h3>{blog.parkOmarH3}</h3>
            <p>{blog.isALarg}</p>
            <p>{blog.bothOf}</p>
            <h3>{blog.iglesiaH3}</h3>
            <figure>
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/elCangrejo/iglesia-del-carmen-panama-320.webp 320w, /images/elCangrejo/iglesia-del-carmen-panama-480.webp 480w, /images/elCangrejo/iglesia-del-carmen-panama-640.webp 640w, /images/elCangrejo/iglesia-del-carmen-panama-768.webp 768w, /images/elCangrejo/iglesia-del-carmen-panama.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/elCangrejo/iglesia-del-carmen-panama-320.jpg 320w, /images/elCangrejo/iglesia-del-carmen-panama-480.jpg 480w, /images/elCangrejo/iglesia-del-carmen-panama-640.jpg 640w, /images/elCangrejo/iglesia-del-carmen-panama-768.jpg 768w, /images/elCangrejo/iglesia-del-carmen-panama.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.delCarmen}
                  alt={blog.delCarmenAltText}
                />
              </picture>
              <figcaption>{blog.delCarmenCaption}</figcaption>
            </figure>
            <p>{blog.theChurch}</p>
            <p>{blog.itHas}</p>
            <p>{blog.towers}</p>
            <p>{blog.itsConstruction}</p>
            <h3>{blog.ffH3}</h3>
            <figure>
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/elCangrejo/f-and-f-tower-panama-320.webp 320w, /images/elCangrejo/f-and-f-tower-panama-480.webp 480w, /images/elCangrejo/f-and-f-tower-panama-640.webp 640w, /images/elCangrejo/f-and-f-tower-panama-768.webp 768w, /images/elCangrejo/f-and-f-tower-panama.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/elCangrejo/f-and-f-tower-panama-320.jpg 320w, /images/elCangrejo/f-and-f-tower-panama-480.jpg 480w, /images/elCangrejo/f-and-f-tower-panama-640.jpg 640w, /images/elCangrejo/f-and-f-tower-panama-768.jpg 768w, /images/elCangrejo/f-and-f-tower-panama.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.ff}
                  alt={blog.ffAltText}
                />
              </picture>
              <figcaption>{blog.ffCaption}</figcaption>
            </figure>
            <p>{blog.theCork}</p>
            <h3>{blog.womanKneeH3}</h3>
            <figure>
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/elCangrejo/woman-on-her-knee-statue-320.webp 320w, /images/elCangrejo/woman-on-her-knee-statue-480.webp 480w, /images/elCangrejo/woman-on-her-knee-statue-640.webp 640w, /images/elCangrejo/woman-on-her-knee-statue-768.webp 768w, /images/elCangrejo/woman-on-her-knee-statue.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/elCangrejo/woman-on-her-knee-statue-320.jpg 320w, /images/elCangrejo/woman-on-her-knee-statue-480.jpg 480w, /images/elCangrejo/woman-on-her-knee-statue-640.jpg 640w, /images/elCangrejo/woman-on-her-knee-statue-768.jpg 768w, /images/elCangrejo/woman-on-her-knee-statue.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.onKnee}
                  alt={blog.onKneeAltText}
                />
              </picture>
              <figcaption>{blog.onKneeCaption}</figcaption>
            </figure>
            <p>{blog.thisIs}</p>
            <h3>{blog.robertoH3}</h3>
            <figure>
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/elCangrejo/roberto-duran-house-320.webp 320w, /images/elCangrejo/roberto-duran-house-480.webp 480w, /images/elCangrejo/roberto-duran-house-640.webp 640w, /images/elCangrejo/roberto-duran-house-768.webp 768w, /images/elCangrejo/roberto-duran-house.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/elCangrejo/roberto-duran-house-320.jpg 320w, /images/elCangrejo/roberto-duran-house-480.jpg 480w, /images/elCangrejo/roberto-duran-house-640.jpg 640w, /images/elCangrejo/roberto-duran-house-768.jpg 768w, /images/elCangrejo/roberto-duran-house.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.duran}
                  alt={blog.duranAltText}
                />
              </picture>
              <figcaption>{blog.duranCaption}</figcaption>
            </figure>
            <p>{blog.houseIn}</p>
            <h3>{blog.otherSitesH3}</h3>
            <figure>
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/elCangrejo/barberia-de-luis-el-cangrejo-320.webp 320w, /images/elCangrejo/barberia-de-luis-el-cangrejo-480.webp 480w, /images/elCangrejo/barberia-de-luis-el-cangrejo-640.webp 640w, /images/elCangrejo/barberia-de-luis-el-cangrejo-768.webp 768w, /images/elCangrejo/barberia-de-luis-el-cangrejo.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/elCangrejo/barberia-de-luis-el-cangrejo-320.jpg 320w, /images/elCangrejo/barberia-de-luis-el-cangrejo-480.jpg 480w, /images/elCangrejo/barberia-de-luis-el-cangrejo-640.jpg 640w, /images/elCangrejo/barberia-de-luis-el-cangrejo-768.jpg 768w, /images/elCangrejo/barberia-de-luis-el-cangrejo.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.barber}
                  alt={blog.barberAltText}
                />
              </picture>
              <figcaption>{blog.barberCaption}</figcaption>
            </figure>
            <figure>
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/elCangrejo/tryp-hotel-el-cangrejo-320.webp 320w, /images/elCangrejo/tryp-hotel-el-cangrejo-480.webp 480w, /images/elCangrejo/tryp-hotel-el-cangrejo-640.webp 640w, /images/elCangrejo/tryp-hotel-el-cangrejo-768.webp 768w, /images/elCangrejo/tryp-hotel-el-cangrejo.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/elCangrejo/tryp-hotel-el-cangrejo-320.jpg 320w, /images/elCangrejo/tryp-hotel-el-cangrejo-480.jpg 480w, /images/elCangrejo/tryp-hotel-el-cangrejo-640.jpg 640w, /images/elCangrejo/tryp-hotel-el-cangrejo-768.jpg 768w, /images/elCangrejo/tryp-hotel-el-cangrejo.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.tryp}
                  alt={blog.trypAltText}
                />
              </picture>
              <figcaption>{blog.trypCaption}</figcaption>
            </figure>
            <figure>
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/elCangrejo/el-coffee-shop-pty-320.webp 320w, /images/elCangrejo/el-coffee-shop-pty-480.webp 480w, /images/elCangrejo/el-coffee-shop-pty-640.webp 640w, /images/elCangrejo/el-coffee-shop-pty-768.webp 768w, /images/elCangrejo/el-coffee-shop-pty.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/elCangrejo/el-coffee-shop-pty-320.jpg 320w, /images/elCangrejo/el-coffee-shop-pty-480.jpg 480w, /images/elCangrejo/el-coffee-shop-pty-640.jpg 640w, /images/elCangrejo/el-coffee-shop-pty-768.jpg 768w, /images/elCangrejo/el-coffee-shop-pty.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.coffee}
                  alt={blog.coffeeAltText}
                />
              </picture>
              <figcaption>{blog.coffeeCaption}</figcaption>
            </figure>
            <figure>
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/elCangrejo/graffiti-wall-in-el-cangrejo-320.webp 320w, /images/elCangrejo/graffiti-wall-in-el-cangrejo-480.webp 480w, /images/elCangrejo/graffiti-wall-in-el-cangrejo-640.webp 640w, /images/elCangrejo/graffiti-wall-in-el-cangrejo-768.webp 768w, /images/elCangrejo/graffiti-wall-in-el-cangrejo.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/elCangrejo/graffiti-wall-in-el-cangrejo-320.jpg 320w, /images/elCangrejo/graffiti-wall-in-el-cangrejo-480.jpg 480w, /images/elCangrejo/graffiti-wall-in-el-cangrejo-640.jpg 640w, /images/elCangrejo/graffiti-wall-in-el-cangrejo-768.jpg 768w, /images/elCangrejo/graffiti-wall-in-el-cangrejo.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.graff}
                  alt={blog.graffAltText}
                />
              </picture>
              <figcaption>{blog.graffCaption}</figcaption>
            </figure>
            <figure>
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/elCangrejo/palm-trees-el-cangrejo-320.webp 320w, /images/elCangrejo/palm-trees-el-cangrejo-480.webp 480w, /images/elCangrejo/palm-trees-el-cangrejo-640.webp 640w, /images/elCangrejo/palm-trees-el-cangrejo-768.webp 768w, /images/elCangrejo/palm-trees-el-cangrejo.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/elCangrejo/palm-trees-el-cangrejo-320.jpg 320w, /images/elCangrejo/palm-trees-el-cangrejo-480.jpg 480w, /images/elCangrejo/palm-trees-el-cangrejo-640.jpg 640w, /images/elCangrejo/palm-trees-el-cangrejo-768.jpg 768w, /images/elCangrejo/palm-trees-el-cangrejo.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.palm}
                  alt={blog.palmAltText}
                />
              </picture>
              <figcaption>{blog.palmCaption}</figcaption>
            </figure>
            <figure>
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/elCangrejo/panamanian-motorcycle-club-320.webp 320w, /images/elCangrejo/panamanian-motorcycle-club-480.webp 480w, /images/elCangrejo/panamanian-motorcycle-club-640.webp 640w, /images/elCangrejo/panamanian-motorcycle-club-768.webp 768w, /images/elCangrejo/panamanian-motorcycle-club.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/elCangrejo/panamanian-motorcycle-club-320.jpg 320w, /images/elCangrejo/panamanian-motorcycle-club-480.jpg 480w, /images/elCangrejo/panamanian-motorcycle-club-640.jpg 640w, /images/elCangrejo/panamanian-motorcycle-club-768.jpg 768w, /images/elCangrejo/panamanian-motorcycle-club.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.moto}
                  alt={blog.motoAltText}
                />
              </picture>
              <figcaption>{blog.motoCaption}</figcaption>
            </figure>
            <figure>
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/elCangrejo/el-cangrejo-life-320.webp 320w, /images/elCangrejo/el-cangrejo-life-480.webp 480w, /images/elCangrejo/el-cangrejo-life-640.webp 640w, /images/elCangrejo/el-cangrejo-life-768.webp 768w, /images/elCangrejo/el-cangrejo-life.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/elCangrejo/el-cangrejo-life-320.jpg 320w, /images/elCangrejo/el-cangrejo-life-480.jpg 480w, /images/elCangrejo/el-cangrejo-life-640.jpg 640w, /images/elCangrejo/el-cangrejo-life-768.jpg 768w, /images/elCangrejo/el-cangrejo-life.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.life}
                  alt={blog.lifeAltText}
                />
              </picture>
              <figcaption>{blog.lifeCaption}</figcaption>
            </figure>
            <figure>
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/elCangrejo/street-performer-el-cangrejo-320.webp 320w, /images/elCangrejo/street-performer-el-cangrejo-480.webp 480w, /images/elCangrejo/street-performer-el-cangrejo-640.webp 640w, /images/elCangrejo/street-performer-el-cangrejo-768.webp 768w, /images/elCangrejo/street-performer-el-cangrejo.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/elCangrejo/street-performer-el-cangrejo-320.jpg 320w, /images/elCangrejo/street-performer-el-cangrejo-480.jpg 480w, /images/elCangrejo/street-performer-el-cangrejo-640.jpg 640w, /images/elCangrejo/street-performer-el-cangrejo-768.jpg 768w, /images/elCangrejo/street-performer-el-cangrejo.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.street}
                  alt={blog.streetAltText}
                />
              </picture>
              <figcaption>{blog.streetCaption}</figcaption>
            </figure>
            <figure>
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/elCangrejo/el-cangrejo-view-320.webp 320w, /images/elCangrejo/el-cangrejo-view-480.webp 480w, /images/elCangrejo/el-cangrejo-view-640.webp 640w, /images/elCangrejo/el-cangrejo-view-768.webp 768w, /images/elCangrejo/el-cangrejo-view.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/elCangrejo/el-cangrejo-view-320.jpg 320w, /images/elCangrejo/el-cangrejo-view-480.jpg 480w, /images/elCangrejo/el-cangrejo-view-640.jpg 640w, /images/elCangrejo/el-cangrejo-view-768.jpg 768w, /images/elCangrejo/el-cangrejo-view.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.view}
                  alt={blog.viewAltText}
                />
              </picture>
              <figcaption>{blog.viewCaption}</figcaption>
            </figure>
            <figure className="last-figure">
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/elCangrejo/el-cangrejo-panama-320.webp 320w, /images/elCangrejo/el-cangrejo-panama-480.webp 480w, /images/elCangrejo/el-cangrejo-panama-640.webp 640w, /images/elCangrejo/el-cangrejo-panama-768.webp 768w, /images/elCangrejo/el-cangrejo-panama.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/elCangrejo/el-cangrejo-panama-320.jpg 320w, /images/elCangrejo/el-cangrejo-panama-480.jpg 480w, /images/elCangrejo/el-cangrejo-panama-640.jpg 640w, /images/elCangrejo/el-cangrejo-panama-768.jpg 768w, /images/elCangrejo/el-cangrejo-panama.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.arch}
                  alt={blog.archAltText}
                />
              </picture>
              <figcaption>{blog.archCaption}</figcaption>
            </figure>
            <div className="button-parent">
              <button
                title="Transportation in Panama"
                onClick={goToTransportationStory}
              >
                &#60;
              </button>
              <button
                title="What languages are spoken in Panama?"
                onClick={goToSpanishStory}
              >
                &#62;
              </button>
            </div>
          </section>
        )}
      </main>
    );
  }
  if (document.title === "What languages are spoken in Panama?") {
    return (
      <main id="main-element" className={`blog-details margin `}>
        {isPending && <div>Loading...</div>}
        {error && <div className="load-error">{error}</div>}
        {blog && (
          <section className={blog.className}>
            <div className="line-divider centered wide">
              <p className="diamond-text-box date">
                <span className="left"></span>
                <span className="content caps centered">{blog.date}</span>
                <span className="right"></span>
              </p>
            </div>
            <h1 className="centered post-title">{blog.title}</h1>
            <p className="body intro">{blog.intro}</p>
            <div className="share-parent">
              <FacebookShareButton
                url={currentPageUrl}
                hashtag="#panama"
                aria-label="facebook"
              >
                <FacebookIcon size={35} />
              </FacebookShareButton>
              <TwitterShareButton
                url={currentPageUrl}
                hashtags={["pickuppanama", "spanish", "language", "panama"]}
                title={"What languages are spoken in Panama?"}
                aria-label="twitter"
              >
                <TwitterIcon size={35} />
              </TwitterShareButton>
              <LinkedinShareButton
                url={currentPageUrl}
                title={"What languages are spoken in Panama?"}
                summary={
                  "Find out what languages are spoken in Panama and understand why it's important to learn Spanish."
                }
                aria-label="linkedin"
              >
                <LinkedinIcon size={35} />
              </LinkedinShareButton>
              <EmailShareButton
                url={currentPageUrl}
                subject={"What languages are spoken in Panama?"}
                body={`Find out what languages are spoken in Panama and understand why it's important to learn Spanish.`}
                aria-label="email"
                separator={" "}
              >
                <EmailIcon size={35} />
              </EmailShareButton>
            </div>
            <figure>
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/spanish/th-panama-products-320.webp 320w, /images/spanish/th-panama-products-480.webp 480w, /images/spanish/th-panama-products-640.webp 640w, /images/spanish/th-panama-products-768.webp 768w, /images/spanish/panama-products.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/spanish/th-panama-products-320.jpg 320w, /images/spanish/th-panama-products-480.jpg 480w, /images/spanish/th-panama-products-640.jpg 640w, /images/spanish/th-panama-products-768.jpg 768w, /images/spanish/panama-products.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  fetchpriority="high"
                  className="no-right-click"
                  src={blog.panProducts}
                  alt={blog.prodAltText}
                />
              </picture>
              <figcaption>{blog.prodCaption}</figcaption>
            </figure>
            <p>{blog.about15}</p>
            <p>{blog.althoughFlu}</p>
            <h2>{blog.whatRoleh2}</h2>
            <p>
              <span className="six-hundred">{blog.busEconSpan}</span>{" "}
              {blog.busEcon}
            </p>
            <p>
              <span className="six-hundred">{blog.tourismSpan}</span>{" "}
              {blog.tourism}
            </p>
            <p>
              <span className="six-hundred">{blog.eduSpan}</span> {blog.educat}
            </p>
            <figure className="school">
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/spanish/roberto-eisenmann-bilingual-school-320.webp 320w, /images/spanish/roberto-eisenmann-bilingual-school-480.webp 480w, /images/spanish/roberto-eisenmann-bilingual-school-640.webp 640w, /images/spanish/roberto-eisenmann-bilingual-school-768.webp 768w, /images/spanish/roberto-eisenmann-bilingual-school.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/spanish/roberto-eisenmann-bilingual-school-320.jpg 320w, /images/spanish/roberto-eisenmann-bilingual-school-480.jpg 480w, /images/spanish/roberto-eisenmann-bilingual-school-640.jpg 640w, /images/spanish/roberto-eisenmann-bilingual-school-768.jpg 768w, /images/spanish/roberto-eisenmann-bilingual-school.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.school}
                  alt={blog.schoolAltText}
                />
              </picture>
              <figcaption>{blog.schoolCaption}</figcaption>
            </figure>
            <p>
              <span className="six-hundred">{blog.irSpan}</span>{" "}
              {blog.internRelations}
            </p>
            <p>
              <span className="six-hundred">{blog.techSciSpan}</span>{" "}
              {blog.techSci}
            </p>
            <p>
              <span className="six-hundred">{blog.expatSpan}</span>{" "}
              {blog.expatComm}
            </p>
            <p>
              <span className="six-hundred">{blog.mediaSpan}</span>{" "}
              {blog.mediaEnt}
            </p>
            <h2>{blog.whyImpH2}</h2>
            <p>{blog.inRemote}</p>
            <p>{blog.learningSpanish}</p>
            <p>{blog.makingEffort}</p>
            <p>{blog.evenIf}</p>
            <p>{blog.nativeSpeakers}</p>
            <h2>{blog.whatAreSomeH2}</h2>
            <p>{blog.learnBasic}</p>
            <h3>{blog.greetingsH3}</h3>
            <ul>
              <li>{blog.liBD}</li>
              <li>{blog.liBT}</li>
              <li>{blog.liBN}</li>
              <li>{blog.liCE}</li>
              <li>{blog.liQT}</li>
              <li>{blog.liCV}</li>
              <li>{blog.MG}</li>
              <li>{blog.hastaL}</li>
              <li>{blog.hastP}</li>
              <li>{blog.nV}</li>
            </ul>
            <h3>{blog.courtesiesH3}</h3>
            <ul>
              <li>{blog.porFli}</li>
              <li>{blog.gracias}</li>
              <li>{blog.muchasG}</li>
              <li>{blog.deNada}</li>
              <li>{blog.conPer}</li>
              <li>{blog.disCulpa}</li>
              <li>{blog.loSiento}</li>
            </ul>
            <h3>{blog.politeReq}</h3>
            <ul>
              <li>{blog.podAy}</li>
              <li>{blog.podDec}</li>
              <li>{blog.meGusSab}</li>
              <li>{blog.serPos}</li>
              <li>{blog.meGusPed}</li>
              <li>{blog.podTraer}</li>
              <li>{blog.podAssist}</li>
              <li>{blog.necisAy}</li>
              <li>{blog.leImp}</li>
              <li>{blog.meGusPre}</li>
            </ul>
            <h3>{blog.basicQuesH3}</h3>
            <figure>
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/spanish/ordering-food-panama-320.webp 320w, /images/spanish/ordering-food-panama-480.webp 480w, /images/spanish/ordering-food-panama-640.webp 640w, /images/spanish/ordering-food-panama-768.webp 768w, /images/spanish/ordering-food-panama.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/spanish/ordering-food-panama-320.jpg 320w, /images/spanish/ordering-food-panama-480.jpg 480w, /images/spanish/ordering-food-panama-640.jpg 640w, /images/spanish/ordering-food-panama-768.jpg 768w, /images/spanish/ordering-food-panama.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.orderFood}
                  alt={blog.orderAltText}
                />
              </picture>
              <figcaption>{blog.orderCaption}</figcaption>
            </figure>
            <ul>
              <li>{blog.cuantoCue}</li>
              <li>{blog.estasFila}</li>
              <li>{blog.nosPuedeDar}</li>
              <li>{blog.tomareAg}</li>
              <li>{blog.deliciosa}</li>
              <li>{blog.laCarna}</li>
              <li>{blog.sinCeb}</li>
              <li>{blog.puedoCambi}</li>
              <li>{blog.sinSal}</li>
              <li>{blog.estoPlato}</li>
              <li>{blog.soyAler}</li>
            </ul>
            <h3>{blog.askingH3}</h3>
            <figure className="alto">
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/spanish/panama-stop-sign-320.webp 320w, /images/spanish/panama-stop-sign-480.webp 480w, /images/spanish/panama-stop-sign-640.webp 640w, /images/spanish/panama-stop-sign-768.webp 768w, /images/spanish/panama-stop-sign.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/spanish/panama-stop-sign-320.jpg 320w, /images/spanish/panama-stop-sign-480.jpg 480w, /images/spanish/panama-stop-sign-640.jpg 640w, /images/spanish/panama-stop-sign-768.jpg 768w, /images/spanish/panama-stop-sign.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.alto}
                  alt={blog.altoAltText}
                />
              </picture>
              <figcaption>{blog.altoCaption}</figcaption>
            </figure>
            <ul>
              <li>{blog.dondeEst}</li>
              <li>{blog.comoLlego}</li>
              <li>{blog.puedeInd}</li>
              <li>{blog.estoyYendo}</li>
              <li>{blog.hayAlgun}</li>
              <li>{blog.dondeQue}</li>
            </ul>
            <h3>{blog.givingDirectH3}</h3>
            <ul>
              <li>{blog.giraD}</li>
              <li>{blog.giraL}</li>
              <li>{blog.goStraight}</li>
              <li>{blog.estaEsqu}</li>
              <li>{blog.tomaLa}</li>
            </ul>
            <h3>{blog.erPhrasesH3}</h3>
            <ul>
              <li>{blog.ayudaLi}</li>
              <li>{blog.llamaAmb}</li>
              <li>{blog.llamaPol}</li>
              <li>{blog.aux}</li>
              <li>{blog.perdido}</li>
              <li>{blog.fuego}</li>
              <li>{blog.necInt}</li>
            </ul>
            <h2>{blog.panamaSpanishH2}</h2>
            <p>{blog.panamaSpanishIs}</p>
            <p>{blog.hasAVariety}</p>
            <p>{blog.dependingOn}</p>
            <p>{blog.whenCoronado}</p>
            <p>{blog.buenaBuena}</p>
            <p>{blog.thisIsKnown}</p>
            <h3>{blog.slangDielects}</h3>
            <p>{blog.hereAreSome}</p>
            <ul>
              <li>{blog.laChiva}</li>
              <li>{blog.chuzo}</li>
              <li>{blog.patacon}</li>
              <li>{blog.chamba}</li>
              <li>{blog.jama}</li>
              <li>{blog.plena}</li>
              <li>{blog.cubetazo}</li>
              <li>{blog.chevere}</li>
              <li>{blog.queXop}</li>
              <li>{blog.AquiTran}</li>
            </ul>
            <h2>{blog.whatOtherH2}</h2>
            <p>{blog.inAddition}</p>
            <figure>
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/spanish/indigenous-panamanians-320.webp 320w, /images/spanish/indigenous-panamanians-480.webp 480w, /images/spanish/indigenous-panamanians-640.webp 640w, /images/spanish/indigenous-panamanians-768.webp 768w, /images/spanish/indigenous-panamanians.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/spanish/indigenous-panamanians-320.jpg 320w, /images/spanish/indigenous-panamanians-480.jpg 480w, /images/spanish/indigenous-panamanians-640.jpg 640w, /images/spanish/indigenous-panamanians-768.jpg 768w, /images/spanish/indigenous-panamanians.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.indigenous}
                  alt={blog.indigenousAltText}
                />
              </picture>
              <figcaption>{blog.indigenousCaption}</figcaption>
            </figure>
            <h2>{blog.howCanLearnH2}</h2>
            <ul>
              <li>{blog.watchMov}</li>
              <li>{blog.useApps}</li>
              <li>{blog.findExchange}</li>
              <li>{blog.createFlash}</li>
              <li>{blog.readSpanish}</li>
              <li>{blog.pracitceDaily}</li>
            </ul>
            <h3>{blog.languageTransferH3}</h3>
            <p>
              {blog.languageTransfer}
              <span>
                {" "}
                <a
                  className="link"
                  target="_blank"
                  href="https://www.languagetransfer.org"
                  rel="noopener noreferrer"
                  aria-label="language trnasfer"
                >
                  {blog.langTrSpan}
                </a>
                {blog.period}
              </span>
            </p>
            <p>{blog.someonePanama}</p>
            <p>{blog.willBenefit}</p>
            <p>{blog.noMem}</p>
            <p>{blog.theLearner}</p>
            <p>{blog.itsAbout}</p>
            <p>{blog.moreImp}</p>
            <p>{blog.ifYouAre}</p>
            <p className="last-figure">{blog.overall}</p>

            <div className="button-parent">
              <button
                title="Things to do in El Cangrejo"
                onClick={goToElCangrejoStory}
              >
                &#60;
              </button>
              <button title="The culture of Panama" onClick={goToCulturalNorms}>
                &#62;
              </button>
            </div>
          </section>
        )}
      </main>
    );
  }
  if (document.title === "The culture of Panama") {
    return (
      <main id="main-element" className={`blog-details margin `}>
        {isPending && <div>Loading...</div>}
        {error && <div className="load-error">{error}</div>}
        {blog && (
          <section className={blog.className}>
            <div className="line-divider centered wide">
              <p className="diamond-text-box date">
                <span className="left"></span>
                <span className="content caps centered">{blog.date}</span>
                <span className="right"></span>
              </p>
            </div>
            <h1 className="centered post-title">{blog.title}</h1>
            <div className="body intro">
              <p>{blog.intro}</p>
              <p className="second-para">{blog.byFamiliz}</p>
            </div>
            <figure>
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/culturalNorms/casco-viejo-panama-320.webp 320w, /images/culturalNorms/casco-viejo-panama-480.webp 480w, /images/culturalNorms/casco-viejo-panama-640.webp 640w, /images/culturalNorms/casco-viejo-panama-768.webp 768w, /images/culturalNorms/casco-viejo-panama.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/culturalNorms/casco-viejo-panama-320.jpg 320w, /images/culturalNorms/casco-viejo-panama-480.jpg 480w, /images/culturalNorms/casco-viejo-panama-640.jpg 640w, /images/culturalNorms/casco-viejo-panama-768.jpg 768w, /images/culturalNorms/casco-viejo-panama.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  fetchpriority="high"
                  className="no-right-click"
                  src={blog.cascoOvImage}
                  alt={blog.cascoOvImageAltText}
                />
              </picture>
              <figcaption>{blog.cascoOvImageCaption}</figcaption>
            </figure>
            <div className="share-parent">
              <FacebookShareButton
                url={currentPageUrl}
                hashtag="#panama"
                aria-label="facebook"
              >
                <FacebookIcon size={35} />
              </FacebookShareButton>
              <TwitterShareButton
                url={currentPageUrl}
                hashtags={[
                  "pickuppanama",
                  "cultural norms",
                  "etiquette",
                  "panama",
                ]}
                title={"The culture of Panama"}
                aria-label="twitter"
              >
                <TwitterIcon size={35} />
              </TwitterShareButton>
              <LinkedinShareButton
                url={currentPageUrl}
                title={"The culture of Panama"}
                summary={
                  "Learn the culture of Panama, dress codes, and how to successfully navigate social interactions."
                }
                aria-label="linkedin"
              >
                <LinkedinIcon size={35} />
              </LinkedinShareButton>
              <EmailShareButton
                url={currentPageUrl}
                subject={"The culture of Panama"}
                body={`Learn the culture of Panama, dress codes, and how to successfully navigate social interactions.`}
                aria-label="email"
                separator={" "}
              >
                <EmailIcon size={35} />
              </EmailShareButton>
            </div>
            <h2>{blog.hereAre}</h2>
            <p>
              <span className="six-hundred">{blog.repectSpan}</span>{" "}
              {blog.panCult}
            </p>
            <p>
              <span className="six-hundred">{blog.frienSpan}</span>{" "}
              {blog.panamAre}
            </p>
            <p>
              <span className="six-hundred">{blog.greet}</span> {blog.itIsCusto}
              <strong>{blog.strongGreet}</strong>
              {blog.ifYouAre}
            </p>
            <p>{blog.whenMeeting}</p>
            <p>
              <span className="six-hundred">{blog.famBondsSpan}</span>{" "}
              {blog.panSoc}
            </p>
            <figure>
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/culturalNorms/casco-viejo-smiling-cops-320.webp 320w, /images/culturalNorms/casco-viejo-smiling-cops-480.webp 480w, /images/culturalNorms/casco-viejo-smiling-cops-640.webp 640w, /images/culturalNorms/casco-viejo-smiling-cops-768.webp 768w, /images/culturalNorms/casco-viejo-smiling-cops.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/culturalNorms/casco-viejo-smiling-cops-320.jpg 320w, /images/culturalNorms/casco-viejo-smiling-cops-480.jpg 480w, /images/culturalNorms/casco-viejo-smiling-cops-640.jpg 640w, /images/culturalNorms/casco-viejo-smiling-cops-768.jpg 768w, /images/culturalNorms/casco-viejo-smiling-cops.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.fam}
                  alt={blog.famAltText}
                />
              </picture>
              <figcaption>{blog.famCaption}</figcaption>
            </figure>
            <p>
              <span className="six-hundred">{blog.relInfluSpan}</span>{" "}
              {blog.mostPan}
            </p>
            <figure>
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/culturalNorms/casco-viejo-church-320.webp 320w, /images/culturalNorms/casco-viejo-church-480.webp 480w, /images/culturalNorms/casco-viejo-church-640.webp 640w, /images/culturalNorms/casco-viejo-church-768.webp 768w, /images/culturalNorms/casco-viejo-church.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/culturalNorms/casco-viejo-church-320.jpg 320w, /images/culturalNorms/casco-viejo-church-480.jpg 480w, /images/culturalNorms/casco-viejo-church-640.jpg 640w, /images/culturalNorms/casco-viejo-church-768.jpg 768w, /images/culturalNorms/casco-viejo-church.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.church}
                  alt={blog.churchAltText}
                />
              </picture>
              <figcaption>{blog.churchCaption}</figcaption>
            </figure>
            <p>
              <span className="six-hundred">{blog.paceSpan}</span>{" "}
              {blog.punctual}
            </p>
            <figure className="hat">
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/culturalNorms/panama-hat-320.webp 320w, /images/culturalNorms/panama-hat-480.webp 480w, /images/culturalNorms/panama-hat-640.webp 640w, /images/culturalNorms/panama-hat-768.webp 768w, /images/culturalNorms/panama-hat.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/culturalNorms/panama-hat-320.jpg 320w, /images/culturalNorms/panama-hat-480.jpg 480w, /images/culturalNorms/panama-hat-640.jpg 640w, /images/culturalNorms/panama-hat-768.jpg 768w, /images/culturalNorms/panama-hat.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.pace}
                  alt={blog.paceAltText}
                />
              </picture>
              <figcaption>{blog.paceCaption}</figcaption>
            </figure>
            <p>{blog.panamaOperates}</p>
            <p>
              <span className="six-hundred">{blog.personSpaceSpan}</span>{" "}
              {blog.panaVal}
            </p>
            <p>
              <span className="six-hundred">{blog.etiquetteSpan}</span>{" "}
              {blog.tableManners}
            </p>
            <p>
              <span className="six-hundred">{blog.useTitles}</span>{" "}
              {blog.usingFormal}
            </p>
            <p>
              <span className="six-hundred">{blog.communicationSpan}</span>{" "}
              {blog.inOrder}
            </p>
            <p>
              <span className="six-hundred">{blog.casConvSpan}</span>{" "}
              {blog.casConv}
            </p>
            <p>
              <span className="six-hundred">{blog.foodSharingSp}</span>{" "}
              {blog.sharingFood}
            </p>
            <p>
              <span className="six-hundred">{blog.celebrFestSP}</span>{" "}
              {blog.boasts}
            </p>
            <figure>
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/culturalNorms/casco-museum-320.webp 320w, /images/culturalNorms/casco-museum-480.webp 480w, /images/culturalNorms/casco-museum-640.webp 640w, /images/culturalNorms/casco-museum-768.webp 768w, /images/culturalNorms/casco-museum.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/culturalNorms/casco-museum-320.jpg 320w, /images/culturalNorms/casco-museum-480.jpg 480w, /images/culturalNorms/casco-museum-640.jpg 640w, /images/culturalNorms/casco-museum-768.jpg 768w, /images/culturalNorms/casco-museum.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.cascoImage}
                  alt={blog.cascoImageAltText}
                />
              </picture>
              <figcaption>{blog.cascoImageCaption}</figcaption>
            </figure>
            <h2>{blog.fauxPasH2}</h2>
            <p>
              <span className="six-hundred">{blog.discusSpan}</span>{" "}
              {blog.avoid}
            </p>
            <p>
              <span className="six-hundred">{blog.usnsolicitedSpan}</span>{" "}
              {blog.givingUnsol}
            </p>
            <p>
              <span className="six-hundred">{blog.wastingFoodSpan}</span>{" "}
              {blog.theCultur}
            </p>
            <p>
              <span className="six-hundred">{blog.speakingLoudSpan}</span>{" "}
              {blog.panaPlace}
            </p>
            <p>
              <span className="six-hundred">{blog.notOffeing}</span>{" "}
              {blog.givingHelp}
            </p>
            <p>
              <span className="six-hundred">{blog.ignoringGreetings}</span>{" "}
              {blog.greetingsAre}
            </p>
            <h2>{blog.doTipH2}</h2>
            <figure className="tip">
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/culturalNorms/tipping-in-panama-320.webp 320w, /images/culturalNorms/tipping-in-panama-480.webp 480w, /images/culturalNorms/tipping-in-panama-640.webp 640w, /images/culturalNorms/tipping-in-panama-768.webp 768w, /images/culturalNorms/tipping-in-panama.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/culturalNorms/tipping-in-panama-320.jpg 320w, /images/culturalNorms/tipping-in-panama-480.jpg 480w, /images/culturalNorms/tipping-in-panama-640.jpg 640w, /images/culturalNorms/tipping-in-panama-768.jpg 768w, /images/culturalNorms/tipping-in-panama.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.tipping}
                  alt={blog.tippingAltText}
                />
              </picture>
              <figcaption>{blog.tippingCaption}</figcaption>
            </figure>
            <p>{blog.whileTipp}</p>
            <p>
              <span className="six-hundred">{blog.restSpan}</span>{" "}
              {blog.itNormal}
            </p>
            <p>
              <span className="six-hundred">{blog.barsSpan}</span>{" "}
              {blog.tipAtBars}
            </p>
            <p>
              <span className="six-hundred">{blog.hotels}</span>{" "}
              {blog.tippingHotel}
            </p>
            <p>
              <span className="six-hundred">{blog.taxisSpan}</span>{" "}
              {blog.tippingTaxi}
            </p>
            <p>
              <span className="six-hundred">{blog.tourGuidesSpan}</span>{" "}
              {blog.ifYouGo}
            </p>
            <p>
              <span className="six-hundred">{blog.otherServicesSpan}</span>{" "}
              {blog.inGeneral}
            </p>
            <h2>{blog.dressCodeH2}</h2>
            <p>{blog.dressCodeVary}</p>
            <p>
              <span className="six-hundred">{blog.casualSpan}</span>{" "}
              {blog.menDress}
            </p>
            <p>{blog.duringFormal}</p>
            <p>{blog.forWomen}</p>
            <p>
              <span className="six-hundred">{blog.beachWearSpan}</span>{" "}
              {blog.ifVisiting}
            </p>
            <figure className="coronado-beach">
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/culturalNorms/coronado-beach-panama-320.webp 320w, /images/culturalNorms/coronado-beach-panama-480.webp 480w, /images/culturalNorms/coronado-beach-panama-640.webp 640w, /images/culturalNorms/coronado-beach-panama-768.webp 768w, /images/culturalNorms/coronado-beach-panama.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/culturalNorms/coronado-beach-panama-320.jpg 320w, /images/culturalNorms/coronado-beach-panama-480.jpg 480w, /images/culturalNorms/coronado-beach-panama-640.jpg 640w, /images/culturalNorms/coronado-beach-panama-768.jpg 768w, /images/culturalNorms/coronado-beach-panama.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.corBeach}
                  alt={blog.corBeachAltText}
                />
              </picture>
              <figcaption>{blog.corBeachCaption}</figcaption>
            </figure>
            <p>{blog.womenWear}</p>
            <p>{blog.sunscreen}</p>
            <h3>{blog.dressCodeH3}</h3>
            <p>{blog.disregarding}</p>
            <ul>
              <li>{blog.menMustLi}</li>
              <li>{blog.womenShould}</li>
              <li>{blog.noBare}</li>
              <li>{blog.noT}</li>
              <li>{blog.shoesMust}</li>
            </ul>
            <h2>{blog.miscilLawsH3}</h2>
            <ul>
              <li>{blog.drinkingLi}</li>
              <li>{blog.beachAttire}</li>
              <li>{blog.shirts}</li>
              <li>{blog.driving}</li>
            </ul>
            <p>{blog.understanding}</p>
            <figure className="last-figure exercise">
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/culturalNorms/panama-exercise-class-320.webp 320w, /images/culturalNorms/panama-exercise-class-480.webp 480w, /images/culturalNorms/panama-exercise-class-640.webp 640w, /images/culturalNorms/panama-exercise-class-768.webp 768w, /images/culturalNorms/panama-exercise-class.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/culturalNorms/panama-exercise-class-320.jpg 320w, /images/culturalNorms/panama-exercise-class-480.jpg 480w, /images/culturalNorms/panama-exercise-class-640.jpg 640w, /images/culturalNorms/panama-exercise-class-768.jpg 768w, /images/culturalNorms/panama-exercise-class.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.exercise}
                  alt={blog.exerciseAltText}
                />
              </picture>
              <figcaption>{blog.exerciseCaption}</figcaption>
            </figure>
            <div className="button-parent">
              <button
                title="What languages are spoken in Panama?"
                onClick={goToSpanishStory}
              >
                &#60;
              </button>
              <button title="Traditional food in Panama" onClick={goToFood}>
                &#62;
              </button>
            </div>
          </section>
        )}
      </main>
    );
  }
  if (document.title === "Traditional food in Panama") {
    return (
      <main id="main-element" className={`blog-details margin `}>
        {isPending && <div>Loading...</div>}
        {error && <div className="load-error">{error}</div>}
        {blog && (
          <section className={blog.className}>
            <div className="line-divider centered wide">
              <p className="diamond-text-box date">
                <span className="left"></span>
                <span className="content caps centered">{blog.date}</span>
                <span className="right"></span>
              </p>
            </div>
            <h1 className="centered post-title">{blog.title}</h1>
            <div className="body intro">
              <p>{blog.intro}</p>
              <p className="second-para">{blog.thisArticle}</p>
            </div>
            <figure>
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/food/panama-fish-market-320.webp 320w, /images/food/panama-fish-market-480.webp 480w, /images/food/panama-fish-market-640.webp 640w, /images/food/panama-fish-market-768.webp 768w, /images/food/panama-fish-market.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/food/panama-fish-market-320.jpg 320w, /images/food/panama-fish-market-480.jpg 480w, /images/food/panama-fish-market-640.jpg 640w, /images/food/panama-fish-market-768.jpg 768w, /images/food/panama-fish-market.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  fetchpriority="high"
                  className="no-right-click"
                  src={blog.fish}
                  alt={blog.fishAltText}
                />
              </picture>
              <figcaption>{blog.fishCaption}</figcaption>
            </figure>
            <div className="share-parent">
              <FacebookShareButton
                url={currentPageUrl}
                hashtag="#panama"
                aria-label="facebook"
              >
                <FacebookIcon size={35} />
              </FacebookShareButton>
              <TwitterShareButton
                url={currentPageUrl}
                hashtags={["pickuppanama", "food", "cuisine", "panama"]}
                title={"Traditional food in Panama"}
                aria-label="twitter"
              >
                <TwitterIcon size={35} />
              </TwitterShareButton>
              <LinkedinShareButton
                url={currentPageUrl}
                title={"Traditional food in Panama"}
                summary={
                  "Discover traditional Panamanian food dishes, explore the best food in Panama City and find places to eat on a budget."
                }
                aria-label="linkedin"
              >
                <LinkedinIcon size={35} />
              </LinkedinShareButton>
              <EmailShareButton
                url={currentPageUrl}
                subject={"Traditional food in Panama"}
                body={`Discover traditional Panamanian food dishes, explore the best food in Panama City and find places to eat on a budget.`}
                aria-label="email"
                separator={" "}
              >
                <EmailIcon size={35} />
              </EmailShareButton>
            </div>
            <h2>{blog.seafoodH2}</h2>
            <p>{blog.seafoodPara}</p>
            <p>{blog.fishAnd}</p>
            <figure>
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/food/seafood-320.webp 320w, /images/food/seafood-480.webp 480w, /images/food/seafood-640.webp 640w, /images/food/seafood-768.webp 768w, /images/food/seafood.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/food/seafood-320.jpg 320w, /images/food/seafood-480.jpg 480w, /images/food/seafood-640.jpg 640w, /images/food/seafood-768.jpg 768w, /images/food/seafood.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.seafood}
                  alt={blog.seafoodAltText}
                />
              </picture>
              <figcaption>{blog.seafoodCaption}</figcaption>
            </figure>
            <figure className="lsalad">
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/food/lobster-salad-panama-320.webp 320w, /images/food/lobster-salad-panama-480.webp 480w, /images/food/lobster-salad-panama-640.webp 640w, /images/food/lobster-salad-panama-768.webp 768w, /images/food/lobster-salad-panama.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/food/lobster-salad-panama-320.jpg 320w, /images/food/lobster-salad-panama-480.jpg 480w, /images/food/lobster-salad-panama-640.jpg 640w, /images/food/lobster-salad-panama-768.jpg 768w, /images/food/lobster-salad-panama.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.lSalads}
                  alt={blog.lSaladsAltText}
                />
              </picture>
              <figcaption>{blog.lSaladsCaption}</figcaption>
            </figure>
            <h2>{blog.panFoodH2}</h2>
            <p>
              <span className="six-hundred">{blog.sanocSpan}</span>{" "}
              {blog.thisPanNational}
            </p>
            <p>
              <span className="six-hundred">{blog.arrozConSpan}</span>{" "}
              {blog.aTradition}
            </p>
            <figure className="delicias">
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/food/delicias-margot-320.webp 320w, /images/food/delicias-margot-480.webp 480w, /images/food/delicias-margot-640.webp 640w, /images/food/delicias-margot-768.webp 768w, /images/food/delicias-margot.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/food/delicias-margot-320.jpg 320w, /images/food/delicias-margot-480.jpg 480w, /images/food/delicias-margot-640.jpg 640w, /images/food/delicias-margot-768.jpg 768w, /images/food/delicias-margot.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.delMar}
                  alt={blog.delMarAltText}
                />
              </picture>
              <figcaption>{blog.delMarCaption}</figcaption>
            </figure>
            <p>
              <span className="six-hundred">{blog.cevSpan}</span>{" "}
              {blog.panaVers}
            </p>
            <figure className="ceviche">
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/food/ceviche-panama-320.webp 320w, /images/food/ceviche-panama-480.webp 480w, /images/food/ceviche-panama-640.webp 640w, /images/food/ceviche-panama-768.webp 768w, /images/food/ceviche-panama.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/food/ceviche-panama-320.jpg 320w, /images/food/ceviche-panama-480.jpg 480w, /images/food/ceviche-panama-640.jpg 640w, /images/food/ceviche-panama-768.jpg 768w, /images/food/ceviche-panama.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.ceviche}
                  alt={blog.cevicheAltText}
                />
              </picture>
              <figcaption>{blog.cevicheCaption}</figcaption>
            </figure>
            <p>
              <span className="six-hundred">{blog.pataSpan}</span>{" "}
              {blog.friedGreen}
            </p>
            <p>
              <span className="six-hundred">{blog.carimSpan}</span>{" "}
              {blog.theseAre}
            </p>
            <p>
              <span className="six-hundred">{blog.ropaViSpan}</span>{" "}
              {blog.aDish}
            </p>
            <p>
              <span className="six-hundred">{blog.tamalesSp}</span>{" "}
              {blog.panTam}
            </p>
            <p>
              <span className="six-hundred">{blog.bollosSpan}</span>{" "}
              {blog.cornDough}
            </p>
            <p>
              <span className="six-hundred">{blog.yucaSpan}</span> {blog.yuca}
            </p>
            <p>
              <span className="six-hundred">{blog.chichemeSpan}</span>{" "}
              {blog.aSweet}
            </p>
            <p>
              <span className="six-hundred">{blog.secoSpan}</span>{" "}
              {blog.aChicken}
            </p>
            <p>
              <span className="six-hundred">{blog.ensaladSpan}</span>{" "}
              {blog.aFestive}
            </p>
            <h2>{blog.tropFruitsH2}</h2>
            <p className="six-hundred">{blog.panamaClimate}</p>
            <p>
              <span className="six-hundred">{blog.guavaSpan}</span>{" "}
              {blog.thisFruit}
            </p>
            <p>
              <span className="six-hundred">{blog.pinaSpan}</span>{" "}
              {blog.PanamaProd}
            </p>
            <p>
              <span className="six-hundred">{blog.papaySpan}</span>{" "}
              {blog.papThrive}
            </p>
            <p>
              <span className="six-hundred">{blog.mangoSpan}</span>{" "}
              {blog.panamOffers}
            </p>
            <p>
              <span className="six-hundred">{blog.bananSp}</span> {blog.banPlan}
            </p>
            <p>
              <span className="six-hundred">{blog.cocoSp}</span> {blog.cocoGrow}
            </p>
            <figure className="coco">
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/food/coconut-panama-320.webp 320w, /images/food/coconut-panama-480.webp 480w, /images/food/coconut-panama-640.webp 640w, /images/food/coconut-panama-768.webp 768w, /images/food/coconut-panama.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/food/coconut-panama-320.jpg 320w, /images/food/coconut-panama-480.jpg 480w, /images/food/coconut-panama-640.jpg 640w, /images/food/coconut-panama-768.jpg 768w, /images/food/coconut-panama.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.coco}
                  alt={blog.cocoAltText}
                />
              </picture>
              <figcaption>{blog.cocoCaption}</figcaption>
            </figure>
            <p>
              <span className="six-hundred">{blog.starSpan}</span> {blog.starFr}
            </p>
            <p>
              <span className="six-hundred">{blog.passionSpan}</span>{" "}
              {blog.thisTang}
            </p>
            <p>
              <span className="six-hundred">{blog.sourSopSp}</span>{" "}
              {blog.sourSop}
            </p>
            <p>
              <span className="six-hundred">{blog.cashfrSp}</span>{" "}
              {blog.cashNuts}
            </p>
            <h2>{blog.desertsH2}</h2>
            <figure className="dessert">
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/food/banana-split-panama-320.webp 320w, /images/food/banana-split-panama-480.webp 480w, /images/food/banana-split-panama-640.webp 640w, /images/food/banana-split-panama-768.webp 768w, /images/food/banana-split-panama.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/food/banana-split-panama-320.jpg 320w, /images/food/banana-split-panama-480.jpg 480w, /images/food/banana-split-panama-640.jpg 640w, /images/food/banana-split-panama-768.jpg 768w, /images/food/banana-split-panama.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.dessert}
                  alt={blog.dessertAltText}
                />
              </picture>
              <figcaption>{blog.dessertCaption}</figcaption>
            </figure>
            <p className="six-hundred">{blog.enjoySome}</p>

            <p>
              <span className="six-hundred">{blog.tresLechSp}</span>{" "}
              {blog.thisIs}
            </p>
            <p>
              <span className="six-hundred">{blog.dulceTamSp}</span>{" "}
              {blog.tamarindCan}
            </p>
            <p>
              <span className="six-hundred">{blog.hojaSp}</span>{" "}
              {blog.theseArePan}
            </p>
            <p>
              <span className="six-hundred">{blog.arrozConSp}</span>{" "}
              {blog.thisIsA}
            </p>
            <p>
              <span className="six-hundred">{blog.cocadesSp}</span>{" "}
              {blog.theseAreCo}
            </p>
            <p>
              <span className="six-hundred">{blog.chichSp}</span> {blog.chichIs}
            </p>
            <h2>{blog.panamaDrinksH2}</h2>
            <figure className="pina">
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/food/chicha-de-pina-320.webp 320w, /images/food/chicha-de-pina-480.webp 480w, /images/food/chicha-de-pina-640.webp 640w, /images/food/chicha-de-pina-768.webp 768w, /images/food/chicha-de-pina.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/food/chicha-de-pina-320.jpg 320w, /images/food/chicha-de-pina-480.jpg 480w, /images/food/chicha-de-pina-640.jpg 640w, /images/food/chicha-de-pina-768.jpg 768w, /images/food/chicha-de-pina.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.pina}
                  alt={blog.pinaAltText}
                />
              </picture>
              <figcaption>{blog.pinaCaption}</figcaption>
            </figure>
            <p>
              <span>{blog.theseDrinks}</span>
              <span>
                {" "}
                <a
                  className="link"
                  target="_self"
                  href="https://pickuppanama.com/panama-food-recipes"
                  rel="noopener noreferrer"
                  aria-label="what languages are spoken in panama"
                >
                  {blog.recipeSpan}
                </a>
                <span>{blog.andLocal}</span>
              </span>
            </p>
            <p>
              <span className="six-hundred">{blog.secoHerranoSp}</span>{" "}
              {blog.thisIsPan}
            </p>
            <p>
              <span className="six-hundred">{blog.chichaSpan}</span>{" "}
              {blog.riceBased}
            </p>
            <p>
              <span className="six-hundred">{blog.ronPSpan}</span>{" "}
              {blog.ronPonche}
            </p>
            <p>
              <span className="six-hundred">{blog.refrescNat}</span>{" "}
              {blog.panamaIs}
            </p>
            <p>
              <span className="six-hundred">{blog.cafeConSpan}</span>{" "}
              {blog.coffeeIs}
            </p>
            <p>
              <span className="six-hundred">{blog.pipaFriSp}</span> {blog.pipa}
            </p>
            <h2>{blog.foodHealthH2}</h2>
            <p>{blog.foodHeealthPara}</p>
            <p>{blog.foodEstabl}</p>
            <p>{blog.someAre}</p>
            <p>
              <span className="six-hundred">{blog.ministrySpan}</span>{" "}
              {blog.theMin}
            </p>
            <p>
              <span className="six-hundred">{blog.panFoodSafeSp}</span>{" "}
              {blog.aupsa}
            </p>
            <p>
              <span className="six-hundred">{blog.nationalFoodSp}</span>{" "}
              {blog.senIs}
            </p>
            <p>
              <span className="six-hundred">{blog.panInSp}</span> {blog.idiap}
            </p>
            <p>
              <span className="six-hundred">{blog.foodAn}</span> {blog.panaFood}
            </p>
            <h2>{blog.americanFood}</h2>
            <figure className="burger">
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/food/panama-hamburger-320.webp 320w, /images/food/panama-hamburger-480.webp 480w, /images/food/panama-hamburger-640.webp 640w, /images/food/panama-hamburger-768.webp 768w, /images/food/panama-hamburger.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/food/panama-hamburger-320.jpg 320w, /images/food/panama-hamburger-480.jpg 480w, /images/food/panama-hamburger-640.jpg 640w, /images/food/panama-hamburger-768.jpg 768w, /images/food/panama-hamburger.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.burger}
                  alt={blog.burgerAltText}
                />
              </picture>
              <figcaption>{blog.burgerCaption}</figcaption>
            </figure>
            <p>{blog.yes}</p>
            <p>{blog.someOf}</p>
            <p>
              <span className="six-hundred">{blog.fastFoodSp}</span>{" "}
              {blog.majorFast}
            </p>
            <p>
              <span className="six-hundred">{blog.casualDining}</span>{" "}
              {blog.thereAreCas}
            </p>
            <p>
              <span className="six-hundred">{blog.steakHouse}</span>{" "}
              {blog.amerStyle}
            </p>
            <p>
              <span className="six-hundred">{blog.intChains}</span>{" "}
              {blog.panaCity}
            </p>
            <p>
              <span className="six-hundred">{blog.sportsBars}</span>{" "}
              {blog.sports}
            </p>
            <h2>{blog.howEatH2}</h2>
            <h3>{blog.fondaH3}</h3>
            <figure className="highway-fonda">
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/food/highway-fonda-panama-320.webp 320w, /images/food/highway-fonda-panama-480.webp 480w, /images/food/highway-fonda-panama-640.webp 640w, /images/food/highway-fonda-panama-768.webp 768w, /images/food/highway-fonda-panama.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/food/highway-fonda-panama-320.jpg 320w, /images/food/highway-fonda-panama-480.jpg 480w, /images/food/highway-fonda-panama-640.jpg 640w, /images/food/highway-fonda-panama-768.jpg 768w, /images/food/highway-fonda-panama.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.hiway}
                  alt={blog.hiwayAltText}
                />
              </picture>
              <figcaption>{blog.hiwayCaption}</figcaption>
            </figure>
            <p>{blog.fondaRefers}</p>
            <figure className="colon-fonda">
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/food/panama-roadside-fonda-320.webp 320w, /images/food/panama-roadside-fonda-480.webp 480w, /images/food/panama-roadside-fonda-640.webp 640w, /images/food/panama-roadside-fonda-768.webp 768w, /images/food/panama-roadside-fonda.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/food/panama-roadside-fonda-320.jpg 320w, /images/food/panama-roadside-fonda-480.jpg 480w, /images/food/panama-roadside-fonda-640.jpg 640w, /images/food/panama-roadside-fonda-768.jpg 768w, /images/food/panama-roadside-fonda.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.roadSide}
                  alt={blog.roadSideAltText}
                />
              </picture>
              <figcaption>{blog.roadSideCaption}</figcaption>
            </figure>
            <p>{blog.theseEst}</p>

            <p>{blog.theChoices}</p>
            <figure className="take-out">
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/food/panama-fonda-meal-320.webp 320w, /images/food/panama-fonda-meal-480.webp 480w, /images/food/panama-fonda-meal-640.webp 640w, /images/food/panama-fonda-meal-768.webp 768w, /images/food/panama-fonda-meal.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/food/panama-fonda-meal-320.jpg 320w, /images/food/panama-fonda-meal-480.jpg 480w, /images/food/panama-fonda-meal-640.jpg 640w, /images/food/panama-fonda-meal-768.jpg 768w, /images/food/panama-fonda-meal.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.takeOut}
                  alt={blog.takeOutAltText}
                />
              </picture>
              <figcaption>{blog.takeOutCaption}</figcaption>
            </figure>
            <p>{blog.thisIsAgreat}</p>
            <p>{blog.theMenu}</p>
            <p>{blog.atmosphere}</p>
            <figure className="jardin">
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/food/jardin-panama-320.webp 320w, /images/food/jardin-panama-480.webp 480w, /images/food/jardin-panama-640.webp 640w, /images/food/jardin-panama-768.webp 768w, /images/food/jardin-panama.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/food/jardin-panama-320.jpg 320w, /images/food/jardin-panama-480.jpg 480w, /images/food/jardin-panama-640.jpg 640w, /images/food/jardin-panama-768.jpg 768w, /images/food/jardin-panama.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.jardin}
                  alt={blog.jardinAltText}
                />
              </picture>
              <figcaption>{blog.jardinCaption}</figcaption>
            </figure>
            <p>{blog.importRole}</p>
            <h2>{blog.otherTips}</h2>
            <h3>{blog.streeVenH2}</h3>
            <figure>
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/food/panama-street-vendor-320.webp 320w, /images/food/panama-street-vendor-480.webp 480w, /images/food/panama-street-vendor-640.webp 640w, /images/food/panama-street-vendor-768.webp 768w, /images/food/panama-street-vendor.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/food/panama-street-vendor-320.jpg 320w, /images/food/panama-street-vendor-480.jpg 480w, /images/food/panama-street-vendor-640.jpg 640w, /images/food/panama-street-vendor-768.jpg 768w, /images/food/panama-street-vendor.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.vendor}
                  alt={blog.vendorAltText}
                />
              </picture>
              <figcaption>{blog.vendorCaption}</figcaption>
            </figure>
            <p>{blog.streeFood}</p>
            <p>{blog.theseVen}</p>
            <figure>
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/food/food-stand-panama-320.webp 320w, /images/food/food-stand-panama-480.webp 480w, /images/food/food-stand-panama-640.webp 640w, /images/food/food-stand-panama-768.webp 768w, /images/food/food-stand-panama.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/food/food-stand-panama-320.jpg 320w, /images/food/food-stand-panama-480.jpg 480w, /images/food/food-stand-panama-640.jpg 640w, /images/food/food-stand-panama-768.jpg 768w, /images/food/food-stand-panama.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.foodStand}
                  alt={blog.foodStandAltText}
                />
              </picture>
              <figcaption>{blog.foodStandCaption}</figcaption>
            </figure>
            <figure>
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/food/panama-fast-food-320.webp 320w, /images/food/panama-fast-food-480.webp 480w, /images/food/panama-fast-food-640.webp 640w, /images/food/panama-fast-food-768.webp 768w, /images/food/panama-fast-food.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/food/panama-fast-food-320.jpg 320w, /images/food/panama-fast-food-480.jpg 480w, /images/food/panama-fast-food-640.jpg 640w, /images/food/panama-fast-food-768.jpg 768w, /images/food/panama-fast-food.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.fastFood}
                  alt={blog.fastFoodAltText}
                />
              </picture>
              <figcaption>{blog.fastFoodCaption}</figcaption>
            </figure>
            <h4>{blog.popularInc}</h4>
            <p>
              <span className="six-hundred">{blog.empSpan}</span>{" "}
              {blog.theseAreSav}
            </p>
            <p>
              <span className="six-hundred">{blog.carimanSpan}</span>{" "}
              {blog.similarTo}
            </p>
            <p>
              <span className="six-hundred">{blog.fruitCupSpan}</span>{" "}
              {blog.freshTropical}
            </p>
            <p>
              <span className="six-hundred">{blog.chichSpan}</span>{" "}
              {blog.theseAreCrisp}
            </p>
            <p>
              <span className="six-hundred">{blog.raspeoSpan}</span>{" "}
              {blog.raspOr}
            </p>
            <p>
              <span className="six-hundred">{blog.plantSp}</span>{" "}
              {blog.plantAre}
            </p>
            <h3>{blog.menuDel}</h3>
            <p>{blog.manyRest}</p>
            <h3>{blog.localMarkets}</h3>
            <p>{blog.visitLocal}</p>
            <h3>{blog.cook}</h3>
            <figure className="lobster">
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/food/lobster-dinner-panama-320.webp 320w, /images/food/lobster-dinner-panama-480.webp 480w, /images/food/lobster-dinner-panama-640.webp 640w, /images/food/lobster-dinner-panama-768.webp 768w, /images/food/lobster-dinner-panama.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/food/lobster-dinner-panama-320.jpg 320w, /images/food/lobster-dinner-panama-480.jpg 480w, /images/food/lobster-dinner-panama-640.jpg 640w, /images/food/lobster-dinner-panama-768.jpg 768w, /images/food/lobster-dinner-panama.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.cookYour}
                  alt={blog.cookYourAltText}
                />
              </picture>
              <figcaption>{blog.cookYourCaption}</figcaption>
            </figure>
            <p>{blog.ifYouHave}</p>
            <h3>{blog.shareH3}</h3>
            <p>{blog.shareMeals}</p>
            <h3>{blog.stayPlacesH3}</h3>
            <p>{blog.someHotels}</p>
            <figure className="coronado-breakfast">
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/food/coronado-panama-breakfast-320.webp 320w, /images/food/coronado-panama-breakfast-480.webp 480w, /images/food/coronado-panama-breakfast-640.webp 640w, /images/food/coronado-panama-breakfast-768.webp 768w, /images/food/coronado-panama-breakfast.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/food/coronado-panama-breakfast-320.jpg 320w, /images/food/coronado-panama-breakfast-480.jpg 480w, /images/food/coronado-panama-breakfast-640.jpg 640w, /images/food/coronado-panama-breakfast-768.jpg 768w, /images/food/coronado-panama-breakfast.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.coronadoBreak}
                  alt={blog.coronadoBreakAltText}
                />
              </picture>
              <figcaption>{blog.coronadoBreakCaption}</figcaption>
            </figure>

            <h3>{blog.avoidH3}</h3>
            <p>{blog.avoidTourist}</p>
            <figure className="portobelo">
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/food/portobelo-fonda-320.webp 320w, /images/food/portobelo-fonda-480.webp 480w, /images/food/portobelo-fonda-640.webp 640w, /images/food/portobelo-fonda-768.webp 768w, /images/food/portobelo-fonda.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/food/portobelo-fonda-320.jpg 320w, /images/food/portobelo-fonda-480.jpg 480w, /images/food/portobelo-fonda-640.jpg 640w, /images/food/portobelo-fonda-768.jpg 768w, /images/food/portobelo-fonda.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.portobelo}
                  alt={blog.portobeloAltText}
                />
              </picture>
              <figcaption>{blog.portobeloCaption}</figcaption>
            </figure>
            <h3>{blog.byob}</h3>
            <p>{blog.someAllow}</p>
            <h2>{blog.panamaRestOptions}</h2>
            <h3>{blog.veganR}</h3>
            <p>
              <span className="six-hundred">{blog.veganOSpan}</span>{" "}
              {blog.veganO}
            </p>
            <p>
              <span className="six-hundred">{blog.vegSuSpan}</span>{" "}
              {blog.allDishes}
            </p>
            <p>
              <span className="six-hundred">{blog.veganShackSpan}</span>{" "}
              {blog.fullyVeg}
            </p>
            <p>
              <span className="six-hundred">{blog.placeSpan}</span>{" "}
              {blog.veganCafe}
            </p>
            <p>
              <span className="six-hundred">{blog.ElJardinSpan}</span>{" "}
              {blog.elJardin}
            </p>
            <p>
              <span className="six-hundred">{blog.natSpiceSpan}</span>{" "}
              {blog.natSpice}
            </p>
            <p>
              <span className="six-hundred">{blog.lovingSpan}</span> {blog.luv}
            </p>
            <h3>{blog.tradR}</h3>
            <figure className="el-trap-food">
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/elCangrejo/el-trapiche-meal-320.webp 320w, /images/elCangrejo/el-trapiche-meal-480.webp 480w, /images/elCangrejo/el-trapiche-meal-640.webp 640w, /images/elCangrejo/el-trapiche-meal-768.webp 768w, /images/elCangrejo/el-trapiche-meal.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/elCangrejo/el-trapiche-meal-320.jpg 320w, /images/elCangrejo/el-trapiche-meal-480.jpg 480w, /images/elCangrejo/el-trapiche-meal-640.jpg 640w, /images/elCangrejo/el-trapiche-meal-768.jpg 768w, /images/elCangrejo/el-trapiche-meal.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src="/images/elCangrejo/el-trapiche-meal.jpg"
                  alt="shish kabob"
                />
              </picture>
              <figcaption>Panamanian shish kabob at El Trapiche</figcaption>
            </figure>
            <p>
              <span className="six-hundred">{blog.elTrapSp}</span> {blog.ElTr}
            </p>
            <p>
              <span className="six-hundred">{blog.elMuSp}</span> {blog.elMundo}
            </p>
            <p>
              <span className="six-hundred">{blog.restAngSp}</span>{" "}
              {blog.restAng}
            </p>
            <p>
              <span className="six-hundred">{blog.cafeCoSp}</span>{" "}
              {blog.cafeCoCa}
            </p>
            <p>
              <span className="six-hundred">{blog.fondaLoSp}</span>{" "}
              {blog.fondaLo}
            </p>

            <figure className="margot-front">
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/food/delicias-margot-front-320.webp 320w, /images/food/delicias-margot-front-480.webp 480w, /images/food/delicias-margot-front-640.webp 640w, /images/food/delicias-margot-front-768.webp 768w, /images/food/delicias-margot-front.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/food/delicias-margot-front-320.jpg 320w, /images/food/delicias-margot-front-480.jpg 480w, /images/food/delicias-margot-front-640.jpg 640w, /images/food/delicias-margot-front-768.jpg 768w, /images/food/delicias-margot-front.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.margF}
                  alt={blog.margFAltText}
                />
              </picture>
              <figcaption>{blog.margFCaption}</figcaption>
            </figure>
            <p className="intro no-margin-top last-figure conclusion">
              {blog.final}
            </p>

            <div className="button-parent">
              <button title="The culture of Panama" onClick={goToCulturalNorms}>
                &#60;
              </button>
              <button
                title="Using WhatsApp instead of your cell phone service in Panama"
                onClick={goToWhatsAppStory}
              >
                &#62;
              </button>
            </div>
          </section>
        )}
      </main>
    );
  }
  if (
    document.title ===
    "Using WhatsApp instead of your cell phone service in Panama"
  ) {
    return (
      <main id="main-element" className={`blog-details margin `}>
        {isPending && <div>Loading...</div>}
        {error && <div className="load-error">{error}</div>}
        {blog && (
          <section className={blog.className}>
            <div className="line-divider centered wide">
              <p className="diamond-text-box date">
                <span className="left"></span>
                <span className="content caps centered">{blog.date}</span>
                <span className="right"></span>
              </p>
            </div>
            <h1 className="centered post-title">{blog.title}</h1>
            <p className="body intro">{blog.intro}</p>
            <p>{blog.asLong}</p>
            <p>{blog.bothParties}</p>
            <p>{blog.thisArticle}</p>
            <div className="share-parent">
              <FacebookShareButton
                url={currentPageUrl}
                hashtag="#panama"
                aria-label="facebook"
              >
                <FacebookIcon size={35} />
              </FacebookShareButton>
              <TwitterShareButton
                url={currentPageUrl}
                hashtags={["pickuppanama", "whatsapp", "panama cell service"]}
                title={
                  "Using WhatsApp instead of your cell phone service in Panama"
                }
                aria-label="twitter"
              >
                <TwitterIcon size={35} />
              </TwitterShareButton>
              <LinkedinShareButton
                url={currentPageUrl}
                title={
                  "Using WhatsApp instead of your cell phone service in Panama"
                }
                summary={
                  "Save money on cell phone service in Panama by using WhatsApp and a Panama data plan and SIM card."
                }
                aria-label="linkedin"
              >
                <LinkedinIcon size={35} />
              </LinkedinShareButton>
              <EmailShareButton
                url={currentPageUrl}
                subject={
                  "Using WhatsApp instead of your cell phone service in Panama"
                }
                body={
                  "Save money on cell phone service in Panama by using WhatsApp and a Panama data plan and SIM card."
                }
                aria-label="email"
                separator={" "}
              >
                <EmailIcon size={35} />
              </EmailShareButton>
            </div>
            <h2>{blog.whyUseH2}</h2>
            <p>{blog.whatsApUses}</p>
            <p>{blog.whatsAppOffers}</p>
            <p>{blog.inAddition}</p>
            <p>{blog.inPanama}</p>
            <h2>{blog.setUpH2}</h2>
            <p>
              <span className="six-hundred">{blog.downloadSpan}</span>{" "}
              {blog.downloadThe}
            </p>
            <p>
              <span className="six-hundred">{blog.verifySpan}</span>{" "}
              {blog.whenYouFirst}
            </p>
            <p>
              <span className="six-hundred">{blog.setUpYour}</span>{" "}
              {blog.afterVerify}
            </p>
            <p>
              <span className="six-hundred">{blog.addContacts}</span>{" "}
              {blog.whatsAppSyncs}
            </p>
            <p>
              <span className="six-hundred">{blog.startMess}</span>{" "}
              {blog.onceYou}
            </p>
            <p>
              <span className="six-hundred">{blog.makeCalls}</span>{" "}
              {blog.youCanAlso}
            </p>
            <p>
              <span className="six-hundred">{blog.exploreAdd}</span>{" "}
              {blog.whatsAppHas}
            </p>
            <h2>{blog.whatsAppPhoneNoH2}</h2>
            <p>{blog.whatsAppNumbers}</p>
            <h3>{blog.formatH3}</h3>
            <p>{blog.countryCode}</p>
            <h3>{blog.whatsAppNoH3}</h3>

            <ul>
              <li>{blog.fiveOSeven}</li>
              <li>{blog.fiveOSevenIs}</li>
              <li>{blog.theXX}</li>
              <li>{blog.cellPhone}</li>
            </ul>
            <h3>{blog.aphoneNumberH3}</h3>
            <ul>
              <li>{blog.usPhoneNo}</li>
            </ul>
            <h2>{blog.checkH2}</h2>
            <p>
              <strong>{blog.yourPhoneMust}</strong>
            </p>
            <ul>
              <li>{blog.calling}</li>
              <li>{blog.useAn}</li>
              <li>{blog.ifYour}</li>
            </ul>
            <h2>{blog.offLineMaps}</h2>
            <p>{blog.beforeTraveling}</p>
            <h2>{blog.whatSimH2}</h2>
            <figure className="sim-card-front">
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/simCard/mas-movil-panama-front-320.webp 320w, /images/simCard/mas-movil-panama-front-480.webp 480w, /images/simCard/mas-movil-panama-front-640.webp 640w, /images/simCard/mas-movil-panama-front-768.webp 768w, /images/simCard/mas-movil-panama-front.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/simCard/mas-movil-panama-front-320.jpg 320w, /images/simCard/mas-movil-panama-front-480.jpg 480w, /images/simCard/mas-movil-panama-front-640.jpg 640w, /images/simCard/mas-movil-panama-front-768.jpg 768w, /images/simCard/mas-movil-panama-front.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.simFront}
                  alt={blog.simFrontAltText}
                />
              </picture>
              <figcaption>{blog.simFrontCaption}</figcaption>
            </figure>
            <figure className="sim-card-back">
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/simCard/mas-movil-panama-back-320.webp 320w, /images/simCard/mas-movil-panama-back-480.webp 480w, /images/simCard/mas-movil-panama-back-640.webp 640w, /images/simCard/mas-movil-panama-back-768.webp 768w, /images/simCard/mas-movil-panama-back.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/simCard/mas-movil-panama-back-320.jpg 320w, /images/simCard/mas-movil-panama-back-480.jpg 480w, /images/simCard/mas-movil-panama-back-640.jpg 640w, /images/simCard/mas-movil-panama-back-768.jpg 768w, /images/simCard/mas-movil-panama-back.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.simBack}
                  alt={blog.simBackAltText}
                />
              </picture>
              <figcaption>{blog.simBackCaption}</figcaption>
            </figure>
            <p>{blog.aSIMcardIs}</p>
            <h3>{blog.simComH3}</h3>
            <p>{blog.simCardsCome}</p>
            <h3>{blog.frequencyH3}</h3>
            <p>{blog.evenThough}</p>
            <h3>{blog.findYourPhoneH3}</h3>
            <p>{blog.lookUp}</p>
            <h3>{blog.compareBandsH3}</h3>
            <h4>{blog.theMainMobile}</h4>
            <ul>
              <li>
                <a
                  className="link"
                  target="_blank"
                  href="https://www.claro.com.pa/personas/"
                  rel="noopener noreferrer"
                  aria-label="claro panama"
                >
                  {blog.mainLi1}
                </a>
              </li>
              <li>
                <a
                  className="link"
                  target="_blank"
                  href="https://www.masmovilpanama.com/"
                  rel="noopener noreferrer"
                  aria-label="mas movil panama"
                >
                  {blog.mainLi2}
                </a>
              </li>
              <li>
                <a
                  className="link"
                  target="_blank"
                  href="https://www.digicelgroup.com/pa/es"
                  rel="noopener noreferrer"
                  aria-label="digicel panama"
                >
                  {blog.mainLi3}
                </a>
              </li>
              <li>
                <a
                  className="link"
                  target="_blank"
                  href="https://www.tigo.com.pa/"
                  rel="noopener noreferrer"
                  aria-label="tigo panama"
                >
                  {blog.mainLi4}
                </a>
              </li>
            </ul>
            <p>{blog.youCanUse}</p>
            <h3>{blog.claroMasMovilH3}</h3>
            <h4>{blog.claroH4}</h4>
            <ul>
              <li>
                <span className="six-hundred">{blog.claroSpan1}</span>{" "}
                <span>{blog.claroLi1}</span>
              </li>
              <li>
                <span className="six-hundred">{blog.claroSpan2}</span>{" "}
                <span>{blog.claroLi2}</span>
              </li>
              <li>
                <span className="six-hundred">{blog.claroSpan3}</span>{" "}
                <span>{blog.claroLi3}</span>
              </li>
              <li className="no-marker">
                <ul>
                  <li>{blog.nestLiA}</li>
                  <li>{blog.nestLiB}</li>
                </ul>
              </li>
            </ul>
            <h4>{blog.masMovilH4}</h4>
            <ul>
              <li>
                <span className="six-hundred">{blog.masMovSpan1}</span>{" "}
                <span>{blog.movilLiA}</span>
              </li>
              <li>
                <span className="six-hundred">{blog.masMovSpan2}</span>{" "}
                <span>{blog.movilLiB}</span>
              </li>
              <li>
                <span className="six-hundred">{blog.masMovSpan3}</span>{" "}
                <span>{blog.movilLiC}</span>
              </li>
              <li className="no-marker">
                <ul>
                  <li>{blog.mMnested1}</li>
                  <li>{blog.mMnested2}</li>
                  <li>{blog.mMnested3}</li>
                  <li>{blog.mMnested4}</li>
                </ul>
              </li>
            </ul>
            <h2>{blog.eSimVs}</h2>
            <p>{blog.physical}</p>
            <p>{blog.eSim}</p>
            <p>{blog.eSImsAre}</p>
            <p>{blog.soMake}</p>
            <p>{blog.IHave}</p>
            <h2>{blog.simPriceH2}</h2>
            <p>{blog.thePriceOf}</p>
            <p>{blog.dataIsAll}</p>
            <h2>{blog.whereToBuyH2}</h2>
            <p>{blog.prePaidareSold}</p>
            <p>{blog.iRecommend}</p>

            <figure className="cell-store">
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/simCard/alex-deleon-panama-320.webp 320w, /images/simCard/alex-deleon-panama-480.webp 480w, /images/simCard/alex-deleon-panama-640.webp 640w, /images/simCard/alex-deleon-panama-768.webp 768w, /images/simCard/alex-deleon-panama.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/simCard/alex-deleon-panama-320.jpg 320w, /images/simCard/alex-deleon-panama-480.jpg 480w, /images/simCard/alex-deleon-panama-640.jpg 640w, /images/simCard/alex-deleon-panama-768.jpg 768w, /images/simCard/alex-deleon-panama.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  fetchpriority="high"
                  className="no-right-click"
                  src={blog.claroStore}
                  alt={blog.claroStoreAltText}
                />
              </picture>
              <figcaption>{blog.claroStoreCaption}</figcaption>
            </figure>
            <h2>{blog.simReg}</h2>
            <p>{blog.whenYouPur}</p>
            <h2>{blog.panamaMobileCar}</h2>
            <p>{blog.recommendation}</p>
            <p>{blog.claroConnect}</p>
            <h3>{blog.claroPrePaid}</h3>
            <ul>
              <li>{blog.claroPlanLi1}</li>
              <li>{blog.claroPlanLi2}</li>
              <li>{blog.claroPlanLi3}</li>
              <li>{blog.claroPlanLi4}</li>
              <li>{blog.claroPlanLi5}</li>
              <li>{blog.claroPlanLi6}</li>
            </ul>
            <h3>{blog.movilprePaid}</h3>
            <ul>
              <li>{blog.movilplanLi1}</li>
              <li>{blog.movilplanLi2}</li>
              <li>{blog.movilplanLi3}</li>
            </ul>
            <p className="intro no-margin-top last-figure conclusion">
              {blog.final}
            </p>
            <div className="button-parent">
              <button title="Traditional food in Panama" onClick={goToFood}>
                &#60;
              </button>
              <button title="How to fly cheap to Panama" onClick={goToFlights}>
                &#62;
              </button>
            </div>
          </section>
        )}
      </main>
    );
  }
  if (document.title === "How to fly cheap to Panama") {
    return (
      <main id="main-element" className={`blog-details margin `}>
        {isPending && <div>Loading...</div>}
        {error && <div className="load-error">{error}</div>}
        {blog && (
          <section className={blog.className}>
            <div className="line-divider centered wide">
              <p className="diamond-text-box date">
                <span className="left"></span>
                <span className="content caps centered">{blog.date}</span>
                <span className="right"></span>
              </p>
            </div>
            <h1 className="centered post-title">{blog.title}</h1>
            <p className="body intro">{blog.intro}</p>
            <div className="share-parent">
              <FacebookShareButton
                url={currentPageUrl}
                hashtag="#panama"
                aria-label="facebook"
              >
                <FacebookIcon size={35} />
              </FacebookShareButton>
              <TwitterShareButton
                url={currentPageUrl}
                hashtags={[
                  "pickuppanama",
                  "airfare",
                  "copa",
                  "panama",
                  "cheap",
                ]}
                title={"How to fly cheap to Panama"}
                aria-label="twitter"
              >
                <TwitterIcon size={35} />
              </TwitterShareButton>
              <LinkedinShareButton
                url={currentPageUrl}
                title={"How to fly cheap to Panama"}
                summary={
                  "Use these tips to save money on airfare on your trip to Panama."
                }
                aria-label="linkedin"
              >
                <LinkedinIcon size={35} />
              </LinkedinShareButton>
              <EmailShareButton
                url={currentPageUrl}
                subject={"How to fly cheap to Panama"}
                body={`Use these tips to save money on airfare on your trip to Panama.`}
                aria-label="email"
                separator={" "}
              >
                <EmailIcon size={35} />
              </EmailShareButton>
            </div>
            <figure className="airplane-window">
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/cheapFlight/view-of-panama-copa-air-320.webp 320w, /images/cheapFlight/view-of-panama-copa-air-480.webp 480w, /images/cheapFlight/view-of-panama-copa-air-640.webp 640w, /images/cheapFlight/view-of-panama-copa-air-768.webp 768w, /images/cheapFlight/view-of-panama-copa-air.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/cheapFlight/view-of-panama-copa-air-320.jpg 320w, /images/cheapFlight/view-of-panama-copa-air-480.jpg 480w, /images/cheapFlight/view-of-panama-copa-air-640.jpg 640w, /images/cheapFlight/view-of-panama-copa-air-768.jpg 768w, /images/cheapFlight/view-of-panama-copa-air.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  fetchpriority="high"
                  className="no-right-click"
                  src={blog.planeWindow}
                  alt={blog.planeWindowAltText}
                />
              </picture>
              <figcaption>{blog.planeWindowCaption}</figcaption>
            </figure>
            <h2>{blog.useFlightH2}</h2>
            <p>{blog.websitesLike}</p>
            <h2>{blog.beFlexH2}</h2>
            <p>{blog.flyingDuring}</p>
            <h2>{blog.beFlexDepartH2}</h2>
            <p>{blog.ifYouplan}</p>
            <h2>{blog.useAirlineMilesH2}</h2>
            <p>{blog.ifYouHave}</p>
            <h2>{blog.BookH2}</h2>
            <p>{blog.BookingYour}</p>
            <h2>{blog.bookingDirectly}</h2>
            <p>{blog.bookingDirect}</p>
            <p>
              <span className="six-hundred">{blog.promoDealsSpan}</span>{" "}
              {blog.airlinesOften}
            </p>
            <p>
              <span className="six-hundred">{blog.ancillaryFeesSpan}</span>{" "}
              {blog.whenBooking}
            </p>
            <p>
              <span className="six-hundred">{blog.priceMatchSpan}</span>{" "}
              {blog.someAirlines}
            </p>
            <p>
              <span className="six-hundred">{blog.flexFareSpan}</span>{" "}
              {blog.airlinesMay}
            </p>
            <p>
              <span className="six-hundred">{blog.customServSpan}</span>{" "}
              {blog.bookDirectly}
            </p>
            <p>
              <span className="six-hundred">{blog.bundleDealsSpan}</span>{" "}
              {blog.someAirlinesBundle}
            </p>
            <h2>{blog.travelH2}</h2>
            <p>{blog.theLowSeason}</p>
            <p>{blog.octoberIs}</p>
            <p>{blog.althoughOct}</p>
            <p>{blog.morningsAre}</p>
            <p>{blog.someTravelers}</p>
            <p>{blog.whileTheLow}</p>
            <h2>{blog.tipsH2}</h2>
            <h3>{blog.timeYourH3}</h3>
            <p>{blog.scheduleYour}</p>
            <h3>{blog.stayFlexH3}</h3>
            <p>{blog.bePrepared}</p>
            <h3>{blog.bookAccommodations}</h3>
            <p>{blog.chooseAccom}</p>
            <h3>{blog.packApp}</h3>
            <p>{blog.packAppropriately}</p>
            <h3>{blog.protectElect}</h3>
            <p>{blog.useWaterProof}</p>
            <h3>{blog.planIndoor}</h3>
            <p>{blog.haveBackup}</p>
            <h3>{blog.scheduleMorning}</h3>
            <p>{blog.itRainsAfternoon}</p>
            <h2>{blog.panamaAirlineH2}</h2>
            <p>{blog.copaAirlines}</p>
            <figure className="airplane-window">
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/cheapFlight/copa-air-panama-320.webp 320w, /images/cheapFlight/copa-air-panama-480.webp 480w, /images/cheapFlight/copa-air-panama-640.webp 640w, /images/cheapFlight/copa-air-panama-768.webp 768w, /images/cheapFlight/copa-air-panama.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/cheapFlight/copa-air-panama-320.jpg 320w, /images/cheapFlight/copa-air-panama-480.jpg 480w, /images/cheapFlight/copa-air-panama-640.jpg 640w, /images/cheapFlight/copa-air-panama-768.jpg 768w, /images/cheapFlight/copa-air-panama.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.planeLoaders}
                  alt={blog.planeLoadersAltText}
                />
              </picture>
              <figcaption>{blog.planeLoadersCaption}</figcaption>
            </figure>
            <h3>{blog.extensiveH3}</h3>
            <p>{blog.copaAirlinesHas}</p>
            <h3>{blog.greatServH3}</h3>
            <p>{blog.copaIsKnown}</p>
            <p>{blog.whileCopa}</p>
            <h2>{blog.otherPan}</h2>
            <p className="last-figure">{blog.otherAir}</p>
            <div className="button-parent">
              <button
                title="Using WhatsApp instead of your cell phone service in Panama"
                onClick={goToWhatsAppStory}
              >
                &#60;
              </button>
              <button
                title="How many Panamanians speak English?"
                onClick={goToEnglish}
              >
                &#62;
              </button>
            </div>
          </section>
        )}
      </main>
    );
  }
  if (document.title === "How many Panamanians speak English?") {
    return (
      <main id="main-element" className={`blog-details margin `}>
        {isPending && <div>Loading...</div>}
        {error && <div className="load-error">{error}</div>}
        {blog && (
          <section className={blog.className}>
            <div className="line-divider centered wide">
              <p className="diamond-text-box date">
                <span className="left"></span>
                <span className="content caps centered">{blog.date}</span>
                <span className="right"></span>
              </p>
            </div>
            <h1 className="centered post-title">{blog.title}</h1>
            <p className="body intro">{blog.intro}</p>
            <div className="share-parent">
              <FacebookShareButton
                url={currentPageUrl}
                hashtag="#panama"
                aria-label="facebook"
              >
                <FacebookIcon size={35} />
              </FacebookShareButton>
              <TwitterShareButton
                url={currentPageUrl}
                hashtags={[
                  "pickuppanama",
                  "spanish",
                  "language",
                  "panama",
                  "english",
                ]}
                title={"How many Panamanians speak English?"}
                aria-label="twitter"
              >
                <TwitterIcon size={35} />
              </TwitterShareButton>
              <LinkedinShareButton
                url={currentPageUrl}
                title={"How many Panamanians speak English?"}
                summary={
                  "How many Panamanians speak English? Find out not only how many, but when and how English began to develop here."
                }
                aria-label="linkedin"
              >
                <LinkedinIcon size={35} />
              </LinkedinShareButton>
              <EmailShareButton
                url={currentPageUrl}
                subject={"How many Panamanians speak English?"}
                body={`How many Panamanians speak English? Find out not only how many, but when and how English began to develop here.`}
                aria-label="email"
                separator={" "}
              >
                <EmailIcon size={35} />
              </EmailShareButton>
            </div>
            <figure className="panama-flag">
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/english/panama-flag-320.webp 320w, /images/english/panama-flag-480.webp 480w, /images/english/panama-flag-640.webp 640w, /images/english/panama-flag-768.webp 768w, /images/english/panama-flag.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/english/panama-flag-320.jpg 320w, /images/english/panama-flag-480.jpg 480w, /images/english/panama-flag-640.jpg 640w, /images/english/panama-flag-768.jpg 768w, /images/english/panama-flag.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  fetchpriority="high"
                  className="no-right-click"
                  src={blog.flagOfPanama}
                  alt={blog.flagOfPanamaAltText}
                />
              </picture>
              <figcaption>{blog.flagOfPanamaCaption}</figcaption>
            </figure>
            <h2>{blog.englishArrivalH2}</h2>
            <p>{blog.explorers}</p>
            <p>{blog.invasions}</p>
            <p>{blog.evenThough}</p>
            <figure className="panama-flag">
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/english/panama-portobelo-canons-320.webp 320w, /images/english/panama-portobelo-canons-480.webp 480w, /images/english/panama-portobelo-canons-640.webp 640w, /images/english/panama-portobelo-canons-768.webp 768w, /images/english/panama-portobelo-canons.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/english/panama-portobelo-canons-320.jpg 320w, /images/english/panama-portobelo-canons-480.jpg 480w, /images/english/panama-portobelo-canons-640.jpg 640w, /images/english/panama-portobelo-canons-768.jpg 768w, /images/english/panama-portobelo-canons.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.canons}
                  alt={blog.canonsAltText}
                />
              </picture>
              <figcaption>{blog.canonsCaption}</figcaption>
            </figure>
            <h2>{blog.moreEnglishSpeakersH2}</h2>
            <p>{blog.pacificMail}</p>
            <h2>{blog.canalZoneH2}</h2>
            <figure className="panama-flag">
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/english/canal-zone-store-320.webp 320w, /images/english/canal-zone-store-480.webp 480w, /images/english/canal-zone-store-640.webp 640w, /images/english/canal-zone-store-768.webp 768w, /images/english/canal-zone-store.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/english/canal-zone-store-320.jpg 320w, /images/english/canal-zone-store-480.jpg 480w, /images/english/canal-zone-store-640.jpg 640w, /images/english/canal-zone-store-768.jpg 768w, /images/english/canal-zone-store.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.canalZone}
                  alt={blog.canalZoneAltText}
                />
              </picture>
              <figcaption>{blog.canalZoneCaption}</figcaption>
            </figure>
            <p>{blog.usPresence}</p>
            <p>{blog.duringThis}</p>
            <p>{blog.although}</p>
            <h2>{blog.whyOnlyFifteen}</h2>
            <p>{blog.peopleWho}</p>
            <p>{blog.theyWould}</p>
            <p>{blog.theUse}</p>
            <p>{blog.thisConcern}</p>
            <h2>{blog.arias}</h2>
            <p>{blog.inFortyOne}</p>
            <p>{blog.heWas}</p>
            <p>{blog.retailBusiness}</p>
            <p>{blog.thereWere}</p>
            <p>{blog.now}</p>

            <p>
              <span>{blog.englishDoes}</span>
              <span>
                {" "}
                <a
                  className="link"
                  target="_self"
                  href="https://pickuppanama.com/blogs/what-languages-are-spoken-in-panama"
                  rel="noopener noreferrer"
                  aria-label="what languages are spoken in panama"
                >
                  {blog.thisArticle}
                </a>
                <span>{blog.talksAbout}</span>
              </span>
            </p>
            <p className="intro no-margin-top last-figure conclusion">
              {blog.ifYou}
            </p>
            <div className="button-parent">
              <button title="How to fly cheap to Panama" onClick={goToFlights}>
                &#60;
              </button>
              <button
                title="Why did Jimmy Carter sign the Panama Canal Treaty?"
                onClick={goToTreatyStory}
              >
                &#62;
              </button>
            </div>
          </section>
        )}
      </main>
    );
  }
  if (document.title === "Why did Jimmy Carter sign the Panama Canal Treaty?") {
    return (
      <main id="main-element" className={`blog-details margin `}>
        {isPending && <div>Loading...</div>}
        {error && <div className="load-error">{error}</div>}
        {blog && (
          <section className={blog.className}>
            <div className="line-divider centered wide">
              <p className="diamond-text-box date">
                <span className="left"></span>
                <span className="content caps centered">{blog.date}</span>
                <span className="right"></span>
              </p>
            </div>
            <h1 className="centered post-title">{blog.title}</h1>
            <p className="body intro">{blog.intro}</p>
            <div className="share-parent">
              <FacebookShareButton
                url={currentPageUrl}
                hashtag="#panama"
                aria-label="facebook"
              >
                <FacebookIcon size={35} />
              </FacebookShareButton>
              <TwitterShareButton
                url={currentPageUrl}
                hashtags={[
                  "pickuppanama",
                  "carter",
                  "treaty",
                  "panama",
                  "canal",
                ]}
                title={"Why did Jimmy Carter sign the Panama Canal Treaty?"}
                aria-label="twitter"
              >
                <TwitterIcon size={35} />
              </TwitterShareButton>
              <LinkedinShareButton
                url={currentPageUrl}
                title={"Why did Jimmy Carter sign the Panama Canal Treaty?"}
                summary={
                  "Carter saw the old treaty as unjust and believed a new treaty was needed to correct it. Read about what events led up to the hostile relations the US had with Panama and how the new treaty helped resolve them."
                }
                aria-label="linkedin"
              >
                <LinkedinIcon size={35} />
              </LinkedinShareButton>
              <EmailShareButton
                url={currentPageUrl}
                subject={"Why did Jimmy Carter sign the Panama Canal Treaty?"}
                body={`Carter saw the old treaty as unjust and believed a new treaty was needed to correct it. Read about what events led up to the hostile relations the US had with Panama and how the new treaty helped resolve them.`}
                aria-label="email"
                separator={" "}
              >
                <EmailIcon size={35} />
              </EmailShareButton>
            </div>
            <figure className="panama-flag">
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/carterTreaty/ship-goes-through-panama-canal-320.webp 320w, /images/carterTreaty/ship-goes-through-panama-canal-480.webp 480w, /images/carterTreaty/ship-goes-through-panama-canal-640.webp 640w, /images/carterTreaty/ship-goes-through-panama-canal-768.webp 768w, /images/carterTreaty/ship-goes-through-panama-canal.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/carterTreaty/ship-goes-through-panama-canal-320.jpg 320w, /images/carterTreaty/ship-goes-through-panama-canal-480.jpg 480w, /images/carterTreaty/ship-goes-through-panama-canal-640.jpg 640w, /images/carterTreaty/ship-goes-through-panama-canal-768.jpg 768w, /images/carterTreaty/ship-goes-through-panama-canal.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  fetchpriority="high"
                  className="no-right-click"
                  src={blog.treaty}
                  alt={blog.treatyAltText}
                />
              </picture>
              <figcaption>{blog.treatyCaption}</figcaption>
            </figure>
            <p>{blog.panamaHadNever}</p>
            <p>{blog.priorTo}</p>
            <p>{blog.thePanamanian}</p>
            <p>{blog.thisHappened}</p>
            <p>{blog.thisIncident}</p>
            <p>{blog.afterThis}</p>
            <p>{blog.throughOut}</p>
            <p>{blog.atThisTime}</p>
            <p>{blog.henryKissinger}</p>
            <p>{blog.originally}</p>
            <p>{blog.soIn}</p>
            <p>{blog.theTwoGov}</p>
            <p>{blog.oneOfTheMain}</p>
            <p>{blog.theTack}</p>
            <p className="intro no-margin-top last-figure conclusion">
              <strong>{blog.carterSaw}</strong>
            </p>
            <div className="button-parent">
              <button
                title="How many Panamanians speak English?"
                onClick={goToEnglish}
              >
                &#60;
              </button>
              <button title="Packing for Panama" onClick={goToPacking}>
                &#62;
              </button>
            </div>
          </section>
        )}
      </main>
    );
  }
  if (document.title === "Packing for Panama") {
    return (
      <main id="main-element" className={`blog-details margin `}>
        {isPending && <div>Loading...</div>}
        {error && <div className="load-error">{error}</div>}
        {blog && (
          <section className={blog.className}>
            <div className="line-divider centered wide">
              <p className="diamond-text-box date">
                <span className="left"></span>
                <span className="content caps centered">{blog.date}</span>
                <span className="right"></span>
              </p>
            </div>
            <h1 className="centered post-title">{blog.title}</h1>
            <p className="body intro">{blog.intro}</p>
            <div className="share-parent">
              <FacebookShareButton
                url={currentPageUrl}
                hashtag="#panama"
                aria-label="facebook"
              >
                <FacebookIcon size={35} />
              </FacebookShareButton>
              <TwitterShareButton
                url={currentPageUrl}
                hashtags={["pickuppanama", "packing", "entry", "panama"]}
                title={"Packing for Panama"}
                aria-label="twitter"
              >
                <TwitterIcon size={35} />
              </TwitterShareButton>
              <LinkedinShareButton
                url={currentPageUrl}
                title={"Packing for Panama"}
                summary={
                  "What should I pack for Panama? Travel light, and bring wicking clothing to stay comfortable."
                }
                aria-label="linkedin"
              >
                <LinkedinIcon size={35} />
              </LinkedinShareButton>
              <EmailShareButton
                url={currentPageUrl}
                subject={"Packing for Panama"}
                body={`What should I pack for Panama? Travel light, and bring wicking clothing to stay comfortable.`}
                aria-label="email"
                separator={" "}
              >
                <EmailIcon size={35} />
              </EmailShareButton>
            </div>
            <figure className="skyline-pa">
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/packing/panama-city-skyline-320.webp 320w, /images/packing/panama-city-skyline-480.webp 480w, /images/packing/panama-city-skyline-640.webp 640w, /images/packing/panama-city-skyline-768.webp 768w, /images/packing/panama-city-skyline.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/packing/panama-city-skyline-320.jpg 320w, /images/packing/panama-city-skyline-480.jpg 480w, /images/packing/panama-city-skyline-640.jpg 640w, /images/packing/panama-city-skyline-768.jpg 768w, /images/packing/panama-city-skyline.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  fetchpriority="high"
                  className="no-right-click"
                  src={blog.panamSky}
                  alt={blog.panamSkyAltText}
                />
              </picture>
              <figcaption>{blog.panamSkyCaption}</figcaption>
            </figure>
            <h2>{blog.whatShouldH2}</h2>
            <ul>
              <li>{blog.lightLi}</li>
              <li>{blog.bringHat}</li>
              <li>{blog.umbrella}</li>
              <li>{blog.lightWeight}</li>
              <li>{blog.wicking}</li>
              <li>{blog.upf}</li>
              <li>{blog.bring}</li>
              <li>{blog.sneakers}</li>
              <li>{blog.leaveJewel}</li>
            </ul>
            <h2 className="extra-margin">{blog.thingsToDo}</h2>

            <fieldset className="six-month-list">
              <legend>
                <h3>{blog.sixMonths}</h3>
              </legend>
              <div className="input-label-parent passport">
                <input
                  type="checkbox"
                  id="togglePassport"
                  className="checkbox-input"
                />
                <label
                  htmlFor="togglePassport"
                  className="checkbox-label"
                ></label>
                <p>{blog.passport}</p>
              </div>
              <div className="input-label-parent book-your">
                <input
                  type="checkbox"
                  id="toggleHotel"
                  className="checkbox-input"
                />
                <label htmlFor="toggleHotel" className="checkbox-label"></label>
                <p>{blog.bookYour}</p>
              </div>
              <div className="input-label-parent organize-any">
                <input
                  type="checkbox"
                  id="toggleOrganize"
                  className="checkbox-input"
                />
                <label
                  htmlFor="toggleOrganize"
                  className="checkbox-label"
                ></label>
                <p>{blog.organize}</p>
              </div>
              <div className="input-label-parent start-getting">
                <input
                  type="checkbox"
                  id="toggleGetting"
                  className="checkbox-input"
                />
                <label
                  htmlFor="toggleGetting"
                  className="checkbox-label"
                ></label>
                <p>
                  <span>{blog.startGetting}</span>
                  <span>
                    {" "}
                    <a
                      className="link"
                      target="_self"
                      href="https://pickuppanama.com/blogs/the-culture-of-panama"
                      rel="noopener noreferrer"
                      aria-label="the culture of panama"
                    >
                      {blog.panCult}
                    </a>
                    <span>{blog.cultPeriod}</span>
                  </span>
                </p>
              </div>
              <div className="input-label-parent brush-up">
                <input
                  type="checkbox"
                  id="toggleSpan"
                  className="checkbox-input"
                />
                <label htmlFor="toggleSpan" className="checkbox-label"></label>
                <p>{blog.practiceSpan}</p>
              </div>
              <div className="input-label-parent">
                <input
                  type="checkbox"
                  id="toggleIT"
                  className="checkbox-input"
                />
                <label htmlFor="toggleIT" className="checkbox-label"></label>
                <p>{blog.planYourIt}</p>
              </div>
              <div className="input-label-parent get-hep">
                <input
                  type="checkbox"
                  id="toggleHepA"
                  className="checkbox-input"
                />
                <label htmlFor="toggleHepA" className="checkbox-label"></label>
                <p>{blog.getHepA}</p>
              </div>
              <div className="input-label-parent learn-all">
                <input
                  type="checkbox"
                  id="toggleFigure"
                  className="checkbox-input"
                />
                <label
                  htmlFor="toggleFigure"
                  className="checkbox-label"
                ></label>
                <p>
                  <span>{blog.figureOut}</span>
                  <span>
                    {" "}
                    <a
                      className="link"
                      target="_self"
                      href="https://pickuppanama.com/blogs/transportation-in-panama"
                      rel="noopener noreferrer"
                      aria-label="transportation in panama"
                    >
                      {blog.tranSpan}
                    </a>
                    <span>{blog.orLook}</span>
                  </span>
                </p>
              </div>
              <div className="input-label-parent plan-data">
                <input
                  type="checkbox"
                  id="toggleData"
                  className="checkbox-input"
                />
                <label htmlFor="toggleData" className="checkbox-label"></label>
                <p>
                  <span>{blog.dataPlan}</span>
                  <span>
                    {" "}
                    <a
                      className="link"
                      target="_self"
                      href="https://pickuppanama.com/blogs/using-whatsapp-instead-of-your-cell-phone-service-in-panama"
                      rel="noopener noreferrer"
                      aria-label="using whatsapp instead of your cell service in panama"
                    >
                      {blog.planOptions}
                    </a>
                    <span>{blog.andWork}</span>
                  </span>
                </p>
              </div>
              <div className="input-label-parent talk-banks">
                <input
                  type="checkbox"
                  id="toggleBanks"
                  className="checkbox-input"
                />
                <label htmlFor="toggleBanks" className="checkbox-label"></label>
                <p>{blog.talkWithBanks}</p>
              </div>
              <div className="input-label-parent check-banks">
                <input
                  type="checkbox"
                  id="toggleCheckBank"
                  className="checkbox-input"
                />
                <label
                  htmlFor="toggleCheckBank"
                  className="checkbox-label"
                ></label>
                <p>{blog.checkBanks}</p>
              </div>
              <div className="input-label-parent travel-site">
                <input
                  type="checkbox"
                  id="toggleTravelSite"
                  className="checkbox-input"
                />
                <label
                  htmlFor="toggleTravelSite"
                  className="checkbox-label"
                ></label>
                <p>
                  <span>{blog.travelState}</span>
                  <span>
                    {" "}
                    <a
                      className="link"
                      target="_blank"
                      href="https://travel.state.gov/content/travel/en/international-travel/International-Travel-Country-Information-Pages/Panama.html"
                      rel="noopener noreferrer"
                      aria-label="travel state panama website"
                    >
                      {blog.linkSpan}
                    </a>
                    <span>{blog.andLook}</span>
                  </span>
                </p>
              </div>
              <div className="input-label-parent verify-this">
                <input
                  type="checkbox"
                  id="toggleVerify"
                  className="checkbox-input"
                />
                <label
                  htmlFor="toggleVerify"
                  className="checkbox-label"
                ></label>
                <p>{blog.verifyThis}</p>
              </div>
            </fieldset>
            <h2>{blog.embassy}</h2>
            <p className="embassy-address">{blog.avenida}</p>
            <p className="embassy-address">{blog.buildingNo}</p>
            <p className="embassy-address">{blog.clayton}</p>
            <p className="embassy-address">{blog.telephone}</p>
            <p className="embassy-address">{blog.emailEm}</p>
            <p>
              <span>
                {" "}
                <a
                  className="link"
                  target="_blank"
                  href="https://pa.usembassy.gov/"
                  rel="noopener noreferrer"
                  aria-label="us emabassy panama"
                >
                  {blog.website}
                </a>
              </span>
            </p>
            <fieldset className="six-month-list">
              <legend>
                <h3>{blog.oneToTwo}</h3>
              </legend>
              <div className="input-label-parent one-to-two-weeks buy-travel">
                <input
                  type="checkbox"
                  id="toggleBuyTravel"
                  className="checkbox-input"
                />
                <label
                  htmlFor="toggleBuyTravel"
                  className="checkbox-label"
                ></label>
                <p>{blog.buyTravel}</p>
              </div>
              <div className="input-label-parent one-to-two-weeks make-two">
                <input
                  type="checkbox"
                  id="toggleMake2"
                  className="checkbox-input"
                />
                <label htmlFor="toggleMake2" className="checkbox-label"></label>
                <p>{blog.make2}</p>
              </div>
              <div className="input-label-parent one-to-two-weeks mail-email">
                <input
                  type="checkbox"
                  id="toggleMailEmail"
                  className="checkbox-input"
                />
                <label
                  htmlFor="toggleMailEmail"
                  className="checkbox-label"
                ></label>
                <p>{blog.email}</p>
              </div>
              <div className="input-label-parent one-to-two-weeks pack-up">
                <input
                  type="checkbox"
                  id="togglePack"
                  className="checkbox-input"
                />
                <label htmlFor="togglePack" className="checkbox-label"></label>
                <p>{blog.pack}</p>
              </div>
            </fieldset>
            <h2>{blog.thingsToPack}</h2>
            <p>{blog.copaAirlines}</p>
            <p>{blog.iAlways}</p>
            <fieldset className="six-month-list third-list">
              <legend>
                <h3>{blog.forYourCarry}</h3>
              </legend>
              <div className="input-label-parent check-pass">
                <input
                  type="checkbox"
                  id="togglePassportCheck"
                  className="checkbox-input"
                />
                <label
                  htmlFor="togglePassportCheck"
                  className="checkbox-label"
                ></label>
                <p>{blog.passportCheck}</p>
              </div>
              <div className="input-label-parent toothbrush">
                <input
                  type="checkbox"
                  id="toggleBrush"
                  className="checkbox-input"
                />
                <label htmlFor="toggleBrush" className="checkbox-label"></label>
                <p>{blog.toothBrush}</p>
              </div>
              <div className="input-label-parent change-of">
                <input
                  type="checkbox"
                  id="toggleChangeOf"
                  className="checkbox-input"
                />
                <label
                  htmlFor="toggleChangeOf"
                  className="checkbox-label"
                ></label>
                <p>{blog.changeOf}</p>
              </div>
              <div className="input-label-parent jacket">
                <input
                  type="checkbox"
                  id="toggleJacket"
                  className="checkbox-input"
                />
                <label
                  htmlFor="toggleJacket"
                  className="checkbox-label"
                ></label>
                <p>{blog.lightWeightJack}</p>
              </div>
              <div className="input-label-parent devices">
                <input
                  type="checkbox"
                  id="toggleDevices"
                  className="checkbox-input"
                />
                <label
                  htmlFor="toggleDevices"
                  className="checkbox-label"
                ></label>
                <p>{blog.devices}</p>
              </div>
              <div className="input-label-parent water-bottle">
                <input
                  type="checkbox"
                  id="toggleBottle"
                  className="checkbox-input"
                />
                <label
                  htmlFor="toggleBottle"
                  className="checkbox-label"
                ></label>
                <p>{blog.waterBottle}</p>
              </div>
              <div className="input-label-parent electro">
                <input
                  type="checkbox"
                  id="toggleElectro"
                  className="checkbox-input"
                />
                <label
                  htmlFor="toggleElectro"
                  className="checkbox-label"
                ></label>
                <p>{blog.electrolytes}</p>
              </div>
              <div className="input-label-parent book">
                <input
                  type="checkbox"
                  id="toggleBook"
                  className="checkbox-input"
                />
                <label htmlFor="toggleBook" className="checkbox-label"></label>
                <p>{blog.book}</p>
              </div>
              <div className="input-label-parent quarters">
                <input
                  type="checkbox"
                  id="toggleQuarters"
                  className="checkbox-input"
                />
                <label
                  htmlFor="toggleQuarters"
                  className="checkbox-label"
                ></label>
                <p>{blog.quarters}</p>
              </div>
              <div className="input-label-parent sunglasses">
                <input
                  type="checkbox"
                  id="toggleSunglasses"
                  className="checkbox-input"
                />
                <label
                  htmlFor="toggleSunglasses"
                  className="checkbox-label"
                ></label>
                <p>{blog.sunglasses}</p>
              </div>
              <div className="input-label-parent wear-sneakers">
                <input
                  type="checkbox"
                  id="toggleSneakers"
                  className="checkbox-input"
                />
                <label
                  htmlFor="toggleSneakers"
                  className="checkbox-label"
                ></label>
                <p>{blog.wearSneakers}</p>
              </div>
            </fieldset>
            <h2>{blog.entranceRequirements}</h2>
            <p>
              {blog.visitthe}
              <span>
                {" "}
                <a
                  className="link"
                  target="_blank"
                  href="https://travel.state.gov/content/travel/en/international-travel/International-Travel-Country-Information-Pages/Panama.html#ExternalPopup"
                  rel="noopener noreferrer"
                  aria-label="embassy of panama"
                >
                  {blog.embassySpan}
                </a>
                {blog.websiteFor}
              </span>
            </p>
            <p>
              <span className="six-hundred">{blog.vPspan}</span> {blog.atLeast}
            </p>
            <p>
              <span className="six-hundred">{blog.returnTicket}</span>{" "}
              {blog.toYourHome}
            </p>
            <p>
              <span className="six-hundred">{blog.money}</span> {blog.proofThat}
            </p>
            <p>
              <span className="six-hundred">{blog.addressText}</span>{" "}
              {blog.address}
            </p>

            <p>
              <span className="six-hundred">{blog.criminalRec}</span>{" "}
              {blog.panamIm}
            </p>

            <p>
              <span className="six-hundred">{blog.yellow}</span>{" "}
              {blog.theYellow}
            </p>
            <p>
              <span className="six-hundred">{blog.covid}</span> {blog.currently}
            </p>
            <p>
              <span className="six-hundred">{blog.currencyRes}</span>{" "}
              {blog.tenK}
            </p>
            <p>
              <span className="six-hundred">{blog.currencyExit}</span>{" "}
              {blog.tenK2}
            </p>

            <div className="button-parent">
              <button
                title="Why did Jimmy Carter sign the Panama Canal Treaty?"
                onClick={goToTreatyStory}
              >
                &#60;
              </button>
              <button
                title="Daily life in Panama"
                onClick={goToMustKnow}
              >
                &#62;
              </button>
            </div>
          </section>
        )}
      </main>
    );
  }if (document.title === "Daily life in Panama") {
    return (
      <main id="main-element" className={`blog-details margin `}>
        {isPending && <div>Loading...</div>}
        {error && <div className="load-error">{error}</div>}
        {blog && (
          <section className={blog.className}>
            <div className="line-divider centered wide">
              <p className="diamond-text-box date">
                <span className="left"></span>
                <span className="content caps centered">{blog.date}</span>
                <span className="right"></span>
              </p>
            </div>
            <h1 className="centered post-title">{blog.title}</h1>
            <p className="body intro">{blog.intro}</p>
            <div className="share-parent">
              <FacebookShareButton
                url={currentPageUrl}
                hashtag="#panama"
                aria-label="facebook"
              >
                <FacebookIcon size={35} />
              </FacebookShareButton>
              <TwitterShareButton
                url={currentPageUrl}
                hashtags={["pickuppanama", "daily", "life", "panama"]}
                title={"Daily life in Panama"}
                aria-label="twitter"
              >
                <TwitterIcon size={35} />
              </TwitterShareButton>
              <LinkedinShareButton
                url={currentPageUrl}
                title={"Daily life in Panama"}
                summary={
                  "Read about daily life in Panama and what you need to know before you visit."
                }
                aria-label="linkedin"
              >
                <LinkedinIcon size={35} />
              </LinkedinShareButton>
              <EmailShareButton
                url={currentPageUrl}
                subject={"Daily life in Panama"}
                body={`Read about daily life in Panama and what you need to know before you visit.`}
                aria-label="email"
                separator={" "}
              >
                <EmailIcon size={35} />
              </EmailShareButton>
            </div>
            <figure className="check-point">
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/mustKnow/panama-checkpoint-320.webp 320w, /images/mustKnow/panama-checkpoint-480.webp 480w, /images/mustKnow/panama-checkpoint-640.webp 640w, /images/mustKnow/panama-checkpoint-768.webp 768w, /images/mustKnow/panama-checkpoint.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/mustKnow/panama-checkpoint-320.jpg 320w, /images/mustKnow/panama-checkpoint-480.jpg 480w, /images/mustKnow/panama-checkpoint-640.jpg 640w, /images/mustKnow/panama-checkpoint-768.jpg 768w, /images/mustKnow/panama-checkpoint.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  fetchpriority="high"
                  className="no-right-click"
                  src={blog.checkP}
                  alt={blog.checkPAltText}
                />
              </picture>
              <figcaption>{blog.checkPCaption}</figcaption>
            </figure>
            <h2>{blog.passportH2}</h2>
            <p>{blog.panamaLaw}</p>
            <p>{blog.mostTime}</p>
            <p>{blog.noPolice}</p>
            <p>{blog.butThey}</p>
            <h3>{blog.timesForPassportH3}</h3>
            <p>{blog.paying}</p>
            <p>{blog.driversLicense}</p>
            <p>{blog.dayTrips}</p>
            <figure className="ferry">
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/mustKnow/contadora-ferry-panama-320.webp 320w, /images/mustKnow/contadora-ferry-panama-480.webp 480w, /images/mustKnow/contadora-ferry-panama-640.webp 640w, /images/mustKnow/contadora-ferry-panama-768.webp 768w, /images/mustKnow/contadora-ferry-panama.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/mustKnow/contadora-ferry-panama-320.jpg 320w, /images/mustKnow/contadora-ferry-panama-480.jpg 480w, /images/mustKnow/contadora-ferry-panama-640.jpg 640w, /images/mustKnow/contadora-ferry-panama-768.jpg 768w, /images/mustKnow/contadora-ferry-panama.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.contadoraFerry}
                  alt={blog.contadoraFerryAltText}
                />
              </picture>
              <figcaption>{blog.contadoraFerryCaption}</figcaption>
            </figure>
            <h2>{blog.money}</h2>
            <p>{blog.panamaUsesUs}</p>
            <p>{blog.coins}</p>
            <figure className="panama-money">
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/mustKnow/panama-money-320.webp 320w, /images/mustKnow/panama-money-480.webp 480w, /images/mustKnow/panama-money-640.webp 640w, /images/mustKnow/panama-money-768.webp 768w, /images/mustKnow/panama-money.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/mustKnow/panama-money-320.jpg 320w, /images/mustKnow/panama-money-480.jpg 480w, /images/mustKnow/panama-money-640.jpg 640w, /images/mustKnow/panama-money-768.jpg 768w, /images/mustKnow/panama-money.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.panMon}
                  alt={blog.panMonAltText}
                />
              </picture>
              <figcaption>{blog.panMonCaption}</figcaption>
            </figure>
            <h3>{blog.smallBills}</h3>
            <p>{blog.vendors}</p>
            <h3>{blog.carryBackup}</h3>
            <p>{blog.atm}</p>
            <p>{blog.evenIf}</p>
            <h2>{blog.dontForget}</h2>
            <p>{blog.expected}</p>
            <figure className="traffic">
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/mustKnow/panama-bus-320.webp 320w, /images/mustKnow/panama-bus-480.webp 480w, /images/mustKnow/panama-bus-640.webp 640w, /images/mustKnow/panama-bus-768.webp 768w, /images/mustKnow/panama-bus.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/mustKnow/panama-bus-320.jpg 320w, /images/mustKnow/panama-bus-480.jpg 480w, /images/mustKnow/panama-bus-640.jpg 640w, /images/mustKnow/panama-bus-768.jpg 768w, /images/mustKnow/panama-bus.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.bus}
                  alt={blog.busAltText}
                />
              </picture>
              <figcaption>{blog.busCaption}</figcaption>
            </figure>
            <h2>{blog.longPants}</h2>
            <p>{blog.menExpected}</p>
            <p>{blog.banks}</p>
            <p>{blog.butIf}</p>
            <p>
              <span>
                {" "}
                <a
                  className="link"
                  target="_self"
                  href="https://pickuppanama.com/blogs/the-culture-of-panama"
                  rel="noopener noreferrer"
                  aria-label="the culture of panama"
                >
                  {blog.thisArticl} 
                </a> 
                {blog.goesInto}
              </span>
            </p>
            <h2>{blog.panamaHas}</h2>
            <p>{blog.evenThough}</p>
            <figure className="taxi">
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/mustKnow/girl-on-boat-in-panama-320.webp 320w, /images/mustKnow/girl-on-boat-in-panama-480.webp 480w, /images/mustKnow/girl-on-boat-in-panama-640.webp 640w, /images/mustKnow/girl-on-boat-in-panama-768.webp 768w, /images/mustKnow/girl-on-boat-in-panama.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/mustKnow/girl-on-boat-in-panama-320.jpg 320w, /images/mustKnow/girl-on-boat-in-panama-480.jpg 480w, /images/mustKnow/girl-on-boat-in-panama-640.jpg 640w, /images/mustKnow/girl-on-boat-in-panama-768.jpg 768w, /images/mustKnow/girl-on-boat-in-panama.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.girlBoat}
                  alt={blog.girlBoatAltText}
                />
              </picture>
              <figcaption>{blog.girlBoatCaption}</figcaption>
            </figure>
            <p>{blog.workIs}</p>
            <figure className="traffic">
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/mustKnow/alex-deleon-panama-hammock-320.webp 320w, /images/mustKnow/alex-deleon-panama-hammock-480.webp 480w, /images/mustKnow/alex-deleon-panama-hammock-640.webp 640w, /images/mustKnow/alex-deleon-panama-hammock-768.webp 768w, /images/mustKnow/alex-deleon-panama-hammock.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/mustKnow/alex-deleon-panama-hammock-320.jpg 320w, /images/mustKnow/alex-deleon-panama-hammock-480.jpg 480w, /images/mustKnow/alex-deleon-panama-hammock-640.jpg 640w, /images/mustKnow/alex-deleon-panama-hammock-768.jpg 768w, /images/mustKnow/alex-deleon-panama-hammock.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.alexHam}
                  alt={blog.alexHamAltText}
                />
              </picture>
              <figcaption>{blog.alexHamCaption}</figcaption>
            </figure>
            <h2>{blog.customerServiceH2}</h2>
            <p>{blog.sociallyAcc}</p>
            <p>{blog.youWillLikely}</p>
            <p>{blog.iWas}</p>
            <p>{blog.inHer}</p>
            <figure className="panama-woman">
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/mustKnow/panama-woman-320.webp 320w, /images/mustKnow/panama-woman-480.webp 480w, /images/mustKnow/panama-woman-640.webp 640w, /images/mustKnow/panama-woman-768.webp 768w, /images/mustKnow/panama-woman.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/mustKnow/panama-woman-320.jpg 320w, /images/mustKnow/panama-woman-480.jpg 480w, /images/mustKnow/panama-woman-640.jpg 640w, /images/mustKnow/panama-woman-768.jpg 768w, /images/mustKnow/panama-woman.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.panamaWoman}
                  alt={blog.panamaWomanAltText}
                />
              </picture>
              <figcaption>{blog.panamaWomanCaption}</figcaption>
            </figure>
            <h2>{blog.waterH2}</h2>
            <p>{blog.yes}</p>
            <p>{blog.inFact}</p>
            <p>{blog.iWasOnce}</p>
            <p>{blog.toPlaySafe}</p>
            <h2>{blog.waterPressure}</h2>
            <p>{blog.waterPressurCan}</p>
            <p>{blog.forInstance}</p>
            <p>{blog.whereIst}</p>
            <p>{blog.lukeWarm}</p>
            <p>{blog.panamaHot}</p> 
            <p>{blog.someAreas}</p>
            <h2>{blog.groceryStores}</h2>
            <p>{blog.whenYou}</p>
            <p>{blog.notCase}</p>
            <figure className="canvas">
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/mustKnow/panama-canvas-bag-320.webp 320w, /images/mustKnow/panama-canvas-bag-480.webp 480w, /images/mustKnow/panama-canvas-bag-640.webp 640w, /images/mustKnow/panama-canvas-bag-768.webp 768w, /images/mustKnow/panama-canvas-bag.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/mustKnow/panama-canvas-bag-320.jpg 320w, /images/mustKnow/panama-canvas-bag-480.jpg 480w, /images/mustKnow/panama-canvas-bag-640.jpg 640w, /images/mustKnow/panama-canvas-bag-768.jpg 768w, /images/mustKnow/panama-canvas-bag.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.canvasBag}
                  alt={blog.canvasBagAltText}
                />
              </picture>
              <figcaption>{blog.canvasBagCaption}</figcaption>
            </figure>
            <h2>{blog.unpavedRoadsH2}</h2>
            <p>{blog.ifYourGoing}</p>
            <figure className="unpaved">
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/mustKnow/dirt-roads-in-panama-320.webp 320w, /images/mustKnow/dirt-roads-in-panama-480.webp 480w, /images/mustKnow/dirt-roads-in-panama-640.webp 640w, /images/mustKnow/dirt-roads-in-panama-768.webp 768w, /images/mustKnow/dirt-roads-in-panama.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/mustKnow/dirt-roads-in-panama-320.jpg 320w, /images/mustKnow/dirt-roads-in-panama-480.jpg 480w, /images/mustKnow/dirt-roads-in-panama-640.jpg 640w, /images/mustKnow/dirt-roads-in-panama-768.jpg 768w, /images/mustKnow/dirt-roads-in-panama.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.unpaved}
                  alt={blog.unpavedAltText}
                />
              </picture>
              <figcaption>{blog.unpavedCaption}</figcaption>
            </figure>
            <p>{blog.ifYour}</p>
            <p>{blog.bottomOut}</p>
            <figure className="vehicle">
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/mustKnow/panama-offroad-vehicle-320.webp 320w, /images/mustKnow/panama-offroad-vehicle-480.webp 480w, /images/mustKnow/panama-offroad-vehicle-640.webp 640w, /images/mustKnow/panama-offroad-vehicle-768.webp 768w, /images/mustKnow/panama-offroad-vehicle.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/mustKnow/panama-offroad-vehicle-320.jpg 320w, /images/mustKnow/panama-offroad-vehicle-480.jpg 480w, /images/mustKnow/panama-offroad-vehicle-640.jpg 640w, /images/mustKnow/panama-offroad-vehicle-768.jpg 768w, /images/mustKnow/panama-offroad-vehicle.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.vehicle}
                  alt={blog.vehicleAltText}
                />
              </picture>
              <figcaption>{blog.vehicleCaption}</figcaption>
            </figure>
            <h2>{blog.sideWalksH2}</h2>
            <p>{blog.sideWalks}</p>
            <p>
              {blog.unlevel}
              <span>
                {" "}
                <a
                  className="link"
                  target="_self"
                  href="https://pickuppanama.com/blogs/things-to-do-in-el-cangrejo"
                  rel="noopener noreferrer"
                  aria-label="things to do in el cangrejo"
                >
                  {blog.elCangrejo} 
                </a> 
                {blog.haveBeen}
              </span>
            </p>
            <figure className="taxi">
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/mustKnow/panama-sidewalk-repair-320.webp 320w, /images/mustKnow/panama-sidewalk-repair-480.webp 480w, /images/mustKnow/panama-sidewalk-repair-640.webp 640w, /images/mustKnow/panama-sidewalk-repair-768.webp 768w, /images/mustKnow/panama-sidewalk-repair.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/mustKnow/panama-sidewalk-repair-320.jpg 320w, /images/mustKnow/panama-sidewalk-repair-480.jpg 480w, /images/mustKnow/panama-sidewalk-repair-640.jpg 640w, /images/mustKnow/panama-sidewalk-repair-768.jpg 768w, /images/mustKnow/panama-sidewalk-repair.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.repair}
                  alt={blog.repairAltText}
                />
              </picture>
              <figcaption>{blog.repairCaption}</figcaption>
            </figure>
            <p>{blog.butOverall}</p>

            <h2>{blog.pedBeware}</h2>
            <p>{blog.driversDo}</p>
            <ul>
              <li>{blog.pedTip}</li>
              <li>{blog.pedTip2}</li>
              <li>{blog.pedTip3}</li>
              <li>{blog.pedTip4}</li>
              <li>{blog.pedTip5}</li>
              <li>{blog.pedTip6}</li>
              <li>{blog.pedTip7}</li>
            </ul>
            <figure className="traffic">
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/mustKnow/view-of-panam-highway-panama-320.webp 320w, /images/mustKnow/view-of-panam-highway-panama-480.webp 480w, /images/mustKnow/view-of-panam-highway-panama-640.webp 640w, /images/mustKnow/view-of-panam-highway-panama-768.webp 768w, /images/mustKnow/view-of-panam-highway-panama.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/mustKnow/view-of-panam-highway-panama-320.jpg 320w, /images/mustKnow/view-of-panam-highway-panama-480.jpg 480w, /images/mustKnow/view-of-panam-highway-panama-640.jpg 640w, /images/mustKnow/view-of-panam-highway-panama-768.jpg 768w, /images/mustKnow/view-of-panam-highway-panama.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.panAm}
                  alt={blog.panAmAltText}
                />
              </picture>
              <figcaption>{blog.panAmCaption}</figcaption>
            </figure>
            <h2>{blog.drivingH2}</h2>
            <p>{blog.traffic}</p>
            <figure className="traffic">
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/mustKnow/traffic-in-panama-320.webp 320w, /images/mustKnow/traffic-in-panama-480.webp 480w, /images/mustKnow/traffic-in-panama-640.webp 640w, /images/mustKnow/traffic-in-panama-768.webp 768w, /images/mustKnow/traffic-in-panama.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/mustKnow/traffic-in-panama-320.jpg 320w, /images/mustKnow/traffic-in-panama-480.jpg 480w, /images/mustKnow/traffic-in-panama-640.jpg 640w, /images/mustKnow/traffic-in-panama-768.jpg 768w, /images/mustKnow/traffic-in-panama.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.trafficJam}
                  alt={blog.trafficJamAltText}
                />
              </picture>
              <figcaption>{blog.trafficJamCaption}</figcaption>
            </figure>
            <p>{blog.theThings}</p>
            <p>{blog.evenNo}</p>
            <p>{blog.distances}</p>
            <p>{blog.dontBesurprised}</p>
            <p>{blog.ihad}</p>
            <p>{blog.itDamaged}</p>
            <h2>{blog.taxisH2}</h2>
            <figure className="yellow-cabs">
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/mustKnow/panama-taxi-cabs-320.webp 320w, /images/mustKnow/panama-taxi-cabs-480.webp 480w, /images/mustKnow/panama-taxi-cabs-640.webp 640w, /images/mustKnow/panama-taxi-cabs-768.webp 768w, /images/mustKnow/panama-taxi-cabs.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/mustKnow/panama-taxi-cabs-320.jpg 320w, /images/mustKnow/panama-taxi-cabs-480.jpg 480w, /images/mustKnow/panama-taxi-cabs-640.jpg 640w, /images/mustKnow/panama-taxi-cabs-768.jpg 768w, /images/mustKnow/panama-taxi-cabs.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.panamaTaxi}
                  alt={blog.panamaTaxiAltText}
                />
              </picture>
              <figcaption>{blog.panamaTaxiCaption}</figcaption>
            </figure>
            <p>{blog.yellowCabs}</p>
            <p>{blog.agree}</p>
            <p>
              <span>
                {" "}
                <a
                  className="link"
                  target="_self"
                  href="https://pickuppanama.com/blogs/transportation-in-panama"
                  rel="noopener noreferrer"
                  aria-label="transportation in panama"
                >
                  {blog.thisArticle} 
                </a> 
                {blog.tellsYou}
              </span>
              </p>
                <h2>{blog.beReady}</h2>
                <p>{blog.panamaIs}</p>
            <p>
              <span>
                {" "}
                <a
                  className="link"
                  target="_self"
                  href="https://pickuppanama.com/blogs/what-languages-are-spoken-in-panama"
                  rel="noopener noreferrer"
                  aria-label="what languages are spoken in panama"
                >
                  {blog.thisArticleTalks} 
                </a> 
                {blog.coverThe}
              </span>
            </p>
            <h2>{blog.toiletTypes}</h2>
            <p>{blog.panamaUses}</p>
            <p>{blog.theOnlyThing}</p>
            <p>{blog.soThe}</p>
            <p>{blog.outHouse}</p>
            <p>{blog.handSanitizerTip}</p>
            <h2>{blog.powerOutages}</h2>
            <p>{blog.powerOut}</p>
            <h2>{blog.panamBig}</h2>
            <p>{blog.panamCel}
              <strong>{blog.celStrong}</strong>
            </p>
            <figure className="taxi">
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/mustKnow/panama-make-a-difference-320.webp 320w, /images/mustKnow/panama-make-a-difference-480.webp 480w, /images/mustKnow/panama-make-a-difference-640.webp 640w, /images/mustKnow/panama-make-a-difference-768.webp 768w, /images/mustKnow/panama-make-a-difference.webp 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <source
                  type="image/jpg"
                  srcSet={
                    process.env.PUBLIC_URL +
                    "/images/mustKnow/panama-make-a-difference-320.jpg 320w, /images/mustKnow/panama-make-a-difference-480.jpg 480w, /images/mustKnow/panama-make-a-difference-640.jpg 640w, /images/mustKnow/panama-make-a-difference-768.jpg 768w, /images/mustKnow/panama-make-a-difference.jpg 1170w"
                  }
                  sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
                />
                <img
                  className="no-right-click"
                  src={blog.mural}
                  alt={blog.muralAltText}
                />
              </picture>
              <figcaption>{blog.muralCaption}</figcaption>
            </figure>
            <div className="button-parent">
              <button
                title="Packing for Panama"
                onClick={goToPacking}
              >
                &#60;
              </button>
              <button
                title="How do I visit the Panama Canal?"
                onClick={goToCanalStory}
              >
                &#62;
              </button>
            </div>
          </section>
        )}
      </main>
    );
  }
 else {
    return (
      <main id="main-element" className="not-found">
        <figure className="not-found-hero">
          <picture>
            <source
              type="image/webp"
              srcSet={
                process.env.PUBLIC_URL +
                "/images/notFound/missing-meal-320.webp 320w, /images/notFound/missing-meal-640.webp 640w, /images/notFound/missing-meal.webp 1017w"
              }
              sizes="(max-width: 320px) 320px, (max-width: 640px) 640px, 1017px"
            />
            <source
              type="image/jpg"
              srcSet={
                process.env.PUBLIC_URL +
                "/images/notFound/missing-meal-320.jpg 320w, /images/notFound/missing-meal-640.jpg 640w, /images/notFound/missing-meal.jpg 1017w"
              }
              sizes="(max-width: 320px) 320px, (max-width: 640px) 640px, 1017px"
            />
            <img
              fetchpriority="high"
              className="no-right-click"
              src={process.env.PUBLIC_URL + "/images/notFound/missing-meal.jpg"}
              alt="Villa Mayte Coronado Panama"
            />
          </picture>
          <figcaption className="fig-caption">
            Taken at Delicias Margot in Coronado Panama.
          </figcaption>
        </figure>
        <section className="centered">
          <h1>404</h1>
          <p>This page is missing.</p>
          <Link to="/">Back to the home page...</Link>
        </section>
      </main>
    );
  }
};

export default BlogDetails;
