
const Mission = () => {
    return (
        <section className="mission">
        <h1>Learn about Panama</h1>
        <p>Panama is the nation that connects North and South America. Explore this growing collection of stories, articles, and photos while you pick up some knowledge about Panama.</p>
    </section>
    )
}

export default Mission;