 const facts = [
    {
      title : "Panama's official name is the Republic of Panama.",
      id: 1
    },
    {
      title: "The population of Panama is 4,476,235.",
      id: 2
    },
    {
      title: "The capital of Panama is Panama City.",
      id: 3
    },
    {
      title: "Panama has a rainy season from May through November and a dry season from December through April.",
      id: 4
    },
    {
      title: "The Panamanian golden frog is Panama's national mascot.",
      id: 5
    },
    {
      title: "Panama's official language is Spanish.",
      id: 6
    },
    {
      title: "Panama is bordered by Columbia and Costa Rica and is located between the Atlantic and Pacific oceans.",
      id: 7
    },
    {
      title: `Panama means "an abundance of fish". It derives from a former fishing location that was near the present capital.`,
      id: 8
    },
    {
      title: "Panama gained independence from Spain in 1821 and then from Columbia on November 3, 1903.",
      id: 9
    },
    {
      title: "Baseball is Panama's national sport.",
      id: 10
    },
    {
      title: "Sancocho is a traditional soup and is Panama's national dish.",
      id: 11
    },
    {
      title: "Seco Herrerano, an 80 proof white spirit made from sugarcane, is the national alcoholic beverage of Panama.",
      id: 12
    },
    {
      title: "Panama's national unit of currency is the Balboa. Panama also accepts United States currency.",
      id: 13
    },
    {
      title: "The national bird of Panama is the harpy eagle, which is depicted on the coat of arms.",
      id: 14
    },
    {
      title: `"A Man, A Plan, A Canal, Panama," is a palindrome.`,
      id: 15
    },
    {
      title: "The length of the Panama Canal from shoreline to shoreline is 40 miles.",
      id: 16
    },
    {
      title: "The Panama Canal locks are 110 feet wide.",
      id: 17
    },
    {
      title: "The average depth of the Panama Canal is 43 feet.",
      id: 18
    },
    {
      title: "The Panama Canal opened on August 15, 1914.",
      id: 19
    },
    {
      title: "The Gatun Lake provides water to operate the canal locks.",
      id: 20
    },
    {
      title: "The Panama Canal cuts distance, costs, and time for cargo ships traveling between the Atlantic and Pacific Oceans.",
      id: 21
    },
    {
      title: "Before the Panama Canal was built, ships had to travel all the way around South America to reach the Pacific and Atlantic Oceans.",
      id: 22
    },
    {
      title: "The geography of Panama is very diverse with mountains, rain forests, white sandy beaches and more than 1500 islands.",
      id: 23
    },
    {
      title: "Panama's government is made up of three branches: the executive, legislative, and judicial branches.",
      id: 24
    },
    {
      title: "Panama elects a new president and a new vice president every 5 years.",
      id: 25
    },
    {
      title: "Laurentino Cortizo is the current president of Panama.",
      id: 26
    },
    {
      title: "Over 2 million people visit Panama every year.",
      id: 27
    },
  ];

  export default facts;