import "./Recipe.css";
import "../../pages/Home/BlogDetails.css";
import { TabTitle, Capitalize } from "../../utils/GeneralFunctions";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  EmailShareButton,
  EmailIcon,
} from "react-share";
const currentPageUrl = window.location.href;

const Recipes = () => {
  TabTitle("Best Panama Food Recipes");
  Capitalize();

  return (
    <main id="main-element" className="recipe-page">
      <section className="blog-details recipe-page-margin">
        <div className="line-divider centered wide first">
          <p className="diamond-text-box date">
            <span className="left"></span>
            <span className="content caps centered"></span>
            <span className="right"></span>
          </p>
        </div>
        <h1 className="post-title">Panama Food Recipes</h1>
        <p className="body intro">
          These recipes go back to the early 1940s. They were given to my great
          aunt in Panama. She gave them to my mom and my mom then gave them to
          me to share. More recipes will be added regularly.
        </p>
        <div className="share-parent">
          <FacebookShareButton
            url={currentPageUrl}
            hashtag="#panama"
            aria-label="facebook"
          >
            <FacebookIcon size={35} />
          </FacebookShareButton>
          <TwitterShareButton
            url={currentPageUrl}
            hashtags={["pickuppanama", "panama", "recipes"]}
            title={"Panama food recipes"}
            aria-label="twitter"
          >
            <TwitterIcon size={35} />
          </TwitterShareButton>
          <LinkedinShareButton
            url={currentPageUrl}
            title={"Panama food recipes"}
            summary={"Enjoy preparing the best Panama food recipes."}
            aria-label="linkedin"
          >
            <LinkedinIcon size={35} />
          </LinkedinShareButton>
          <EmailShareButton
            url={currentPageUrl}
            subject={"Panama food recipes"}
            body={"Enjoy preparing the best Panama food recipes."}
            aria-label="email"
            separator={" "}
          >
            <EmailIcon size={35} />
          </EmailShareButton>
        </div>

        <article id="papayaSection">
          <header className="recipe-header">
            <h2>Papaya dishes</h2>
          </header>
          <figure className="panama-papayas">
            <picture>
              <source
                type="image/webp"
                srcSet={
                  process.env.PUBLIC_URL +
                  "/images/recipePage/panama-papaya-320.webp 320w, /images/recipePage/panama-papaya-480.webp 480w, /images/recipePage/panama-papaya-640.webp 640w, /images/recipePage/panama-papaya-768.webp 768w, /images/recipePage/panama-papaya.webp 1170w"
                }
                sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
              />
              <source
                type="image/jpg"
                srcSet={
                  process.env.PUBLIC_URL +
                  "/images/recipePage/panama-papaya-320.jpg 320w, /images/recipePage/panama-papaya-480.jpg 480w, /images/recipePage/panama-papaya-640.jpg 640w, /images/recipePage/panama-papaya-768.jpg 768w, /images/recipePage/panama-papaya.jpg 1170w"
                }
                sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
              />
              <img
                fetchpriority="high"
                className="no-right-click"
                src="/images/recipePage/panama-papaya.jpg"
                alt="fresh papayas from panama"
              />
            </picture>
            <figcaption>These are fresh papayas from Panama.</figcaption>
          </figure>
          <p>
            Panama papayas are soft and have a greenish-yellow skin that turns
            yellowish orange when ripe. The inside is usually a bright orange or
            pinkish red. They are in season year round.
          </p>
        </article>

        <article>
          <header>
            <h2>Papaya and Ginger</h2>
            <p>
              Make a syrup of one measure sugar, 1/2 measure water, some finely
              sliced dried ginger, and a few slices of lemon. Add 2 measures of
              half ripe papaya sliced lengthwise which have already been
              simmering in water.
            </p>
          </header>
        </article>
        <article>
          <header>
            <h2>Orange and Papaya Marmalade</h2>
            <p>
              Add 1 measure papaya with a 1/2 measure of orange. Wash oranges
              well and squeeze out the seeds and juice. Put the skins through a
              blender and add it with the juice that has been strained of all
              the seeds. Add papaya pulp that has been cut into small pieces
              (without the rind) and boil it all together. Then add as much
              sugar as there is pulp. Boil again for 15 minutes.
            </p>
          </header>
        </article>
        <article>
          <header>
            <h2>Papaya Pickle</h2>
            <p>
              Make syrup of 1 measure sugar and 1/4 measure vinegar, add a few
              whole cloves and peppercorns and 2 measures half ripe papaya, cut
              into small pieces. Boil until tender.
            </p>
          </header>
        </article>
        <article>
          <header>
            <h2>Papaya Sherbet</h2>
            <p>
              Mix 4 cups of papaya pulp with 2 cups sugar and juice of 2 lemons
              and freeze.
            </p>
          </header>
        </article>
        <article>
          <header>
            <h2>Papaya Flavored Gelatin Dessert</h2>
            <ul>
              <li>1/2 box gelatin</li>
              <li>1/2 cup cold water</li>
              <li>Juice 1 lemon</li>
              <li>1 cup boiling water</li>
              <li>1 cup papaya pulp</li>
              <li>1/2 cup sugar</li>
            </ul>
            <p>
              Soak gelatin in cold water five minutes. Dissolve the sugar in the
              boiling water. Add the gelatin and strain. When cool, add the
              papaya and lemon juice. Place on ice to harden.
            </p>
          </header>
        </article>
        <article>
          <header>
            <h2>Papaya Pie</h2>
            <ul>
              <li>2 eggs</li>
              <li>1 cup papaya</li>
              <li>1 cup sugar</li>
              <li>Juice 1/2 lemon</li>
              <li>1/2 cup butter</li>
            </ul>
            <p>
              Make a bottom pie crust and bake. Cream butter and sugar. Add
              beaten eggs, lemon juice and papaya. Pour into pie crust and bake.
              Make a meringue of whites of eggs and 2 tablespoons sugar. Place
              on pie and brown in oven.
            </p>
          </header>
        </article>
        <article>
          <header>
            <h2>Papaya Cocktail</h2>
            <p>
              Dice the papaya and serve in glasses with cocktail sauce and
              chipped ice, or serve with orange, lemon, or lime juice, and a
              little sugar in same manner.
            </p>
          </header>
        </article>
        <article>
          <header>
            <h2>Baked Papaya</h2>
            <p>
              Cut papaya in half lengthwise. Add a little sugar and orange, or
              lemon or lime juice, or a little cinnamon in place of the juice.
              Bake 20 minutes and serve immediately on taking from the oven.
            </p>
          </header>
        </article>
        <article>
          <header>
            <h2>Papaya Whip</h2>
            <p>
              Add the juice of one lemon and 1/2 cup of sugar, to 1 1/2 cups of
              papaya pulp. Then beat into 2 stiffly whipped egg whites.
            </p>
          </header>
        </article>
        <article>
          <header>
            <h2>Stewed Papaya (version 1)</h2>
            <ul>
              <li>2 cups diced papaya</li>
              <li>1/2 cup sugar</li>
              <li>1/2 cup water</li>
              <li>Juice 1/2 lemon</li>
              <li>Juice of 2 lemons</li>
            </ul>
            <p>
              Dice papaya and stew with sugar, water, and lemon juice 1/2 hour.
              Serve in sherbet glasses as a first course for lunch or dessert.
              Can use 4 oranges in place of lemons.
            </p>
          </header>
        </article>
        <article>
          <header>
            <h2>Stewed Papaya (version 2)</h2>
            <p className="last-p-of-section">
              Cook in same manner as version 1, with 1/2 cup sugar and only
              enough water to keep from burning.
            </p>
          </header>
        </article>
        <div className="line-divider centered wide">
          <p className="diamond-text-box date">
            <span className="left"></span>
            <span className="content caps centered"></span>
            <span className="right"></span>
          </p>
        </div>
        <article id="plantainSection">
          <header className="recipe-header">
            <h2>Plantain dishes</h2>
          </header>
          <figure className="panama-plantains">
            <picture>
              <source
                type="image/webp"
                srcSet={
                  process.env.PUBLIC_URL +
                  "/images/recipePage/panama-plantain-320.webp 320w, /images/recipePage/panama-plantain-480.webp 480w, /images/recipePage/panama-plantain-640.webp 640w, /images/recipePage/panama-plantain-768.webp 768w, /images/recipePage/panama-plantain.webp 1170w"
                }
                sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
              />
              <source
                type="image/jpg"
                srcSet={
                  process.env.PUBLIC_URL +
                  "/images/recipePage/panama-plantain-320.jpg 320w, /images/recipePage/panama-plantain-480.jpg 480w, /images/recipePage/panama-plantain-640.jpg 640w, /images/recipePage/panama-plantain-768.jpg 768w, /images/recipePage/panama-plantain.jpg 1170w"
                }
                sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
              />
              <img
                className="no-right-click"
                src="/images/recipePage/panama-plantain.jpg"
                alt="ripe plantains from panama"
              />
            </picture>
            <figcaption>Ripe plantains from Panama</figcaption>
          </figure>
          <p>
            Plantains are a type of banana and are a staple in Panama. When
            ripe, they are usually cooked before eating and served as a side
            dish.
          </p>
        </article>
        <article>
          <header>
            <h2>Plantains with Pineapple</h2>
            <ul>
              <li>8 boiled, ripe plantains</li>
              <li>Milk</li>
              <li>Butter</li>
              <li>Cognac</li>
              <li>Marshmallows</li>
              <li>1 can of pineapple cubes without the juice</li>
              <li>White cheese (cut into small cubes)</li>
              <li>Brown Sugar</li>
            </ul>
            <p>
              Mash the plantains and mix with the brown sugar. Blend in the milk
              and the butter, until the mixture becomes smooth. Add a shot of
              Cognac. Mix in the pineapple and the white cheese.
            </p>
            <p>
              Place in a rectangular pyrex dish and bake at 325 degrees for a
              1/2 hour. Cover it with marshmallows and put it back in the oven
              until the marshmallows are melted.
            </p>
          </header>
        </article>
        <article>
          <header>
            <h2>Special Plantains</h2>
            <ul>
              <li>4 ripe plantains </li>
              <li>2 cups of milk</li>
              <li>1 cup of sugar</li>
              <li>1/2 cup of butter</li>
              <li>1/4 cup of Vermouth</li>
              <li>1 teaspoon of vanilla</li>
              <li>1/4 teaspoon of ground cinnamon</li>
              <li>1/2 teaspoon of vegetable oil</li>
            </ul>
            <p>
              Mash the plantains in a bowl. Add the milk, sugar, butter,
              Vermouth and vanilla. Mix well. Grease a frying pan with oil and
              warm it up.
            </p>
            <p>
              {" "}
              Pour the plantain mixture into the pan. Cook on very low heat
              until fully cooked. Sprinkle it with ground cinnamon.
            </p>
          </header>
        </article>
        <article>
          <header>
            <h2>Tipsy Plantains</h2>
            <ul>
              <li>4 ripe plantains</li>
              <li>6 tablespoons of butter</li>
              <li>2 tablespoons of flour</li>
              <li>4 tablespoons of sugar </li>
              <li>2 Boquete oranges cut in thin slices (with rind)</li>
              <li>1/2 cup of rum (any kind)</li>
              <li>1/4 cup of corn syrup (Karo)</li>
              <li>1 tablespoon of lemon juice</li>
              <li>A grated half lemon </li>
            </ul>
            <p>
              Peel the plantains and cut them into 2" slices. In a large frying
              pan, melt the butter and brown the plantains slightly. In a bowl,
              mix the flour with the sugar and mix in the browned plantains.
              Grease a Pyrex bowl and spoon in the plantain mix.{" "}
            </p>
            <p>
              Cover with orange slices and rind. In a bowl, mix the rum, the
              lemon juice, grated lemon and corn syrup. Pour this mixture over
              the plantains, orange slices and rind. Bake at 350 degrees for
              approximately 35 min. basting the plantains with juice from the
              pan.
            </p>
          </header>
        </article>
        <article>
          <header>
            <h2>Plantains con Leche (milk)</h2>
            <ul>
              <li>3 ripe plantains</li>
              <li>4 tablespoons of butter</li>
              <li>1/4 lb. of cheddar cheese, cut into small squares</li>
              <li>1/4 cup of sugar</li>
              <li>1 stick of cinnamon</li>
            </ul>
            <p>
              Peel the plantains and cut them in half lengthwise without
              separating the two halves. In a large pot, melt the butter and
              brown the plantains. Fill the plantains with the cheese squares
              and put them back in the pot.
            </p>
            <p>
              Sprinkle them with the sugar, and pour the milk over it. Add
              cinnamon. Place the cover on the pot and cook for 10 minutes on
              low. Uncover the pot and continue cooking for 15 minutes. Serve
              hot.
            </p>
          </header>
        </article>
        <article>
          <header>
            <h2>Soup with Plantain Balls</h2>
            <ul>
              <li>4 cups of chicken broth</li>
              <li>1/4 cup of ground raw peanuts</li>
              <li>2 tablespoons of oil</li>
              <li>Salt and pepper to taste</li>
              <li>4 small potatoes</li>
              <li>Achiote powder</li>
              <li>2 green plantains</li>
              <li>1/4 cup of diced, cooked, chicken meat</li>
              <li>1 tablespoon minced onion</li>
              <li>1/2 tablespoon minced parsley</li>
              <li>1 large cabbage leaf</li>
            </ul>
            <p>
              First make the chicken broth. Strain it and add a bit of the
              achiote. Boil one of the plantains in this broth for 15 minutes.
              Meanwhile, grate and knead the other plantain. In 1 tablespoon of
              oil place a few grains of the achiote until you get a good color.
              Remove them and in this oil, brown the onion, the peanuts and
              meat. Add salt and pepper to taste.
            </p>
            <p>
              {" "}
              Mix the raw grated plantain with the cooked one and the other
              tablespoon of oil and knead well. Make little balls of this using
              a teaspoon of the browned mixture in each for filling. Meanwhile,
              boil the potatoes, cut them into pieces, and add the potatoes and
              chopped cabbage to the broth. When it's almost done, add the
              plantain balls and cook about two minutes longer. Add the parsley
              just before serving.
            </p>
          </header>
        </article>
        <article>
          <header>
            <h2>Rum Baked Plantains</h2>
            <ul>
              <li>6 ripe plantains</li>
              <li>2 tablespoons of rum (clear)</li>
              <li>2 tablespoons of margarine</li>
              <li>1/2 cup lime juice</li>
            </ul>
            <p>
              Heat oven to 350 degrees. In a saucepan mix sugar, lime juice,
              margarine, and rum. Heat for 15 minutes until boiling. Peel the
              plantain, cut in diagonal slices, and arrange them in a baking
              dish. Cover plantain with the mixture and bake for 30 minutes.
              Turn it over every 15 minutes.
            </p>
          </header>
        </article>
        <article>
          <header>
            <h2>Ripe Plantains con Vino (wine)</h2>
            <ul>
              <li>3 ripe plantains</li>
              <li>1 stick of butter</li>
              <li>1 cup of sugar</li>
              <li>1/2 bottle of Malaga wine</li>
              <li>1/2 teaspoon of vanilla</li>
            </ul>
            <p>
              Peel the plantains and cut each of them into thirds. Set them in a
              frying pan with sugar, butter, wine and vanilla. Cook them at low
              temperature until they are a golden color, turning them over every
              once in a while. Serve warm.
            </p>
          </header>
        </article>
        <article>
          <header>
            <h2>Plantain Tortillas</h2>
            <ul>
              <li>2 or 3 ripe plantains</li>
              <li>1 tablespoon of sugar</li>
              <li>1/2 teaspoon of baking powder</li>
              <li>Cooking Oil</li>
            </ul>
            <p className="last-p-of-section">
              Mash the plantains with a fork. Place them in a bowl and mix in
              the sugar and the baking powder. Blend well. Shape small cakes of
              the plantain mixture and fry them in oil. Serve warm.
            </p>
          </header>
        </article>
        <div className="line-divider centered wide">
          <p className="diamond-text-box date">
            <span className="left"></span>
            <span className="content caps centered"></span>
            <span className="right"></span>
          </p>
        </div>
        <article id="eggSection">
          <header className="recipe-header">
            <h2>Eggs and Souffles</h2>
          </header>
          <figure className="egg-dishes">
            <picture>
              <source
                type="image/webp"
                srcSet={
                  process.env.PUBLIC_URL +
                  "/images/recipePage/egg-dish-320.webp 320w, /images/recipePage/egg-dish-480.webp 480w, /images/recipePage/egg-dish-640.webp 640w, /images/recipePage/egg-dish-768.webp 768w, /images/recipePage/egg-dish.webp 1170w"
                }
                sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
              />
              <source
                type="image/jpg"
                srcSet={
                  process.env.PUBLIC_URL +
                  "/images/recipePage/egg-dish-320.jpg 320w, /images/recipePage/egg-dish-480.jpg 480w, /images/recipePage/egg-dish-640.jpg 640w, /images/recipePage/egg-dish-768.jpg 768w, /images/recipePage/egg-dish.jpg 1170w"
                }
                sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, (max-width: 768px) 768px, 1170px"
              />
              <img
                className="no-right-click"
                src="/images/recipePage/egg-dish.jpg"
                alt="a dish of scrambled eggs"
              />
            </picture>
            <figcaption>Eggs are very versatile and are used in a lot of Panamanian dishes.</figcaption>
          </figure>
        </article>
        <article>
          <header>
            <h2>Eggs with Onions</h2>
            <h3>1) Prepare a white sauce</h3>
            <ul>
              <li>2 tablespoons of butter</li>
              <li>2 tablespoons of flour</li>
              <li>1/4 teaspoon of salt</li>
              <li>1 cup of milk</li>
            </ul>
            <p>Melt the butter over low heat in a 1 1/2 quart saucepan, stir in the flour, salt and pepper. Cook and stir until the mixture is smooth and bubbly and then remove from heat. Gradually stir in the milk, heat to boil while stirring constantly for 1 minute.</p>
            <h3>2) Prepare the eggs</h3>
            <p>
            Make six hard boiled eggs.  Peel them and cut them in halves.  Peel one pound of onions, cut them in thin slices and fry them, slowly, in butter. They must cook, but not brown. Grate two ounces of American cheese. Place the eggs in a shallow dish, put the onions on top of them, and pour the white sauce over them. Sprinkle with grated cheese and  chopped parsley, and place in oven for ten to fifteen minutes.
            </p>
          </header>
        </article>
        <article>
          <header>
          <h2>Scotch Eggs</h2>
            <ul>
              <li>5 Eggs</li>
              <li>6 ounces of cold meat</li>
              <li>2 ounces of stale bread</li>
              <li>Bread Crumbs</li>
              <li>1 teaspoon chopped onions</li>
              <li>1 teaspoon chopped parsley</li>
              <li>1/2 teaspoon nutmeg</li>
              <li>1/4 teaspoon pepper</li>
              <li>1/2 teaspoon salt</li>
            </ul>
            <p>Boil four of the eggs for 7 minutes. Pass the meat through a mincer and add the seasonings. Soak the stale bread in water, wring it dry in a cloth, break it up well and add the other ingredients.  Then mix with a whole raw egg. Shell the hard-boiled eggs and cut in half lengthwise. Take a piece of the meat mixture, flatten it, and roll it round the half-egg.  Roll it in flour, beaten egg and breadcrumbs and fry until brown. They can be served hot or cold with salad. </p>
          </header>
        </article>
        <article>
          <header>
          <h2>Eggs with Tomato</h2>
            <p>Butter some toast and spread thinly with anchovy paste.  Put a piece of fried tomato on toast, then put a poached egg on the tomato. Pour cream sauce over all and sprinkle with a little chopped parsley.  A little grated cheese added to the cream sauce improves the flavor.</p>
          </header>
        </article>
        <article>
          <header>
            <h2>Eggs Baked in Tomato</h2>
            <ul>
              <li>1 cup canned tomato</li>
              <li>6 eggs</li>
              <li>1/2 teaspoon of salt</li>
              <li>2 cups soft bread crumbs</li>
              <li>2 tablespoons butter</li>
              <li>1/4 teaspoon paprika</li>
            </ul>
            <p>
            Pour tomatoes in a pan, add 1 cup soft bread crumbs, mix well.  Break eggs whole into a dish. Melt butter and stir in remaining crumbs. Sprinkle salt, paprika and buttered crumbs over eggs and bake in a slow over, 15 or 20 minutes.
            </p>
          </header>
        </article>
        <article>
          <header>
            <h2>Eggs Farci</h2>
            <ul>
              <li>6 hard boiled eggs</li>
              <li>1/4 ounce can sardines</li>
              <li>1 tablespoon butter</li>
              <li>4 tablespoons milk</li>
              <li>1/2 teaspoon salt</li>
              <li>1/4 teaspoon pepper</li>
              <li>1 teaspoon lemon juice</li>
              <li>1/2 cup tomato sauce</li>
            </ul>
            <p>
            Shell the eggs and cut an even slice from each end of every egg. Then cut them in half lengthwise. Remove and mash yolks. Take the bones out of the sardines and mash them. Add the sardines to the mashed yolks along with salt, pepper, butter, milk and lemon juice. Mix well and add the mix to the hollowed egg whites. Set on a plate, sprinkle fine bread crumbs over them and place in moderate oven for 6 minutes. Serve with rich heavy tomato sauce.
            </p>
          </header>
        </article>
        <article>
          <header>
            <h2>Eggs Rissoles</h2>
            <ul>
              <li>6 hard boiled eggs</li>
              <li>1/2 cup cream sauce</li>
              <li>1 cup tomato sauce</li>
              <li>1/2 cup diced mushrooms</li>
              <li>1/2 cup butter</li>
              <li>Pinch of salt & pepper</li>
            </ul>
            <p>
            Chop hard boiled eggs into small dice. Add diced mushrooms that have been sautéd in butter.  Season with salt and pepper and add enough cream sauce to make a consistency for molding. Let the mixture chill in the refrigerator.  Shape in egg shaped pellets, dip into butter and fry.  Serve at once with tomato sauce.
            </p>
          </header>
        </article>
        <article>
          <header>
            <h2>Eggs Toadstools</h2>
            <p>
            Boil 6 eggs hard, plunge into cold water and peel carefully.  Slice off just enough of the rounded ends so that they can stand upright. Also cut a small slice off the pointed ends.  Cut 6 tomatoes in half after taking off the skin. Place upper halves on top of the eggs to resemble scarlet fungi.  Decorate with small spots of thick, well seasoned salad dressing. Place the toadstools on a bed of lettuce or other salad leaves.
            </p>
          </header>
        </article>
        <article>
          <header>
            <h2>Cheese and Tomato Eggs</h2>
            <ul>
              <li>One egg and tomato for each person</li>
              <li>Pepper</li>
              <li>Salt</li>
              <li>Onion</li>
              <li>Parmesan</li>
              <li>Cheddar or Cheshire Cheese</li>
              <li>Well buttered ramekin cases</li>
            </ul>
            <p>
            Remove the skins from the tomatoes and slice 1 tomato into each ramekin case. Season with salt, pepper and about 1/6 teaspoon of finely chopped onion.
            </p>
            <p>
            Cut a fine layer of cheese over all and place little nuts of butter here and there. Lastly, break the eggs on top, taking care to keep the yolk whole. Bake in moderate oven until set.
            </p>
          </header>
        </article>
        <article>
          <header>
            <h2>Spinach Souffle</h2>
            <ul>
              <li>2 pounds of  cooked spinach</li>
              <li>Lemon juice</li>
              <li>Salt and Pepper to taste</li>
              <li>4 ounces of white sauce</li>
              <li>3 eggs</li>
              <li>Pinch of grated nutmeg</li>
            </ul>
            <p>
            Put spinach through a sieve and into a basin, season and add lemon juice and nutmeg. Beat up the yolks of eggs, add to mixture, also white sauce, which should be rather thick.
            </p>
            <p>
            Stir in lightly the stiffly whipped whites of eggs and pour mixture into well-greased soufflé dish, around which a band of greased paper has been tied. Bake in moderate over for 20 minutes until firm to the touch.  Remove the band of paper carefully, and serve at once.
            </p>
          </header>
        </article>
        <article>
          <header>
            <h2>Orange Souffle</h2>
            <ul>
              <li>4 juicy oranges</li>
              <li>3 eggs</li>
              <li>3 ounces of Castor sugar</li>
              <li>1/4 (sheets) leaf gelatine</li>
            </ul>
            <p>
            Squeeze juice from oranges. Separate whites from yolks of eggs. Put orange juice, yolks and sugar in double cooker and cook gently until thick, stirring all the time. 
            </p>
            <p>When ready, pour into a bowl to cool. Melt gelatine in a little water or orange juice and when fairly cool, add to mixture in basin.</p>
            <p>
            Stand basin in cold water and stir occasionally until on the point of setting.  Whisk the egg whites to a very stiff froth, and fold lightly into the mixture.
            </p>
            <p className="last-p-of-section">
            Serve in a soufflé dish or in a bowl decorated with crushed meringue.
            </p>
          </header>
        </article>
      </section>
    </main>
  );
};

export default Recipes;
